import { takeLatest, call, put } from 'redux-saga/effects';
import {
  FETCH_EVENTS,
  GET_ALL_EVENTS,
  FETCH_EVENT,
  GET_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
} from '../constants/action-types';
import { request } from './api';
import { replace } from 'react-router-redux';

function* fetchEvents({ page, pageSize, query }) {
  const events = yield call(request, {
    url: `/admin/events/all/${page}?limit=${pageSize}&q=${query}`,
    method: 'GET',
  });

  yield put({
    type: FETCH_EVENTS,
    payload: events,
  });
}

function* fetchEvent({ eventId }) {
  const event = yield call(request, {
    url: `/admin/events/${eventId}`,
    method: 'GET',
  });

  yield put({
    type: FETCH_EVENT,
    payload: event,
  });
}

function* deleteEvent({ eventId }) {
  yield call(request, {
    url: `/admin/events/${eventId}`,
    method: 'DELETE',
  });

  yield put(replace('/admin/events'));
}

export default function* eventsSaga() {
  yield takeLatest(GET_ALL_EVENTS, fetchEvents);
  yield takeLatest(GET_EVENT, fetchEvent);
  yield takeLatest(DELETE_EVENT, deleteEvent);
}
