import {
  FETCH_ROBEX_STARTED,
  FETCH_ROBEX_COMPLETE,
  FETCH_ROBEX_FAILED,
} from '../constants/action-types';

const initialState = {
  suppliersNotified: 0,
  loading: false,
  error: '',
};

function robex(state = initialState, action) {
  switch (action.type) {
    case FETCH_ROBEX_STARTED:
      return { ...initialState, loading: true };
    case FETCH_ROBEX_COMPLETE:
      return {
        ...state,
        suppliersNotified: action.payload.suppliersNotified,
        error: false,
        loading: false,
      };
    case FETCH_ROBEX_FAILED:
      return { ...initialState, error: action.payload };
    default:
      return state;
  }
}

export default robex;
