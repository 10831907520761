import { FETCH_VENDORS } from '../constants/action-types';

const initialState = {
  items: [],
  paging: {},
};

function vendors(state = initialState, action) {
  switch (action.type) {
    case FETCH_VENDORS:
      return Object.assign({}, state, {
        items: action.payload.results,
        paging: action.payload.paging,
        totalRecords: action.payload.totalRecords,
      });
    default:
      return state;
  }
}

export default vendors;
