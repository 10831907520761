import { FETCH_PARTNERSHIPS } from '../constants/action-types';

const initialState = {
  items: [],
};

function partnerships(state = initialState, action) {
  switch (action.type) {
    case FETCH_PARTNERSHIPS:
      return {
        ...state,
        items: action.payload.results,
        paging: action.payload.numPages,
      };
    default:
      return state;
  }
}

export default partnerships;
