const KEY = 'AUTHENTICATION_IDENTITY';

export const getStoredIdentity = () => {
  try {
    return JSON.parse(window.localStorage.getItem(KEY));
  } catch (err) {
    return null;
  }
};

export default class BrowserStorageIdentityService {
  getIdentity() {
    return getStoredIdentity();
  }

  setIdentity(identity) {
    if (identity) {
      window.localStorage.setItem(KEY, JSON.stringify(identity));
    } else {
      window.localStorage.removeItem(KEY);
    }
  }
}
