import {
  FETCH_PARTNERSHIPS,
  FETCH_PARTNERSHIP,
  UPDATE_PARTNERSHIP,
  DELETE_PARTNERSHIP,
  CREATE_PARTNERSHIP,
} from '../constants/action-types';
import { httpService } from '../../app-config';

export function fetchPartnerships(params) {
  return (dispatch) =>
    httpService
      .request({
        path: `/partnerships/search/${params.page}?name=${params.q}&limit=${params.limit}`,
      })
      .then((response) => response)
      .then((payload) =>
        dispatch({
          type: FETCH_PARTNERSHIPS,
          payload,
        })
      );
}

export const fetchPartnership = (id) => (dispatch) =>
  httpService
    .request({
      path: `/partnerships/${id}`,
    })
    .then((payload) =>
      dispatch({
        type: FETCH_PARTNERSHIP,
        payload,
      })
    );

export const updatePartnership = (data) => (dispatch) =>
  httpService
    .request({
      method: 'PATCH',
      path: `/partnerships/${data.uuid}`,
      body: data,
    })
    .then((payload) =>
      dispatch({
        type: UPDATE_PARTNERSHIP,
        payload,
      })
    );

export const deletePartnership = (id) => (dispatch) =>
  httpService
    .request({
      method: 'DELETE',
      path: `/partnerships/${id}`,
    })
    .then((payload) =>
      dispatch({
        type: DELETE_PARTNERSHIP,
        payload,
      })
    );

export const createPartnership = (body) => (dispatch) =>
  httpService
    .request({
      method: 'POST',
      path: `/partnerships`,
      body,
    })
    .then((payload) =>
      dispatch({
        type: CREATE_PARTNERSHIP,
        payload,
      })
    );
