import { FETCH_BOOKING, UPDATE_BOOKING } from '../constants/action-types';

const initialState = {
  id: null,
  eventType: [],
  dietary: {
    tags: [],
    notes: null,
  },
  payments: [],
  state: 'pending',
  numberGuests: 0,
  timings: {
    date: null,
    endTime: null,
    startTime: null,
  },
  location: {},
  dietaryNotes: null,
  logistics: null,
  created: null,
  updated: null,
  vendor_id: null,
  customer_id: null,
  vendor: {
    id: null,
    name: null,
  },
};

function booking(state = initialState, action) {
  switch (action.type) {
    case FETCH_BOOKING:
    case UPDATE_BOOKING:
      return action.payload;
    default:
      return state;
  }
}

export default booking;
