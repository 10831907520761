import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';

import formField from '../../components/form-field';
import formCheckbox from '../../components/form-checkbox';
import TimePicker from '../../components/time-picker';
import Upload from '../../components/upload';
import Image from '../../components/image';

const STATES = [
  { value: 'deactivated', label: 'Deactivated' },
  { value: 'active', label: 'Active' },
  { value: 'pending', label: 'Pending' },
];

export const isPostcodeRequired = (data) =>
  typeof data.public.logistics.location.lat === 'undefined' &&
  typeof data.public.logistics.location.long === 'undefined';

const SupplierFields = (props) => {
  const {
    data,
    setProfilePicture,
    profilePicture,
    stateValue,
    removeProfilePicture,
  } = props;

  const stateDisabled =
    (data.state === 'deactivated' && stateValue === 'deactivated') ||
    (data.state === 'active' && stateValue === 'deactivated');

  const errors = {};

  const hasProfilePicture = profilePicture && profilePicture.url;

  return (
    <div>
      <Field
        name="name"
        className="form-control"
        component={formField}
        label="Supplier's name"
        labelClassName="form-control-label"
        required
        serverError={errors.name}
      />

      {data.uuid && (
        <>
          <label className="field__label form-control-label">
            Profile photo
          </label>

          {!hasProfilePicture && (
            <Upload
              className="image-uploader"
              name="supplier.profilePhoto"
              eid={data.uuid}
              setUploadedFile={setProfilePicture}
            />
          )}

          {hasProfilePicture && (
            <Image
              onRemoveClick={removeProfilePicture}
              image={profilePicture}
            />
          )}
        </>
      )}

      <Field
        name="tagline"
        className="form-control"
        component={formField}
        label="Supplier's tagline"
        labelClassName="form-control-label"
        value={data.tagline}
      />

      {data.slug && (
        <div>
          <Field
            name="slug"
            className="form-control"
            component={formField}
            label="Supplier's slug"
            labelClassName="form-control-label"
            value={data.slug}
          />

          <p className="field__label--after">
            If this profile is already live, changing the slug may mean existing
            links to this profile will break. The platform will update itself,
            but for example if this profile has been manually added to any
            landing pages or sent via any emails, these links won’t work.
          </p>

          <div className="field">
            <label className="field__label form-control-label">
              Supplier&#39;s profile URL
            </label>
            <input
              className="form-control"
              disabled
              value={`https://feast-it.com/suppliers/${data.slug}`}
            />
          </div>

          <div className="field">
            <label className="field__label form-control-label">
              Supplier documents URL
            </label>
            <input
              className="form-control"
              disabled
              value={`https://feast-it.com/suppliers/${data.slug}?documents`}
            />
          </div>
        </div>
      )}

      <Field
        name="state"
        className="form-control"
        component={formField}
        disabled={true}
        options={STATES}
        label="State"
        labelClassName="form-control-label"
        serverError={errors.name}
      />

      <Field
        name="visible"
        className="form-control"
        component={formCheckbox}
        label="Visible"
        labelClassName="form-control-label"
        disabled={stateDisabled}
      />
      <p className="field__label--after">
        If <b>unchecked</b>, the supplier will not be discoverable externally.
        It will still be accessible via the direct URL.
      </p>

      <Field
        name="trusted"
        className="form-control"
        component={formCheckbox}
        label="Trusted"
        labelClassName="form-control-label"
        disabled={stateDisabled}
      />
      <p className="field__label--after">
        If <b>checked</b>, the supplier will be added to the trusted list.
      </p>

      <label className="field__label form-control-label">Description</label>
      <Field
        name="public.description"
        className="form-control"
        type="textarea"
        component={formField}
        labelClassName="form-control-label"
        serverError={errors['public.description']}
        rows="5"
      />

      <Field
        name="public.video_url"
        className="form-control"
        component={formField}
        label="Video"
        labelClassName="form-control-label"
        serverError={errors['public.video_url']}
      />
      <p className="field__label--after">
        e.g. https://www.youtube.com/watch?v=MuK-OxIVexs
      </p>

      <br />
      <label>Robex</label>

      <Field
        name="algorithmDiscoverable"
        className="form-control"
        component={formCheckbox}
        label="Algorithm Discoverable"
        labelClassName="form-control-label"
        disabled={stateDisabled}
      />
      <p className="field__label--after">
        If <b>checked</b>, the supplier will be included in enquiry algorithm
        results.
      </p>

      <p className="field__label--before">
        {data.robex_boost ? (
          <b>
            {`This supplier currently has a robex boost active until
             ${moment(data.robex_boosted_until).format('DD/MM/YYYY')}.`}
          </b>
        ) : (
          'This supplier does not have an active Robex boost.'
        )}
      </p>
      <Field
        name="robex_boosted_until"
        className="form-control"
        type="date"
        component={formField}
        label="Apply New Robex Boost Until"
        labelClassName="form-control-label"
      />
      <p className="field__label--after">
        A Robex boost will be applied to the supplier until this date.
      </p>

      <br />
      <label>Supplier‘s logistics</label>

      <Field
        label="Earliest Start Time"
        name="public.logistics.availableHours.start"
        component={TimePicker}
        serverError={errors['public.logistics.availableHours.start']}
      />

      <Field
        label="Latest End Time"
        name="public.logistics.availableHours.end"
        component={TimePicker}
        serverError={errors['public.logistics.availableHours.end']}
      />

      <Field
        name="public.logistics.maxWorkingHours"
        className="form-control"
        component={formField}
        label="Maximum working hours"
        labelClassName="form-control-label"
        serverError={errors['public.logistics.maxWorkingHours']}
      />

      <label className="field__label form-control-label">
        Additional Information
      </label>
      <Field
        name="public.logistics.setup.details"
        className="form-control"
        type="textarea"
        component={formField}
        labelClassName="form-control-label"
        serverError={errors['public.logistics.setup.details']}
        rows="5"
      />

      <Field
        name="public.logistics.radius"
        className="form-control"
        component={formField}
        label="Area covered (km)"
        labelClassName="form-control-label"
        pattern="[-+]?[0-9]*[.,]?[0-9]+"
        serverError={errors['public.logistics.location.radius']}
        // convert km to meters
        format={(value) => value / 1000}
        // convert meters to km
        parse={(value) => value * 1000}
        required
        type="number"
      />

      <Field
        name="public.logistics.minSpend"
        className="form-control"
        component={formField}
        label="Minimum spend (value in £)"
        labelClassName="form-control-label"
        required
        serverError={errors['public.logistics.minSpend']}
      />

      <Field
        name="public.basePrice"
        className="form-control"
        component={formField}
        label="Minimum price per head (value in £)"
        labelClassName="form-control-label"
        required
        serverError={errors['public.basePrice']}
      />

      <Field
        name="public.commission"
        className="form-control"
        component={formField}
        label="Commission %"
        labelClassName="form-control-label"
        required
        serverError={errors['public.commission']}
      />

      <Field
        name="public.logistics.maxBookingsPerDay"
        className="form-control"
        component={formField}
        label="Max bookings per day"
        labelClassName="form-control-label"
        required
        serverError={errors['public.logistics.maxBookingsPerDay']}
      />

      <br />
      <label>Supplier‘s VAT Information</label>

      <Field
        name="public.logistics.VATRegistered"
        className="form-control"
        component={formCheckbox}
        label="VAT Registered"
        labelClassName="form-control-label"
      />

      <Field
        name="public.logistics.VATNumber"
        className="form-control"
        component={formField}
        label="VAT Number"
        labelClassName="form-control-label"
        serverError={errors['public.logistics.VATNumber']}
      />

      <Field
        name="public.logistics.registeredName"
        className="form-control"
        component={formField}
        label="Registered Name"
        labelClassName="form-control-label"
        serverError={errors['public.logistics.registeredName']}
      />

      <Field
        name="public.logistics.companyRegistrationNumber"
        className="form-control"
        component={formField}
        label="Company Registration Number"
        labelClassName="form-control-label"
        serverError={errors['public.logistics.companyRegistrationNumber']}
      />

      <Field
        name="public.logistics.registeredLocationLine1"
        className="form-control"
        component={formField}
        label="Registered Address Line 1"
        labelClassName="form-control-label"
        serverError={errors['public.logistics.registeredLocationLine1']}
      />

      <Field
        name="public.logistics.registeredLocationLine2"
        className="form-control"
        component={formField}
        label="Registered Address Line 2"
        labelClassName="form-control-label"
        serverError={errors['public.logistics.registeredLocationLine2']}
      />

      <Field
        name="public.logistics.registeredCity"
        className="form-control"
        component={formField}
        label="Registered Address City"
        labelClassName="form-control-label"
        serverError={errors['public.logistics.registeredCity']}
      />

      <Field
        name="public.logistics.registeredPostcode"
        className="form-control"
        component={formField}
        label="Registered Address Postcode"
        labelClassName="form-control-label"
        serverError={errors['public.logistics.registeredPostcode']}
      />

      <Field
        name="external.iZettleStaffName"
        className="form-control"
        component={formField}
        label="iZettle Staff Name (Only edit this field if you know what you are doing!)"
        labelClassName="form-control-label"
        serverError={errors['external.iZettleStaffName']}
      />

      <br />
    </div>
  );
};

SupplierFields.defaultProps = {
  supplierImages: [],
  stateValue: '',
};

SupplierFields.propTypes = {
  data: PropTypes.shape({}).isRequired,
  setUploadedFile: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  reorderImages: PropTypes.func.isRequired,
  supplierImages: PropTypes.arrayOf(PropTypes.shape({})),
  stateValue: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { supplier } = state;

  return {
    supplier,
  };
};

export default connect(mapStateToProps)(SupplierFields);
