export const ADDITIONAL_INFO_COURSES = {
  canapes: 'Canapés',
  starters: 'Starters',
  mains: 'Mains',
  desserts: 'Desserts',
};

export const ADDITIONAL_INFO_DECISIONS = {
  yes: 'Yes',
  no: 'No',
  not_sure: 'Not sure',
};

export const ADDITIONAL_INFO_BAR_TYPE = {
  cash: 'Cash',
  open: 'Open',
  mixed: 'Mixed',
  not_sure: 'Not sure',
};

export const SUPPLIER_SETUP_DRINKS_STREET_FOOD = [
  {
    label: 'Indoors',
    value: 'Indoors',
  },
  {
    label: 'Outdoors',
    value: 'Outdoors',
  },
  {
    label: 'A mix of both',
    value: 'A mix of both',
  },
  {
    label: 'Not sure',
    value: 'Not sure',
  },
];

export const SUPPLIER_SETUP_CATERING_PRIVATE_CHEF = [
  {
    label: 'Indoors with a professional kitchen',
    value: 'Indoors with a professional kitchen',
  },
  {
    label: 'Indoors with a domestic kitchen',
    value: 'Indoors with a domestic kitchen',
  },
  {
    label: 'Indoors with no kitchen provided',
    value: 'Indoors with no kitchen provided',
  },
  {
    label: 'Outdoors',
    value: 'Outdoors',
  },
  {
    label: 'Not sure',
    value: 'Not sure',
  },
];

export const ADDITIONAL_INFO_SUPPLIER_SETUP_OPTIONS = {
  mobile_bar: SUPPLIER_SETUP_DRINKS_STREET_FOOD,
  event_bar: SUPPLIER_SETUP_DRINKS_STREET_FOOD,
  caterers: SUPPLIER_SETUP_CATERING_PRIVATE_CHEF,
  private_chef: SUPPLIER_SETUP_CATERING_PRIVATE_CHEF,
  street_food: SUPPLIER_SETUP_DRINKS_STREET_FOOD,
};
