import React from 'react';
import { render } from 'react-dom';
import { browserHistory as baseHistory } from 'react-router';

import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import {
  syncHistoryWithStore,
  routerReducer,
  routerMiddleware,
} from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import { AppContainer } from 'react-hot-loader';

import * as reducers from './js/reducers';
import rootSaga from './js/sagas';
import * as services from './app-config';

import * as userActions from './js/actions/user';

import Root from './js/root';
import { initDatadogRum } from './js/modules/monitoring';

import './css/app.scss'; // eslint-disable-line
import 'file-loader?name=[name].[ext]!./.htaccess'; // eslint-disable-line

const { identityService } = services;

initDatadogRum();

const sagaMiddleware = createSagaMiddleware();

const middleware = [thunk, sagaMiddleware, routerMiddleware(baseHistory)];

const reducer = combineReducers({
  ...reducers,
  form: formReducer,
  routing: routerReducer,
});

const enhancer = compose(
  applyMiddleware(...middleware),
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f
);

export const store = createStore(reducer, enhancer);

sagaMiddleware.run(rootSaga);
if (window.devToolsExtension) {
  window.devToolsExtension.updateStore(store);
}

// initialize store from platform

const existingUser = identityService.getIdentity();

// if we have an existing identity then we should update it
// when starting the application and also periodically
if (existingUser !== null) {
  // ensure the app knows about our persisted identity, we'll update it asap
  store.dispatch(userActions.setUser(existingUser));
  store.dispatch(userActions.fetchUser(existingUser.token));
}

const history = syncHistoryWithStore(baseHistory, store);

render(
  <Root store={store} history={history} />,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./js/root', () => {
    const NextApp = require('./js/root').default; // eslint-disable-line global-require

    render(
      <AppContainer>
        <NextApp store={store} history={history} />
      </AppContainer>,
      document.getElementById('app')
    );
  });
}
