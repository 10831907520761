export const DIETARY_TAGS_PID = '2';
export const CUISINE_TAGS_PID = '3';
export const CUSTOM_TAGS_PID = '10';
export const SETUP_TAGS_PID = '4';
export const EVENT_TYPE_TAGS_PID = '5';

export const TAG_CATEGORY_MAP = {
  5: 'Event Type',
  11: 'Access Type',
  17: 'Coverage Hours',
  3: 'Food Type',
  2: 'Dietary',
  4: 'Food Setup',
  12: 'Drink',
  13: 'Photo & video Type',
  14: 'Marquee Type',
  15: 'Florist Type',
  16: 'Venue Type',
  18: 'Staff Type',
};
