import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import classNames from 'classnames';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarItem from '../partials/calendar-item';
import setHeaderColor from '../../actions/header';
import {
  fetchSupplier,
  fetchSupplierCalendar,
  deleteSupplierCalendarItem,
} from '../../actions/suppliers';
import { getCalendarEvents } from '../../selectors/calendar';

const views = ['month', 'day'];

class SupplierCalendar extends Component {
  static defaultProps = {
    supplier: {},
  };

  static propTypes = {
    fetchSupplier: PropTypes.func.isRequired,
    fetchSupplierCalendar: PropTypes.func.isRequired,
    deleteSupplierCalendarItem: PropTypes.func.isRequired,
    supplier: PropTypes.shape({
      name: PropTypes.string,
      uuid: PropTypes.string,
    }),
    setHeaderColor: PropTypes.func.isRequired,
    params: PropTypes.shape({
      supplierId: PropTypes.string.isRequired,
    }).isRequired,
    calendarEvents: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        raw: PropTypes.shape({
          uuid: PropTypes.string.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        booking: PropTypes.shape({
          id: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          customer: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
          }).isRequired,
        }),
      }).isRequired
    ).isRequired,
  };

  state = {
    formOpen: false,
    item: {},
  };

  componentDidMount() {
    this.props.setHeaderColor('white');
    this.props.fetchSupplier(this.props.params.supplierId);
    this.props.fetchSupplierCalendar(this.props.params.supplierId);
  }

  styleCalendarItems = ({ state }) => ({
    className: `calendar-item--${state}`,
  });

  handleSelect = ({ start, end }) => {
    this.setState({
      formOpen: true,
      item: {
        startTime: moment(start).format('YYYY-MM-DD'),
        endTime: moment(end).format('YYYY-MM-DD'),
        supplierId: this.props.supplier.uuid,
      },
    });
  };

  handleSelectItem = (item) => {
    this.setState({
      formOpen: true,
      item: {
        ...item.raw,
        startTime: moment(item.raw.startTime).format('YYYY-MM-DD'),
        endTime: moment(item.raw.endTime).format('YYYY-MM-DD'),
      },
    });
  };

  handleDelete = (uuid) => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      const { supplier } = this.props;
      this.setState({ formOpen: false });
      this.props.deleteSupplierCalendarItem(supplier.uuid, uuid);
    }
  };

  closeForm = () => this.setState({ formOpen: false });

  render() {
    const { supplier, calendarEvents } = this.props;

    const { formOpen } = this.state;
    const localizer = momentLocalizer(moment);

    return (
      <main className="view view--bookings">
        <div className="container page-title clearfix">
          <h1 className="pull-left">{supplier.name}&#8217;s Calendar</h1>
          <Link className="footer__marque pull-right" to="/admin/suppliers">
            {'< Back to all Suppliers'}
          </Link>
        </div>

        <div className="container">
          <div className="row">
            <div
              className={classNames('calendar', {
                'col-md-10': formOpen,
                'col-md-12': !formOpen,
              })}
            >
              <Calendar
                localizer={localizer}
                events={calendarEvents}
                startAccessor="startTime"
                endAccessor="endTime"
                titleAccessor="title"
                defaultView="month"
                views={views}
                selectable
                onSelectEvent={this.handleSelectItem}
                onSelectSlot={this.handleSelect}
                eventPropGetter={this.styleCalendarItems}
              />
            </div>
            {formOpen && (
              <CalendarItem
                initialValues={this.state.item}
                closeForm={this.closeForm}
                handleDelete={this.handleDelete}
              />
            )}
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = ({ supplier, ...state }) => ({
  supplier,
  calendarEvents: getCalendarEvents(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setHeaderColor,
      fetchSupplier,
      fetchSupplierCalendar,
      deleteSupplierCalendarItem,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SupplierCalendar)
);
