import React, {useEffect, useState} from 'react';
import { useMutation } from '@apollo/client';

import { UPLOAD_PUBLIC_IMAGE_MUTATION } from '../../mutations/upload-public-image';
import {captureError} from "../../modules/monitoring";
import {ENTITY_OPTIONS} from "../../constants/upload-public-image";

const UploadPublicImage = () => {
  const [uploadImage] = useMutation(UPLOAD_PUBLIC_IMAGE_MUTATION);
  const [file, setFile] = useState(null);
  const [entityId, setEntityId] = useState('');
  const [entityType, setEntityType] = useState('');
  const [image, setImage] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    let timeoutId;
    if (isCopied) {
      timeoutId = setTimeout(() => {
        setIsCopied(false);
      }, 3500);
    }
    return () => clearTimeout(timeoutId);
  }, [isCopied]);

  const validateFields = () => {
    const newErrors = {};

    if (!entityId) {
      newErrors.entityId = 'Salesforce ID is required.';
    }
    if (!entityType) {
      newErrors.entityType = 'Please select an entity.';
    }
    if (!file) {
      newErrors.file = 'Please upload a valid image file under 5MB.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file?.type.startsWith('image/') && file.size <= 5 * 1024 * 1024) {
      setFile(file);
      setErrors((prevErrors) => ({
        ...prevErrors,
        file: null, // Clear file error
        server: null, // Clear previous server errors
      }));
    } else {
      const errorMessage = 'Please upload a valid image file under 5MB.';

      setErrors((prevErrors) => ({ ...prevErrors, file: errorMessage }));

      captureError(new Error('Invalid image file'), {
        entityType,
        entityId,
        file: file?.name,
        errorMessage,
      });
    }
  };

  const handleImageUpload = async (event) => {
    if (event) event.preventDefault();

    if (!validateFields()) {
      return; // Stop submission if there are errors
    }

    setIsUploading(true);

    try {
      const { data: uploadPublicImageResponse } = await uploadImage({
        variables: { file, entityId, entityType },
      });

      const { uploadPublicImage } = uploadPublicImageResponse;

      if (uploadPublicImage?.imageUrl) {
        setImage(uploadPublicImage.imageUrl);
        setErrors((prevErrors) => ({
          ...prevErrors,
          server: null, // Clear server error
        }));
      } else {
        setErrors({ server: 'Failed to upload the image. Please try again.' });
      }
    } catch (error) {
      // Extract error details
      const errorMessage =
        error?.graphQLErrors?.[0]?.extensions?.exception?.meta?.cause ||
        'An unexpected error occurred. Please try again later.';

      if (errorMessage.includes('Record to update not found')) {
        setErrors({ entityId: 'The Salesforce ID you entered was not found. Please check and try again.' });
      } else {
        setErrors({ server: errorMessage });
      }

      captureError(error, {
        entityType,
        entityId,
        file: file?.name,
        errorMessage,
      } );

    } finally {
      setIsUploading(false);
      if (Object.keys(errors).length > 0) {
        captureError(new Error('Upload process stopped due to errors'), {
          entityType,
          entityId,
          file: file?.name,
          errors,
        });
      }
    }
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(image);
      setIsCopied(true);
    } catch (error) {
      captureError(error, {
        entityType,
        entityId,
        file: file?.name,
        errorMessage: 'Failed to copy the image URL to the clipboard.',
      })
    }
  };

  return (
    <main className="view upload--page">
      <div className="container page-title clearfix">
        <h1>Upload Public Image</h1>
      </div>

      <div className="container input--area">
        <form onSubmit={handleImageUpload}>
          {errors.server && <p className="field__error" role="alert">{errors.server}</p>}

          <div className="field">
            <label className={"field__label form-control-label"} htmlFor="entitySelect">Select an entity type:</label>
            <select
              name="entitySelect"
              value={entityType}
              onChange={(event) => {
                setEntityType(event.target.value);
                setErrors((prevErrors) => ({ ...prevErrors, entityType: null })); // Clear entityType error
              }}
              required
            >
              <option value="" disabled>Select an entity</option>
              {ENTITY_OPTIONS.map((option) => (
                <option key={option.key} value={option.key}>
                  {option.label}
                </option>
              ))}
            </select>
            {errors.entityType && <p className="field__error" role="alert">{errors.entityType}</p>}
          </div>

          <div className="field upload--input">
            <label className={"field__label form-control-label"} htmlFor="entityIdInput">Type the salesforce ID:</label>
            <input
              type="text"
              name="entityIdInput"
              onChange={(event) => {
                setEntityId(event.target.value);
                setErrors((prevErrors) => ({ ...prevErrors, entityId: null })); // Clear entityId error
              }}
              required
            />
            {errors.entityId && <p className="field__error" role="alert">{errors.entityId}</p>}
          </div>

          <div className="field upload--field">
            <label className={"field__label form-control-label"} htmlFor="imageInput">Choose an image to upload:</label>
            <input
              className="image--input"
              type="file"
              name="imageInput"
              accept="image/*"
              onChange={handleFileChange}
              required
            />
            {errors.file && <p className="field__error" role="alert">{errors.file}</p>}
          </div>

          <div className="field image--submit">
            <button
              type="submit"
              className="button button--blue image--submit"
              disabled={isUploading}
            >
              {isUploading ? 'Uploading...' : 'Upload'}
            </button>
          </div>
        </form>
      </div>

      {image && (
        <div className="image--area">
          <img className="event--image" src={image} alt="Uploaded public image" />
          <div className="row--grey copy--url">
            <p>
              The image has been successfully uploaded and linked to the selected entity. No further action is required, but if you need the image URL, you can copy it below:
            </p>
            <p>{image}</p>
            <button
              type="button"
              className="button button--green"
              onClick={handleCopyClick}
            >
              {isCopied ? 'Copied!' : 'Copy to clipboard'}
            </button>
          </div>
        </div>
      )}
    </main>
  );
};

export default UploadPublicImage;
