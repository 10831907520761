import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from '../../components/multi-select';

const SupplierTagsFields = ({ onChange, tags, supplierTags, label }) => (
  <MultiSelect
    label={label}
    options={tags}
    onChangeHandler={onChange}
    value={supplierTags}
    valueKey="uuid"
    labelKey="name"
  />
);

SupplierTagsFields.propTypes = {
  onChange: PropTypes.func.isRequired,
  supplierTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
};

export default SupplierTagsFields;
