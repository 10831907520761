/* eslint-disable array-callback-return, react/no-direct-mutation-state */
import React, { Component } from 'react';
import $ from 'jquery';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  fetchRootTags,
  fetchChildrenTags,
  createTag,
  removeTag,
  updateTag,
} from '../../actions/tags';
import setHeaderColor from '../../actions/header';
import { setUser } from '../../actions/user';
import TagGroup from '../../components/tag-group';

class Tags extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      newTags: {},
      selectedTag: { id: 0, name: '' },
    };
  }

  componentWillMount() {
    this.loadTags();
    this.props.setHeaderColor('white');
    this.update(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user === null) {
      this.update(nextProps);
    }
  }

  update(props) {
    const { user, router } = props;

    if (user == null) {
      router.replace('/login');
    }
  }

  loadTags() {
    this.setState({ isLoading: true });
    this.props
      .fetchRootTags()
      .then(() => {
        this.props.fetchChildrenTags();
      })
      .then(() => {
        this.setState({
          isLoading: false,
        });
        this.setTagsState();
      })
      .catch((err) => {
        if (err.status === 401) {
          this.props.setUser(null);
        }
      });
  }

  setTagsState() {
    const newTags = {};
    this.props.rootTags.map((tag) => {
      const name = this.state.newTags[tag.uuid]
        ? this.state.newTags[tag.uuid].name
        : '';
      newTags[tag.uuid] = { root_tag_id: tag.uuid, name };
    });
    this.setState({ newTags });
  }

  createTag(newTag) {
    if (this.state.newTags[newTag.uuid].name !== '') {
      this.props.createTag(this.state.newTags[newTag.uuid]).then(() => {
        this.state.newTags[newTag.uuid].name = '';
        this.loadTags();
      });
    }
  }

  removeTag(tag) {
    if (
      window.confirm(
        'Are you sure you want to delete this tag? If not, please speak to an admin.'
      )
    ) {
      this.props.removeTag(tag.uuid).then(() => {
        this.loadTags();
      });
    }
  }

  onChange(newTag, event) {
    this.state.newTags[newTag.uuid].name = event.target.value;
    this.setTagsState();
  }

  toggleEditTag(tag) {
    this.setState({ selectedTag: tag });

    // bad solution
    const selector = `input[name="some_tag_${tag.id}"]`;
    setTimeout(() => {
      const el = $(selector).eq(0);
      el.focus();
    }, 200);
  }

  onChangeExistedTag(event) {
    const { selectedTag } = this.state;
    selectedTag.name = event.target.value;
    this.setState({ selectedTag });
  }

  updateTag() {
    const { selectedTag } = this.state;

    this.toggleEditTag({ uuid: 0, name: '' });
    this.props.updateTag(selectedTag).catch((error) => console.error(error));
  }

  render() {
    const rootTags = this.props.rootTags;
    const childrenTags = this.props.childrenTags;
    const tagsByRoot = {};
    rootTags.map((tag) => {
      tagsByRoot[tag.name] = [];
    });
    childrenTags.map((tag) => {
      if (tag.rootTag) {
        if (!tagsByRoot[tag.rootTag.name]) tagsByRoot[tag.rootTag.name] = [];
        tagsByRoot[tag.rootTag.name].push(tag);
      }
    });
    return (
      <main className="view view--bookings">
        <div className="container page-title">
          <h1>Tags</h1>
        </div>
        <div className="row--grey">
          <div className="container">
            {!!rootTags.length &&
              rootTags.map((rootTag, i) => (
                <TagGroup
                  key={rootTag.id}
                  groupTags={tagsByRoot[rootTag.name]}
                  rootTag={rootTag}
                  selectedTag={this.state.selectedTag}
                  removeHandler={this.removeTag.bind(this)}
                  clickHandler={this.createTag.bind(this, rootTag)}
                  changeHandler={this.onChange.bind(this, rootTag)}
                  newTag={this.state.newTags[rootTag.uuid]}
                  toggleEditTag={this.toggleEditTag.bind(this)}
                  updateTag={this.updateTag.bind(this)}
                  changeTagHandler={this.onChangeExistedTag.bind(this)}
                />
              ))}
          </div>
        </div>
      </main>
    );
  }
}

function mapStateToProps({ rootTags, childrenTags, user }) {
  return { rootTags, childrenTags, user };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUser,
      setHeaderColor,
      fetchRootTags,
      fetchChildrenTags,
      createTag,
      removeTag,
      updateTag,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(Tags));
