import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';

class TextEditor extends Component {
  static propTypes = {
    meta: PropTypes.shape({ initial: PropTypes.string }).isRequired,
    input: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func.isRequired,
    }).isRequired,
  };

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  state = {
    value: this.props.meta.initial,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.input.value !== this.state.value) {
      this.setValue(nextProps.input.value);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.meta.initial !== this.props.meta.initial) {
      this.setValue(this.props.meta.initial);
    }
  }

  onChange = (value) => {
    this.setValue(value);
  };

  setValue = (value) => {
    const { input } = this.props;

    this.setState({ value });
    if (input.onChange) {
      input.onChange(value);
    }
  };

  render() {
    const {
      meta: { touched, error },
    } = this.props;
    const { value } = this.state;

    return (
      <div className="text-editor u-mb--m">
        <ReactQuill
          modules={this.modules}
          value={value}
          onChange={this.onChange}
          theme="snow"
        />
        {touched && error && <span className="field__error">{error}</span>}
      </div>
    );
  }
}

export default TextEditor;
