import { gql } from '@apollo/client';

export const EDIT_EVENT_MUTATION = gql`
  mutation ($eventUuid: String!, $data: editEventInput!) {
    editEvent(eventUuid: $eventUuid, data: $data) {
      date
      type
      urgency
      start_time
      end_time
      location_city
      location_line_1
      location_line_2
      postcode
      custom_event_name
    }
  }
`;

export const EDIT_EVENT_WITH_PARTNERSHIP_ID_MUTATION = gql`
  mutation ($eventUuid: String!, $data: editEventInput!) {
    editEvent(eventUuid: $eventUuid, data: $data) {
      uuid
    }
  }
`;
