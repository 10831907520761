import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import setHeaderColor from '../../actions/header';
import { setUser } from '../../actions/user';
import { fetchUsers, deleteUser } from '../../actions/users';
import TableSearch from '../../components/table-search';
import TableList from '../../components/table-list';
import Pagination from '../../components/pagination';
import Button from '../../components/button';

class UsersPage extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      query: '',
    };
  }

  getParams(location) {
    return {
      q: location.query.q || '',
      limit: location.query.limit || 10,
      offset: location.query.offset !== undefined ? location.query.offset : 0,
    };
  }

  componentWillMount() {
    this.props.setHeaderColor('white');
    this.update(this.props);
    const params = this.getParams(this.props.location);
    this.setState({ query: params.q });
    this.loadUsers(params);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user === null) {
      this.update(nextProps);
    }
    const oldParams = this.getParams(this.props.location);
    const params = this.getParams(nextProps.location);
    if (JSON.stringify(oldParams) !== JSON.stringify(params)) {
      this.setState({ query: params.q });
      this.loadUsers(params);
    }
  }

  update(props) {
    const { user, router } = props;

    if (user == null) {
      router.replace('/login');
    }
  }

  loadUsers({ q = '', limit = 10, offset = 0 } = {}) {
    this.setState({ isLoading: true });
    this.props
      .fetchUsers({ q, limit, offset })
      .then(() => this.setState({ isLoading: false }))
      .catch((err) => {
        if (err.status === 401) {
          this.props.setUser(null);
        }
      });
  }

  changeQuery(query) {
    this.setState({ query });
    this.loadUsers({ q: query });
  }

  newUserClick = () => this.props.router.push('/admin/users/new');

  deleteUser = (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      this.props.deleteUser(id);
    }
  };

  render() {
    const urlPath = this.props.location.pathname;
    const { users } = this.props;

    const columns = [
      {
        name: 'firstName',
        title: 'First Name',
      },
      {
        name: 'lastName',
        title: 'Last Name',
      },
      {
        name: 'email',
        title: 'Email',
      },
      {
        name: 'phone',
        title: 'Phone Number',
      },
      {
        name: 'created',
        title: 'Joining Date',
        cell: (props) => moment(props.value).format('DD/MM/YYYY'),
      },
      {
        name: 'uuid',
        title: 'Manage',
        cell: (props) => {
          return (
            <Link
              to={`/admin/users/${props.original.id}`}
              activeClassName="t--bold"
            >
              <button className="button button--green pull-right">View</button>
            </Link>
          );
        },
      },
      {
        name: 'delete',
        title: 'Delete',
        cell: (props) => {
          return (
            <button
              className="button button--red"
              onClick={() => this.deleteUser(props.original.id)}
            >
              Delete
            </button>
          );
        },
      },
    ];

    return (
      <main className="view view--bookings">
        <div className="container page-title">
          <h1>Users</h1>
        </div>

        <div className="row--padded-m">
          <div className="container">
            <div className="clearfix">
              <TableSearch
                className="pull-left"
                label="Search query"
                input={this.state.query}
                onChange={this.changeQuery.bind(this)}
              />

              <Button
                className="pull-right"
                color="green"
                text="+ Add new user"
                onClick={this.newUserClick}
              />
            </div>

            <TableList data={users} columns={columns} />

            <Pagination
              paging={this.props.paging}
              totalRecords={this.props.totalRecords}
              data={users}
              currentUrl={urlPath}
              onClick={this.loadUsers.bind(this)}
            />
          </div>
        </div>
      </main>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    { setUser, setHeaderColor, fetchUsers, deleteUser },
    dispatch
  );
}

const mapStateToProps = ({ users, user }) => ({
  users: users.items,
  paging: users.paging,
  totalRecords: users.totalRecords,
  user,
});

export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(UsersPage)
);
