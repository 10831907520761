import { store } from '../..';
import { setUser } from '../actions/user';

export default class HttpService {
  constructor(baseUri, httpMiddleware = []) {
    this.baseUri = baseUri;
    this.httpMiddleware = httpMiddleware;
    this.fetch = createFetchingFunctionFromMiddleware(baseUri, httpMiddleware);
  }

  async requestCore(request) {
    const response = await this.fetch(request);

    // todo: review this
    const responseBody = await tryGetResponseBody(response);

    if (!isStatusOkay(response.status)) {
      throw createServiceError(responseBody, response.status);
    }

    return responseBody;
  }

  async request(request) {
    const responseBody = await this.requestCore(request);

    if (responseBody == null) {
      return null;
    }

    return responseBody.data || responseBody;
  }
}

function createServiceError(responseBody, responseStatus) {
  const error = new Error('SERVICE_CALL_ERROR');
  error.response =
    responseBody &&
    ((responseBody.error && responseBody.error.extra) ||
      responseBody.error ||
      responseBody.errors ||
      responseBody.info ||
      responseBody.message);

  // Priortise API response body custom status, fall back to HTTP
  error.status = (responseBody && responseBody.status) || responseStatus;
  error.code = (responseBody && responseBody.code) || 0;
  error.info = (responseBody && responseBody.info) || '';

  if ([401, 403].includes(error.status)) {
    store.dispatch(setUser(null));
  }

  return error;
}

const isStatusOkay = (status) =>
  (status >= 200 && status < 300) || status === 304;

async function tryGetResponseBody(response) {
  try {
    return await response.json();
  } catch (err) {
    return err;
  }
}

function createFetchingFunctionFromMiddleware(baseUri, httpMiddleware) {
  return async (request) => {
    const chain = [...httpMiddleware];

    async function next(request) {
      const handler = chain.shift();

      if (handler != null) {
        return await handler(request, next);
      }
      const uri = baseUri + request.path;
      return await fetch(uri, request);
    }

    return await next(request);
  };
}
