import { gql } from '@apollo/client';

export const EDIT_EVENT_REQUEST = gql`
  mutation editEventRequest(
    $requestId: String!
    $data: completeEventRequestData!
  ) {
    editEventRequest(requestId: $requestId, data: $data) {
      uuid
      id
    }
  }
`;
