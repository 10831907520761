import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router';
import classNames from 'classnames';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import { fetchUser, updateUser } from '../../actions/users';

import { FormikMultiSelect } from '../../components/multi-select';

const EditUserForm = ({ params, onFetchUser, onUpdateUser, customer }) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    onFetchUser(params.id);
  }, [params.id, onFetchUser]);

  const initialValues = useMemo(() => {
    return {
      email: customer.email || '',
      type: { label: customer.type },
      segment: { label: customer.segment },
      businessUser: customer.business_user || false,
    };
  }, [customer]);

  const handleSubmit = (values) => {
    const {
      type: { label: customerLabel },
      segment: { label: customerSegment },
      businessUser,
    } = values;
    try {
      onUpdateUser(
        customer.id,
        omitBy(
          {
            ...values,
            type: customerLabel,
            segment: customerSegment,
            business_user: businessUser,
          },
          isNil
        )
      );

      setHasSubmitted(true);
      setTimeout(() => setHasSubmitted(false), 3000);
    } catch (err) {
      //
    }
  };
  return (
    <div className="container">
      <h1 style={{ margin: '0 0 16px 0' }}>Edit User</h1>
      <Link className="footer__marque pull-right" to="/admin/users">
        {'< Back to all users'}
      </Link>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ dirty }) => (
          <Form>
            <div className="row">
              <div className="col-md-6">
                <div className="field">
                  <label className="field__label form-control-label">
                    Email
                  </label>
                  <Field className="form-control" name="email" />
                </div>

                <div className="field">
                  <label className="field__label form-control-label">
                    Type
                  </label>
                  <Field
                    name="type"
                    as={FormikMultiSelect}
                    multi={false}
                    options={[{ label: 'customer' }, { label: 'supplier' }]}
                  />
                </div>

                <div className="field">
                  <label className="field__label form-control-label">
                    Segment
                  </label>
                  <Field
                    name="segment"
                    as={FormikMultiSelect}
                    multi={false}
                    options={[
                      { label: 'Bride/Groom' },
                      { label: 'Individual consumer' },
                      { label: 'Corporate' },
                      { label: 'Professional Event Planner' },
                    ]}
                  />
                </div>

                <div className="field">
                  <Field name="businessUser">
                    {({ field }) => (
                      <div>
                        <label className="checkbox_label form-control-label">
                          Togather Business User
                        </label>
                        <input
                          type="checkbox"
                          {...field}
                          checked={field.value}
                          className="checkbox"
                        />
                      </div>
                    )}
                  </Field>
                </div>

                <p className="field__label--after">
                  If <b>checked</b>, this customer will be shown the Togather
                  Business dashboard.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <button
                  disabled={!dirty}
                  type="submit"
                  className={classNames('button', {
                    'button--grey': !dirty,
                    'button--blue': dirty,
                  })}
                >
                  Save
                </button>

                {hasSubmitted && (
                  <span style={{ marginLeft: '8px' }} className="text-success">
                    Saved Successfully
                  </span>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapDispatchToProps = {
  onFetchUser: fetchUser,
  onUpdateUser: updateUser,
};

const mapStateToProps = ({ customer }) => ({
  customer,
});
export default connect(mapStateToProps, mapDispatchToProps)(EditUserForm);
