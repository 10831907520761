import {
  toPennies,
  calculateRateValue,
  calculateCommissionFee,
  addRate,
} from '@feast-it/finance';

// Used to show suppliers a cost breakdown whilst quoting
export const calculateCostBreakdown = ({
  total,
  commission,
  service_fee,
  old_commission,
}) => {
  const traderValue = toPennies(total) || 0;

  const commissionValue = old_commission
    ? calculateRateValue(traderValue, commission)
    : calculateCommissionFee(traderValue, commission);

  const baseTotal = traderValue + commissionValue;
  const customerTotal = addRate(baseTotal, service_fee);
  const serviceValue = calculateRateValue(baseTotal, service_fee);

  return {
    traderValue,
    commissionValue,
    baseTotal,
    customerTotal,
    serviceValue,
  };
};
