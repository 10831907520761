import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'formik';

import Asterisk from './asterisk';

export default function MultiSelect({
  input,
  label,
  options,
  onChangeHandler,
  value,
  required,
  ...rest
}) {
  return (
    <div className="section field">
      <label className="field__label field__label--touched form-control-label">
        {label}
        <Asterisk required={required} />
      </label>
      <Select
        multi
        backspaceRemoves
        value={value}
        onChange={onChangeHandler}
        options={options}
        required={required}
        {...rest}
      />
    </div>
  );
}

const FormikSelectBase = ({
  name,
  value,
  label,
  options,
  required,
  multi = true,
  formik: { errors, touched, setFieldValue },
  ...props
}) => {
  const onChange = useCallback(
    (nextValue) => {
      setFieldValue(name, nextValue);
    },
    [setFieldValue, name]
  );

  return (
    <div className="section field">
      <label htmlFor={label} className="field__label form-control-label">
        {label}
      </label>
      <Select
        name={name}
        multi={multi}
        backspaceRemoves
        value={value}
        onChange={(obj) => onChange(obj)}
        options={options}
        required={required}
      />
    </div>
  );
};

FormikSelectBase.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  formik: PropTypes.shape({
    errors: PropTypes.objectOf(PropTypes.string).isRequired,
    touched: PropTypes.objectOf(PropTypes.bool).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

export const FormikMultiSelect = connect(FormikSelectBase);
