import { gql } from '@apollo/client';

export const MARK_SUPPLIER_AS_PAID = gql`
  mutation ($quoteId: String!) {
    markSupplierAsPaid(quoteId: $quoteId) {
      uuid
      quote_id
    }
  }
`;
