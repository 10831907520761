import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { getStoredIdentity } from '../../services/browser-storage-identity-service';
import {
  isCognitoAuthentication,
  getLatestCognitoToken,
} from '../../modules/cognito';

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_API_BASE_URI,
  headers: {
    'keep-alive': 'true',
  },
});

const authLink = setContext(async (_, { headers }) => {
  const { token, id } = getStoredIdentity() ?? {};

  if (isCognitoAuthentication(token)) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${await getLatestCognitoToken()}`,
        'x-new-auth': true,
        'x-user-id': id,
      },
    };
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache(),
  credentials: 'include',
});
