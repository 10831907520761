import { createSelector } from 'reselect';
import {
  constructCostBreakdown,
  toPounds,
  formatCurrency,
} from '@feast-it/finance';

const getQuotes = (state) => state.quotes.results;

export const getQuotesTable = createSelector(getQuotes, (quotes) =>
  quotes.map((quote) => {
    const { customerTotal } = constructCostBreakdown(quote);

    return {
      ...quote,
      customerTotal: formatCurrency(toPounds(customerTotal)),
    };
  })
);
