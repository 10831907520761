import merge from 'lodash/merge';

import {
  ADD_DIETARY_REQUIREMENT,
  REMOVE_DIETARY_REQUIREMENT,
  RESET_EVENT,
  SET_EVENT,
} from '../constants/action-types';

const initialState = {
  dietaryItems: [],
  total: 0,
};

function addRequirement(items, action) {
  const existingIndex = items.findIndex(
    (item) => item.id === action.payload.id
  );
  if (existingIndex > -1) {
    items[existingIndex].quantity = action.payload.quantity;
  } else {
    items = [...items, action.payload];
  }
  return items;
}

function removeRequirement(items, action) {
  const existingIndex = items.findIndex((item) => item.id === action.payload);
  if (existingIndex > -1) {
    items[existingIndex].quantity -= 1;
    if (items[existingIndex].quantity === 0) {
      items.splice(existingIndex, 1);
    }
  }
  return items;
}

export default function (state = initialState, action) {
  const items = state.dietaryItems;
  switch (action.type) {
    case RESET_EVENT:
      return initialState;
    case ADD_DIETARY_REQUIREMENT:
      return merge({}, state, {
        dietaryItems: addRequirement(items, action, state),
      });
    case REMOVE_DIETARY_REQUIREMENT:
      return merge({}, state, {
        dietaryItems: removeRequirement(items, action),
      });
    case SET_EVENT:
      return merge({}, state, {
        dietaryItems: action.payload,
      });
    default:
      return state;
  }
}
