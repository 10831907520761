import {
  FETCH_USERS,
  FETCH_USER,
  RESET_USERS,
  DELETE_USER,
  UPDATE_USER,
} from '../constants/action-types';
import { httpService } from '../../app-config';

export function fetchUsers(params) {
  return (dispatch) =>
    httpService
      .request({
        path: `/admin/search/users?q=${params.q}&limit=${params.limit}&offset=${params.offset}`,
      })
      .then((response) => response)
      .then((payload) => dispatch({ type: FETCH_USERS, payload }));
}

export function fetchUser(id) {
  return (dispatch) =>
    httpService
      .request({
        path: `/admin/customers/${id}`,
      })
      .then((payload) => dispatch({ type: FETCH_USER, payload }));
}

export function deleteUser(id) {
  return (dispatch) =>
    httpService
      .request({
        method: 'DELETE',
        path: `/users/${id}`,
      })
      .then(() => dispatch({ type: DELETE_USER, payload: { id } }));
}

export function updateUser(id, data) {
  return (dispatch) =>
    httpService
      .request({
        method: 'PATCH',
        path: `/admin/customers/${id}`,
        body: data,
      })
      .then((payload) => dispatch({ type: UPDATE_USER, payload }));
}

export const resetUsers = () => ({ type: RESET_USERS });
