import React from 'react';
import { PropTypes } from 'prop-types';
import { useMutation } from '@apollo/client';

import Button from '../../components/button';
import Spinner from '../../components/spinner';
import {
  handleOnImpersonateUser,
  handleOnImpersonateUserV2,
} from '../../modules/impersonateUser';
import { IMPERSONATE_USER_MUTATION } from '../../mutations/impersonate-user';
import { useImpersonationV2 } from '../../hooks/useImpersonationV2';

const SupplierUsers = ({
  supplierUsers,
  addUser,
  searchUsers,
  removeUser,
  handleStateChange,
  usersLoading,
  userSearchResults,
}) => {
  const [impersonateMutation] = useMutation(IMPERSONATE_USER_MUTATION);
  const { isImpersonationV2Enabled } = useImpersonationV2();

  return (
    <div>
      <label>Supplier Users</label>

      {supplierUsers &&
        supplierUsers.map((user) => (
          <div className="supplier__item" key={user.userId}>
            <div className="supplier__items-column">
              <h4>
                {user.firstName} {user.lastName}
              </h4>
              <p>{user.email}</p>
            </div>

            <div className="supplier__user-column--right">
              <Button
                color="blue"
                text="Impersonate"
                onClick={() => {
                  if (isImpersonationV2Enabled) {
                    handleOnImpersonateUserV2({
                      userId: user.userId,
                      userType: 'supplier',
                    });
                  } else {
                    handleOnImpersonateUser(
                      impersonateMutation,
                      user.userId,
                      'suppliers/dashboard'
                    );
                  }
                }}
              />

              <Button
                color="red"
                text="Remove"
                onClick={() => removeUser(user.userId)}
              />
            </div>
          </div>
        ))}

      {!supplierUsers.length && (
        <div className="supplier__group">Supplier currently has no users.</div>
      )}

      <div className="field supplier__group--add">
        <label>Add User</label>

        <input
          id="userSearch"
          placeholder="Search users"
          onChange={({ target: { value } }) =>
            handleStateChange(value, 'userSearch')
          }
          className="field__input form-control"
        />

        <Button color="blue" text="Search Users" onClick={searchUsers} />

        {usersLoading && <Spinner />}

        {!!userSearchResults.length && (
          <table className="supplier__users">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Add</th>
              </tr>
            </thead>

            <tbody>
              {userSearchResults.map((user) => (
                <tr key={user.id}>
                  <td>
                    {user.firstName} {user.lastName}
                  </td>
                  <td>{user.email}</td>
                  <td>
                    <Button
                      color="green"
                      text="Add"
                      onClick={() => addUser(user.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

SupplierUsers.defaultProps = {
  supplierUsers: [],
  usersLoading: false,
};

SupplierUsers.propTypes = {
  supplierUsers: PropTypes.arrayOf(PropTypes.shape({})),
  addUser: PropTypes.func.isRequired,
  removeUser: PropTypes.func.isRequired,
  handleStateChange: PropTypes.func.isRequired,
  userSearchResults: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchUsers: PropTypes.func.isRequired,
  usersLoading: PropTypes.bool,
};

export default SupplierUsers;
