import * as Yup from 'yup';

export const eventTypes = [
  'Corporate Event',
  'Business Catering',
  'Company Party',
  'Public-facing Event',
  'Wedding',
  'Birthday',
  'Dinner Party',
  'Celebration',
  'Event',
  'Virtual Event',
];

const rating = Yup.number()
  .integer()
  .min(0, 'Must be between 0-5')
  .max(5, 'Must be between 0-5')
  .nullable();

const baseSchema = {
  published: Yup.boolean(),
  publicComment: Yup.string()
    .min(10, 'Too short!')
    .max(5000, 'Too long!')
    .required('Required'),
  ratingValue: rating,
  ratingQuality: rating,
  ratingProfessionalism: rating,
  ratingFriendliness: rating,
};

export const updateSchema = Yup.object().shape({
  ...baseSchema,
});

export const createSchema = Yup.object().shape({
  ...baseSchema,
  supplierId: Yup.string().typeError('Required').required('Required'),
  privateComment: Yup.string(),
  eventDate: Yup.date('Invalid date').required('Required'),
  eventType: Yup.string()
    .oneOf(eventTypes, 'Invalid value')
    .required('Required'),
  customerName: Yup.string().required('Required'),
});
