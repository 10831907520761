import React from 'react';
import PropTypes from 'prop-types';
import Imgix from 'react-imgix';

import { ReactComponent as InvalidIcon } from '../../images/sprite/ui/invalid.svg';
import { IMGIX_IMAGE_PROPS } from '../constants/imgix';

export default function Image({ image, onRemoveClick }) {
  if (!image || !image.url) {
    return null;
  }

  return (
    <div className="image-draggable">
      <Imgix
        className="image-item"
        src={image.url}
        imgixProps={IMGIX_IMAGE_PROPS}
        width={100}
        height={100}
      />
      {onRemoveClick && (
        <InvalidIcon
          className="icon image-draggable-remove"
          onMouseDown={(evt) => {
            evt.stopPropagation();
            onRemoveClick(image);
          }}
        />
      )}
    </div>
  );
}

Image.defaultProps = {
  onRemoveClick: null,
};

Image.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  onRemoveClick: PropTypes.func,
};
