import React from 'react';
import { Field, reduxForm, propTypes } from 'redux-form';
import { Link } from 'react-router';
import { addUser } from '../../actions/user';
import FormField from '../../components/form-field';
import FormSelect from '../../components/form-select';
import Button from '../../components/button';

import { scrollToFirstError } from '../../libs/form-validation-utils';

const USER_TYPES = [
  { value: 'customer', label: 'User' },
  { value: 'supplier', label: 'Supplier' },
  { value: 'admin', label: 'Admin' },
];

const handleOnSubmit = (data) => addUser(data);
const handleOnSubmitSuccess = (result, dispatch, props) =>
  props.router.push('/admin/users');

const required = (value) => (value ? undefined : 'Required');
const number = (value) =>
  value && isNaN(Number(value)) ? 'Numbers only' : undefined;

const AddUserForm = ({
  handleSubmit,
  submitFailed,
  submitSucceeded,
  submitting,
}) => (
  <main className="view view--bookings">
    <div className="container page-title clearfix">
      <h1 className="pull-left">Add User</h1>
      <Link className="footer__marque pull-right" to="/admin/users">
        {'< Back to all users'}
      </Link>
    </div>

    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <Field
              name="email"
              type="email"
              component={FormField}
              className="form-control"
              label="Email"
              labelClassName="form-control-label"
              validate={required}
              required
            />

            <Field
              name="firstName"
              type="text"
              component={FormField}
              className="form-control"
              label="First Name"
              labelClassName="form-control-label"
              validate={required}
              required
            />

            <Field
              name="lastName"
              type="text"
              component={FormField}
              className="form-control"
              label="Last Name"
              labelClassName="form-control-label"
              validate={required}
              required
            />

            <Field
              name="phone"
              type="number"
              component={FormField}
              className="form-control"
              label="Phone"
              labelClassName="form-control-label"
              validate={number}
            />

            <Field
              name="type"
              component={FormSelect}
              className="form-control"
              label="Type"
              labelClassName="form-control-label"
              options={USER_TYPES}
            />

            <Button
              type="submit"
              color="green"
              text="Create User"
              isLoading={submitting}
            />

            {submitFailed === true && (
              <span className="text-danger">Create User Failed</span>
            )}
            {submitSucceeded === true && (
              <span className="text-success">User Created</span>
            )}
          </div>
        </div>
      </form>
    </div>
  </main>
);

AddUserForm.propTypes = { ...propTypes };

export default reduxForm({
  form: 'customer',
  onSubmit: handleOnSubmit,
  onSubmitSuccess: handleOnSubmitSuccess,

  onSubmitFail: (errors, dispatch, submitError) => {
    scrollToFirstError(errors);

    if (typeof submitError !== 'function') {
      throw submitError;
    }
  },
})(AddUserForm);
