import React from 'react';
import { Field, reduxForm } from 'redux-form';
import formField from '../../components/form-field';
import { GoogleSignInButton } from '../../components/google-sign-in-button';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Incorrect email address';
  }
  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 8) {
    errors.password = 'Invalid password';
  }
  return errors;
};

const LoginForm = (props) => {
  const { handleSubmit, submitting, error } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Field name="email" type="email" component={formField} label="Email" />
      <Field
        name="password"
        type="password"
        component={formField}
        label="Password"
      />
      <span className="form-error">{error}</span>
      <div className="form-footer form-footer--spaced">
        <button
          className="button button--blue"
          type="submit"
          disabled={submitting}
        >
          Sign In
        </button>
        <GoogleSignInButton />
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'login', // a unique identifier for this form
  validate, // <--- validation function given to redux-form
})(LoginForm);
