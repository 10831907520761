import { HEADER } from '../constants/action-types';

function setHeaderColor(state) {
  return {
    type: HEADER,
    payload: state,
  };
}

export default setHeaderColor;
