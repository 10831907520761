import {
  SUPPLIER_REVIEW_SEARCHED,
  SUPPLIER_REVIEW_SEARCH_RECEIVED,
  SUPPLIER_REVIEW_CREATED,
  SUPPLIER_REVIEW_READ,
  SUPPLIER_REVIEW_UPDATED,
  SUPPLIER_REVIEW_DELETED,
  SUPPLIER_REVIEW_RECEIVED,
  SUPPLIER_REVIEW_ERRORED,
} from '../constants/action-types';

const initialState = {
  item: null,
  list: [],
  numPages: 0,
  error: null,
  loading: false,
};

export default function supplierReviews(state = initialState, action) {
  switch (action.type) {
    case SUPPLIER_REVIEW_SEARCHED:
    case SUPPLIER_REVIEW_CREATED:
    case SUPPLIER_REVIEW_READ:
    case SUPPLIER_REVIEW_UPDATED:
    case SUPPLIER_REVIEW_DELETED:
      return { ...state, loading: true };

    case SUPPLIER_REVIEW_SEARCH_RECEIVED:
      return {
        ...state,
        loading: false,
        error: null,
        item: null,
        list: action.res.results,
        numPages: action.res.numPages,
      };

    case SUPPLIER_REVIEW_RECEIVED:
      return {
        ...state,
        loading: false,
        error: null,
        item: { ...(state.item || {}), ...action.review },
        list: [],
        numPages: 0,
      };

    case SUPPLIER_REVIEW_ERRORED:
      return {
        ...state,
        loading: false,
        error: action.err,
      };

    default:
      return state;
  }
}
