export const SHELL_SET_SETTINGS = 'SHELL_SET_SETTINGS';
export const AUTHENTICATION_LOGIN = 'AUTHENTICATION_LOGIN';
export const IDENTITY_CHANGED = 'IDENTITY_CHANGED';
export const TOGGLE_HEADER_DROPDOWN = 'TOGGLE_HEADER_DROPDOWN';
export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';
export const TOGGLE_VENDOR_MODAL = 'TOGGLE_VENDOR_MODAL';
export const SET_VENDOR_MODAL_DATA = 'SET_VENDOR_MODAL_DATA';
export const HEADER = 'HEADER';
export const FETCH_VENDORS = 'FETCH_VENDORS';
export const FETCH_VENDOR = 'FETCH_VENDOR';
export const UPDATE_VENDOR = 'UPDATE_VENDOR';
export const RESET_VENDOR = 'RESET_VENDOR';
export const SET_VENDOR_DATA = 'SET_VENDOR_DATA';
export const SET_USER = 'SET_USER';
export const FETCH_USER_BY_EMAIL = 'FETCH_USER_BY_EMAIL';
export const FETCH_VENDOR_OWNER = 'FETCH_VENDOR_OWNER';

export const FETCH_SUPPLIER = 'FETCH_SUPPLIER';
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';
export const FETCH_SUPPLIERS = 'FETCH_SUPPLIERS';
export const SET_SUPPLIER_DATA = 'SET_SUPPLIER_DATA';
export const RESET_SUPPLIER = 'RESET_SUPPLIER';

export const SET_EVENT_DATE = 'SET_EVENT_DATE';
export const SET_EVENT_POSTCODE = 'SET_EVENT_POSTCODE';
export const ADD_DIETARY_REQUIREMENT = 'ADD_DIETARY_REQUIREMENT';
export const REMOVE_DIETARY_REQUIREMENT = 'REMOVE_DIETARY_REQUIREMENT';
export const RESET_EVENT = 'RESET_EVENT';
export const SET_EVENT = 'SET_EVENT';
export const SENT_QUOTE_EMAIL = 'SENT_QUOTE_EMAIL';
export const SENT_ALL_QUOTES_EMAIL = 'SENT_ALL_QUOTES_EMAIL';
export const DELETE_QUOTE = 'DELETE_QUOTE';
export const UPDATE_QUOTE = 'UPDATE_QUOTE';
export const SET_QUOTE = 'SET_QUOTE';
export const FETCH_QUOTE = 'FETCH_QUOTE';
export const ADD_QUOTE = 'ADD_QUOTE';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER = 'FETCH_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';

export const FETCH_BOOKING = 'FETCH_BOOKING';
export const UPDATE_BOOKING = 'UPDATE_BOOKING';

export const FETCH_QUOTES = 'FETCH_QUOTES';

export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const FETCH_SETTING = 'FETCH_SETTING';

export const FETCH_ROOT_TAGS = 'FETCH_ROOT_TAGS';
export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_TAG = 'FETCH_TAG';
export const RECEIVED_TAGS = 'RECEIVED_TAGS';

export const SET_VENDOR_CALENDAR = 'SET_VENDOR_CALENDAR';
export const REMOVE_VENDOR_CALENDAR = 'REMOVE_VENDOR_CALENDAR';

export const FETCH_ENQUIRIES = 'FETCH_ENQUIRIES';
export const FETCH_ENQUIRY = 'FETCH_ENQUIRY';
export const UPDATE_ENQUIRY = 'UPDATE_ENQUIRY';
export const SET_ENQUIRY_DATA = 'SET_ENQUIRY_DATA';
export const RESET_USERS = 'RESET_USERS';

export const FETCH_SUPPLIER_CALENDAR = 'FETCH_SUPPLIER_CALENDAR';
export const CREATE_SUPPLIER_CALENDAR_ITEM = 'CREATE_SUPPLIER_CALENDAR_ITEM';
export const UPDATE_SUPPLIER_CALENDAR_ITEM = 'UPDATE_SUPPLIER_CALENDAR_ITEM';
export const DELETE_SUPPLIER_CALENDAR_ITEM = 'DELETE_SUPPLIER_CALENDAR_ITEM';

export const SUPPLIER_REVIEW_SEARCHED = 'supplierReviews/SEARCHED';
export const SUPPLIER_REVIEW_SEARCH_RECEIVED =
  'supplierReviews/SEARCH_RECEIVED';

export const SUPPLIER_REVIEW_CREATED = 'supplierReviews/CREATED';
export const SUPPLIER_REVIEW_READ = 'supplierReviews/READ';
export const SUPPLIER_REVIEW_UPDATED = 'supplierReviews/UPDATED';
export const SUPPLIER_REVIEW_DELETED = 'supplierReviews/DELETED';

export const SUPPLIER_REVIEW_RECEIVED = 'supplierReviews/RECEIVED';
export const SUPPLIER_REVIEW_ERRORED = 'supplierReviews/ERRORED';

export const FETCH_PARTNERSHIPS = 'FETCH_PARTNERSHIPS';

export const RUN_ROBEX = 'RUN_ROBEX';
export const FETCH_ROBEX_STARTED = 'FETCH_ROBEX_STARTED';
export const FETCH_ROBEX_COMPLETE = 'FETCH_ROBEX_COMPLETE';
export const FETCH_ROBEX_FAILED = 'FETCH_ROBEX_FAILED';

export const SEND_ALL_QUOTES_EMAIL = 'SEND_ALL_QUOTES_EMAIL';
export const SEND_ALL_QUOTES_EMAIL_STARTED = 'SEND_ALL_QUOTES_EMAIL_STARTED';
export const SEND_ALL_QUOTES_EMAIL_COMPLETE = 'SEND_ALL_QUOTES_EMAIL_COMPLETE';
export const SEND_ALL_QUOTES_EMAIL_FAILED = 'SEND_ALL_QUOTES_EMAIL_FAILED';

export const FETCH_PARTNERSHIP = 'FETCH_PARTNERSHIP';
export const UPDATE_PARTNERSHIP = 'UPDATE_PARTNERSHIP';
export const DELETE_PARTNERSHIP = 'DELETE_PARTNERSHIP';
export const CREATE_PARTNERSHIP = 'CREATE_PARTNERSHIP';

export const FETCH_EVENTS = 'FETCH_EVENTS';
export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';

export const FETCH_EVENT = 'FETCH_EVENT';
export const GET_EVENT = 'GET_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';

export const FETCH_REQUESTS = 'FETCH_REQUESTS';
export const GET_ALL_REQUESTS = 'GET_ALL_REQUESTS';

export const FETCH_REQUEST = 'FETCH_REQUEST';
export const GET_REQUEST = 'GET_REQUEST';
export const UPDATE_REQUEST = 'UPDATE_REQUEST';
export const CLOSE_REQUEST = 'CLOSE_REQUEST';
export const DELETE_REQUEST = 'DELETE_REQUEST';
