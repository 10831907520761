import React, { createContext, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  signUp,
  signIn as amplifySignIn,
  signOut as amplifySignOut,
  resetPassword as amplifyResetPassword,
  confirmResetPassword as amplifyConfirmPasswordReset,
  confirmSignUp,
  fetchAuthSession,
  signInWithRedirect,
} from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { Amplify } from 'aws-amplify';

import { identityService, properties } from '../../../../app-config';

import { client as apolloClient } from '../../../modules/apollo/apollo';
import { LINK_USER } from '../../../mutations';
import { setUser, fetchUser } from '../../../actions/user';
import { paths } from '../../../constants/paths';
import { COGNITO_IDENTITY_FIELDS } from '../../../constants/cognito-identity-fields';
import { isCognitoUserAdmin } from '../../../modules/cognito';

Amplify.configure({
  Auth: {
    Cognito: {
      loginWith: {
        email: true,
        oauth: {
          redirectSignIn: [paths.LOGIN],
          redirectSignOut: [paths.LOGOUT],
          scopes: ['email', 'profile', 'openid'],
          domain: properties.cognitoPoolDomain,
          responseType: 'code',
        },
      },
      userPoolId: properties.cognitoPoolId,
      userPoolClientId: properties.cognitoClientId,
    },
  },
});

const AuthContext = createContext({
  signIn: async () => null,
  signOut: async () => {},
  getSession: async () => null,
  register: async () => null,
  confirmRegistration: async () => null,
  resetPassword: async () => null,
  confirmPasswordReset: async () => {},
  user: null,
  googleSignIn: async () => {},
});

const isNewlyCreatedUser = (idToken) => {
  if (!idToken) {
    return false;
  }

  return Boolean(!idToken['custom:databaseId']);
};

const AuthProvider = ({ user, setUser, fetchUser, children }) => {
  useEffect(() => {
    const onSignIn = async () => {
      try {
        const session = await fetchAuthSession();

        const accessToken = session.tokens?.accessToken?.toString();
        const identityDetails = session.tokens?.idToken?.payload;

        if (!identityDetails) {
          const errorMessage = 'Missing identity details';
          console.error(errorMessage);
          return;
        }

        // Set user in local storage so correct headers used - previously logged in user
        if (isCognitoUserAdmin(accessToken)) {
          identityService.setIdentity({
            id: identityDetails[COGNITO_IDENTITY_FIELDS.DATABASE_ID],
            token: accessToken,
          });
        } else {
          await signOut();
          // override oath redirectSignOut in Cognito config to stay on same page
          window.location.href = `${paths.LOGIN}?isAdmin=false`;
        }

        if (isNewlyCreatedUser(identityDetails)) {
          const { given_name, family_name, email, phone_number } =
            identityDetails;

          const result = await apolloClient.mutate({
            mutation: LINK_USER,
            variables: {
              input: {
                email,
                firstName: given_name,
                lastName: family_name,
                type: 'admin',
                phone: phone_number ?? '',
                cognitoUsername:
                  identityDetails[COGNITO_IDENTITY_FIELDS.COGNITO_USERNAME],
              },
            },
          });

          identityService.setIdentity({
            id: result.data?.linkUser?.id,
            token: accessToken,
          });
        }

        // update user and set user in redux store
        await fetchUser(accessToken);
      } catch (error) {
        console.error(error);
        console.log('Not signed in');
        setUser(null);
      }
    };

    return Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signedIn':
          onSignIn();
          break;
        case 'signedOut':
          setUser(null);
          break;
      }
    });
  }, []);

  const signIn = async (username, password) =>
    amplifySignIn({ username, password });

  const signOut = async () => amplifySignOut();

  const getSession = async () => fetchAuthSession();

  const register = async (username, password) =>
    signUp({
      username,
      password,
      options: {
        autoSignIn: true,
        userAttributes: {
          email: username,
        },
      },
    });

  const confirmRegistration = async (username, confirmationCode) =>
    confirmSignUp({ username, confirmationCode });

  const resetPassword = async (username) => amplifyResetPassword({ username });

  const confirmPasswordReset = async (
    username,
    confirmationCode,
    newPassword
  ) => amplifyConfirmPasswordReset({ username, confirmationCode, newPassword });

  const googleSignIn = async () => signInWithRedirect({ provider: 'Google' });

  const value = {
    signIn,
    signOut,
    getSession,
    register,
    confirmRegistration,
    resetPassword,
    confirmPasswordReset,
    googleSignIn,
    user,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  fetchUser: (token, extraParams) => dispatch(fetchUser(token, extraParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider);

export const useAuth = () => useContext(AuthContext);
