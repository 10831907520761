import slugify from 'slugify';
import compose from 'just-compose';

export const formatCurrency = (num) =>
  num ? num.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) : 0;

export const generateSlug = (name) =>
  slugify(name, {
    lower: true,
    remove: /[&,+()$~%.'":*?<>{}@!]/g,
  });

export const replaceString = (string, target, replacement) => {
  if (
    typeof string !== 'string' ||
    typeof target !== 'string' ||
    typeof replacement !== 'string'
  ) {
    return string;
  }
  const regex = new RegExp(target, 'g');
  return string.replace(regex, replacement);
};

export const replaceLinebreaksWithSpaces = compose(
  (string) => replaceString(string, '</p>', '</p> '),
  (string) => replaceString(string, '</li>', ' </li>')
);
