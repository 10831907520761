const isPlainObject = (object) =>
  Object.prototype.toString.call(object) === '[object Object]';

function normalizeEmptyStrings(object, replacer) {
  // if this is an object of some kind then iterate
  // its properties and return an object without empty strings
  if (isPlainObject(object)) {
    return Object.keys(object).reduce((copy, key) => {
      let value = object[key];

      if (value === '') {
        value = replacer;
      }

      copy[key] = normalizeEmptyStrings(value, replacer);
      return copy;
    }, {});
  }

  if (Array.isArray(object)) {
    return object.map((item) => normalizeEmptyStrings(item, replacer));
  }

  return object;
}

function withFilteredBody(body) {
  const options = this || {};

  const replacer = options && options.method === 'POST' ? undefined : '';

  body = JSON.stringify(normalizeEmptyStrings(body, replacer));

  return { ...options, body };
}

export default async function jsonBody(request, next) {
  if (!isPlainObject(request.body) && !Array.isArray(request.body)) {
    return await next(request);
  }

  request.headers = request.headers || {};

  // const filteredBodyRequest = withFilteredBody(request.body).bind(request);
  const filteredBodyRequest = withFilteredBody(request.body);
  delete request.body;
  const headers = { ...request.headers, 'Content-Type': 'application/json' };
  delete request.headers;

  return await next({ ...filteredBodyRequest, ...request, headers });
}
