import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  constructCostBreakdown,
  formatCurrency,
  toPounds,
  addRate,
} from '@feast-it/finance';

import { quoteShape } from '../../prop-types/quotes';
// Actions
import {
  fetchEnquiry,
  deleteEnquiry,
  sendQuoteEmail,
} from '../../actions/enquiries';
import { deleteQuote } from '../../actions/quotes';
import { setUser } from '../../actions/user';
import { getSupplier } from '../../actions/suppliers';
import { getAllTags } from '../../actions/tags';

// Components
import BookingField from '../../components/booking-field';
import Button from '../../components/button';
import Spinner from '../../components/spinner';
import { SplitTreatments } from '@splitsoftware/splitio-react';

const conversationButtonFeatureName =
  'admin-request-quotes-message-conversation-link';

class Quote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vendor: null,
      vendorLoaded: false,
      error: null,
      sending: false,
    };
  }

  componentDidMount() {
    getSupplier(this.props.quote.supplier_id).then((vendor) => {
      this.setState({
        vendor,
        vendorLoaded: true,
      });
    });
  }

  handleSingleEmailClick = (data) => {
    this.setState({ error: null, sending: true });

    return this.props
      .sendQuoteEmail(data)
      .then(() => {
        this.setState({ sending: false });
      })
      .catch((error) =>
        this.setState({
          sending: false,
          error: `${error.message} ${error.info ? error.info : ''}`,
        })
      );
  };

  handleDeleteQuoteClick = (data) => {
    this.props.deleteQuote(data);
  };

  renderConversationButton = (treatmentWithConfig) => {
    const { treatment } = treatmentWithConfig;
    if (treatment === 'on')
      return (
        <div>
          <Button
            color="grey"
            text="See message conversation"
            onClick={() =>
              this.props.handleOnImpersonateUser(
                this.props.impersonateMutation,
                this.props.userId,
                `messages/${this.props.conversationId}`
              )
            }
          />
        </div>
      );
  };

  render() {
    const { quote, isRequest, requestId, conversationId, userId } = this.props;
    const { vendor, vendorLoaded, error, sending } = this.state;
    const {
      total,
      commission_fee,
      covers,
      state,
      internalNote,
      emailSent,
      published,
    } = quote;
    const basePrice = toPounds(total);
    const priceWithCommission = addRate(basePrice, commission_fee);
    const { customerTotal } = constructCostBreakdown(quote);
    const menuPrice = formatCurrency(priceWithCommission / covers);

    if (!vendorLoaded) {
      return <Spinner />;
    }

    return (
      <div className="quote">
        <div className="quote__top">
          <div className="quote__field-vendor-name">
            <BookingField label="Supplier Name" value={vendor.name} />
          </div>
        </div>

        <div className="quote__bottom">
          <div className="quote__field">
            <BookingField label="Covers" value={covers} />
          </div>

          <div className="quote__field">
            <BookingField
              label="Customer's Price"
              value={`£${formatCurrency(toPounds(customerTotal))}`}
            />
          </div>

          <div className="quote__field">
            <BookingField label="menuPrice" value={`£${menuPrice}`} />
          </div>

          <div
            className={classNames('quote__field', {
              'quote__field--green': internalNote,
              'quote__field--black': !internalNote,
            })}
          >
            <BookingField
              label="Note to Togather"
              value={internalNote ? 'Yes' : 'No'}
            />
          </div>

          <div
            className={classNames('quote__field', {
              'quote__field--green': published,
              'quote__field--red': !published,
            })}
          >
            <BookingField
              label="Published"
              value={published ? 'True' : 'False'}
            />
          </div>

          <div
            className={classNames('quote__field', {
              'quote__field--green': state === 'booked',
              'quote__field--black': state === 'pending',
              'quote__field--red': state === 'declined',
            })}
          >
            <BookingField
              className="quote__field"
              label="State"
              value={state}
            />
          </div>

          <div>
            <div>
              <div>
                <Link to={`/admin/quotes/${quote.uuid}`}>
                  <Button
                    className="quote__button--edit"
                    text="See Quote"
                    color="blue"
                  />
                </Link>
              </div>

              <div>
                <Link to={`/admin/requests/${requestId}/${quote.uuid}`}>
                  <Button
                    className="quote__button--edit"
                    text="Edit Quote"
                    color="green"
                  />
                </Link>
              </div>

              {userId && conversationId && (
                <SplitTreatments names={[conversationButtonFeatureName]}>
                  {({ treatments, isReady }) => {
                    // Passes down a TreatmentsWithConfig object and SplitContext properties like the boolean `isReady` flag.
                    return isReady ? (
                      this.renderConversationButton(
                        treatments[conversationButtonFeatureName]
                      ) // Use the treatments and configs.
                    ) : (
                      <Spinner />
                    ); // Render a spinner if the SDK is not ready yet. You can do what you want with this boolean.
                  }}
                </SplitTreatments>
              )}
            </div>
          </div>

          {state === 'pending' && (
            <div>
              <div>
                <Button
                  text="Decline - Not Suitable"
                  color="red"
                  disabled={this.props.declineQuoteInProgress}
                  onClick={() =>
                    this.props.handleDeclineQuote(quote.uuid, 'NOT_SUITABLE')
                  }
                />
              </div>

              <div>
                <Button
                  text="Decline - Booked Another Supplier"
                  color="red"
                  disabled={this.props.declineQuoteInProgress}
                  onClick={() =>
                    this.props.handleDeclineQuote(
                      quote.uuid,
                      'BOOKED_ANOTHER_SUPPLIER'
                    )
                  }
                />
              </div>
            </div>
          )}

          {!isRequest && (
            <Button
              className="quote__button"
              disabled={emailSent || sending}
              color={emailSent ? 'grey' : 'blue'}
              text={
                emailSent ? 'Notification Sent' : 'Send New Quote Notification'
              }
              onClick={() => this.handleSingleEmailClick({ id: quote.uuid })}
              isLoading={sending}
            />
          )}

          {error && <span className="field__error">{error}</span>}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchEnquiry,
      deleteEnquiry,
      sendQuoteEmail,
      deleteQuote,
      setUser,
      getAllTags,
    },
    dispatch
  );

Quote.propTypes = {
  quote: quoteShape.isRequired,
  sendQuoteEmail: PropTypes.func.isRequired,
  deleteQuote: PropTypes.func.isRequired,
  fetchEnquiry: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  getAllTags: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(Quote);
