import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router';
import classNames from 'classnames';
import { useMutation, useQuery } from '@apollo/client';

import { FormikCheckbox } from '../../components/form-checkbox';
import { FormikMultiSelect } from '../../components/multi-select';
import { httpService } from '../../../app-config';
import { REQUEST_BY_ID } from '../../queries/requestById';
import { captureError } from '../../modules/monitoring';
import {
  ADDITIONAL_INFO_SUPPLIER_SETUP_OPTIONS,
  ADDITIONAL_INFO_BAR_TYPE,
  ADDITIONAL_INFO_DECISIONS,
  ADDITIONAL_INFO_COURSES,
} from '../../constants/additional-info';
import { EDIT_EVENT_REQUEST } from '../../mutations/edit-event-request';

const RequestEditForm = ({ params }) => {
  const [tags, setTags] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [tier2Verticals, setTier2Verticals] = useState([]);
  const [editEventRequestMutation] = useMutation(EDIT_EVENT_REQUEST);

  const { data: requestQueryData, refetch: requestQueryRefetch } = useQuery(
    REQUEST_BY_ID,
    {
      variables: {
        request_id: params.requestId,
      },
      skip: !params.requestId,
      onError: (error) => captureError(error),
    }
  );

  const eventRequest = requestQueryData?.result?.[0];

  useEffect(() => {
    let isCancelled = false;

    const fetchTags = async () => {
      const [tier1Tags, tier2Tags] = await Promise.all([
        await httpService.request({
          path: `/tags/${eventRequest?.categories_tier2?.parent}/vertical`,
        }),
        await httpService.request({
          path: `/tags/${eventRequest?.categories_tier2?.sys_name}/groups`,
        }),
      ]);

      const data = [
        ...tier1Tags?.map((tier1Tag) => ({
          value: tier1Tag.uuid,
          label: tier1Tag.name,
        })),
        ...tier2Tags?.reduce((allTier2Tags, tier2TagGroup) => {
          const groupTags = tier2TagGroup.tags.map((tier2Tag) => ({
            value: tier2Tag.uuid,
            label: tier2Tag.name,
          }));
          return [...allTier2Tags, ...groupTags];
        }, []),
      ];

      if (data) {
        setTags(data);
      }
    };

    if (eventRequest?.categories_tier2?.parent && !isCancelled) {
      fetchTags();
    }

    return () => {
      isCancelled = true;
    };
  }, [eventRequest?.categories_tier2]);

  useEffect(() => {
    const categoryTier1 = eventRequest?.categories_tier2?.parent;
    const load = async () => {
      const tier2 = await httpService.request({
        path: `/verticals/tier2/${categoryTier1}`,
      });
      setTier2Verticals(tier2);
    };

    if (categoryTier1) {
      load();
    }
  }, [eventRequest?.categories_tier2]);

  const handleSubmit = async (values) => {
    try {
      const data = {
        budget: parseInt(values.budget),
        budget_flexible: values.budget_flexible,
        completed: values.completed,
        description: values.description,
        item_quantity: parseInt(values.item_quantity),
        category_tier2: values.category_tier2.value,
        accepting_quotes: values.accepting_quotes,
        admin_note: values.admin_note,
        tag_uuids: values.tags.map((tag) => tag.value),
        additionalInfo: {
          children: values.additional_info.children.value,
          courses: values.additional_info.courses?.map(
            (course) => course.value
          ),
          crockery: values.additional_info.crockery.value,
          power: values.additional_info.power.value,
          supplier_setup: values.additional_info.supplier_setup,
          waiters: values.additional_info.waiters.value,
          water: values.additional_info.water.value,
          bar_type: values.additional_info.bar_type.value,
        },
      };

      await editEventRequestMutation({
        variables: {
          requestId: eventRequest.uuid,
          data,
        },
      });

      await requestQueryRefetch();

      setHasSubmitted(true);
      setTimeout(() => setHasSubmitted(false), 3000);
    } catch (err) {
      console.error(err);
    }
  };

  const initialValues = useMemo(
    () => ({
      budget: eventRequest?.budget ?? 0,
      budget_flexible: eventRequest?.budget_flexible ?? false,
      description: eventRequest?.description ?? '',
      tags:
        eventRequest?.event_request_tags?.map((tag) => ({
          value: tag.tags_event_request_tags_tag_uuidTotags.uuid,
          label: tag.tags_event_request_tags_tag_uuidTotags.name,
        })) ?? null,
      accepting_quotes: Boolean(eventRequest?.accepting_quotes) ?? false,
      admin_note: eventRequest?.admin_note ?? '',
      completed: Boolean(eventRequest?.completed),
      category_tier2: {
        label: eventRequest?.categories_tier2?.name,
        value: eventRequest?.categories_tier2?.sys_name,
      },
      item_quantity: eventRequest?.item_quantity ?? 0,
      additional_info: {
        children: {
          label:
            ADDITIONAL_INFO_DECISIONS[eventRequest?.additional_info?.children],
          value: eventRequest?.additional_info?.children,
        },
        courses:
          eventRequest?.additional_info?.courses?.map((course) => ({
            value: course,
            label: ADDITIONAL_INFO_COURSES[course],
          })) ?? null,
        crockery: {
          label:
            ADDITIONAL_INFO_DECISIONS[eventRequest?.additional_info?.crockery],
          value: eventRequest?.additional_info?.crockery,
        },
        power: {
          label:
            ADDITIONAL_INFO_DECISIONS[eventRequest?.additional_info?.power],
          value: eventRequest?.additional_info?.power,
        },
        supplier_setup: eventRequest?.additional_info?.supplier_setup,
        waiters: {
          label:
            ADDITIONAL_INFO_DECISIONS[eventRequest?.additional_info?.waiters],
          value: eventRequest?.additional_info?.waiters,
        },
        water: {
          label:
            ADDITIONAL_INFO_DECISIONS[eventRequest?.additional_info?.water],
          value: eventRequest?.additional_info?.water,
        },
        bar_type: {
          label:
            ADDITIONAL_INFO_BAR_TYPE[eventRequest?.additional_info?.bar_type],
          value: eventRequest?.additional_info?.bar_type,
        },
      },
    }),
    [eventRequest]
  );

  const isBarVertical =
    eventRequest?.categories_tier2?.sys_name === ('mobile_bar' || 'event_bar');
  const isCaterer = eventRequest?.categories_tier2?.sys_name === 'caterers';
  const hasSupplierSetup =
    eventRequest?.categories_tier2?.sys_name ===
    ('street_food' ||
      'private_chef' ||
      'caterers' ||
      'mobile_bar' ||
      'event_bar');

  return (
    <div className="container">
      <h1 style={{ margin: '0 0 16px 0' }}>Edit request</h1>

      <Link to={`/admin/requests/${params.requestId}`}>
        <button
          style={{ margin: '0 0 16px 0' }}
          type="button"
          className="button button--grey-light"
        >
          &laquo; Back to request
        </button>
      </Link>

      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ dirty }) => (
          <Form>
            <div className="row">
              <div className="col-md-6">
                <div className="field">
                  <Field
                    as={FormikCheckbox}
                    label="Accepting quotes"
                    className="form-control"
                    name="accepting_quotes"
                  />
                </div>

                <div className="field">
                  <Field
                    as={FormikCheckbox}
                    label="Completed"
                    className="form-control"
                    name="completed"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="field">
                  <label className="field__label form-control-label">
                    Budget
                  </label>

                  <Field className="form-control" name="budget" />

                  <Field
                    as={FormikCheckbox}
                    label="Budget Flexible"
                    className="form-control"
                    name="budget_flexible"
                  />
                </div>

                <div className="field">
                  <label className="field__label form-control-label">
                    Description
                  </label>

                  <Field
                    name="description"
                    as="textarea"
                    className="form-control"
                  />
                </div>

                <div className="field">
                  <label className="field__label form-control-label">
                    No. of guests
                  </label>

                  <Field className="form-control" name="item_quantity" />
                </div>
              </div>

              <div className="col-md-6">
                <Field
                  name="tags"
                  label="Tags"
                  as={FormikMultiSelect}
                  options={tags}
                />

                <div className="field">
                  <label className="field__label form-control-label">
                    Tier 2 Vertical
                  </label>

                  <Field
                    name="category_tier2"
                    as={FormikMultiSelect}
                    multi={false}
                    options={tier2Verticals.map((vertical) => ({
                      label: vertical.name,
                      value: vertical.sysName,
                    }))}
                  />
                </div>

                <div className="field">
                  <label className="field__label form-control-label">
                    Note from Togather
                  </label>

                  <Field
                    name="admin_note"
                    as="textarea"
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            {eventRequest?.additional_info && (
              <div className="row">
                <h6 style={{ margin: '0 0 16px 16px' }}>Additional Info</h6>
                {isCaterer && (
                  <>
                    <div className="col-md-6">
                      <div className="field">
                        <label className="field__label form-control-label">
                          Children
                        </label>

                        <Field
                          name="additional_info.children"
                          as={FormikMultiSelect}
                          multi={false}
                          options={[
                            { label: 'Yes', value: 'yes' },
                            { label: 'No', value: 'no' },
                            { label: 'Not Sure', value: 'not_sure' },
                          ]}
                        />
                      </div>

                      <div className="field">
                        <label className="field__label form-control-label">
                          Courses
                        </label>

                        <Field
                          name="additional_info.courses"
                          as={FormikMultiSelect}
                          multi
                          options={[
                            { label: 'Canapes', value: 'canapes' },
                            { label: 'Starters', value: 'starters' },
                            { label: 'Mains', value: 'mains' },
                            { label: 'Desserts', value: 'desserts' },
                          ]}
                        />
                      </div>

                      <div className="field">
                        <label className="field__label form-control-label">
                          Crockery
                        </label>

                        <Field
                          name="additional_info.crockery"
                          as={FormikMultiSelect}
                          multi={false}
                          options={[
                            { label: 'Yes', value: 'yes' },
                            { label: 'No', value: 'no' },
                            { label: 'Not Sure', value: 'not_sure' },
                          ]}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <label className="field__label form-control-label">
                          Power
                        </label>

                        <Field
                          name="additional_info.power"
                          as={FormikMultiSelect}
                          multi={false}
                          options={[
                            { label: 'Yes', value: 'yes' },
                            { label: 'No', value: 'no' },
                            { label: 'Not Sure', value: 'not_sure' },
                          ]}
                        />
                      </div>

                      <div className="field">
                        <label className="field__label form-control-label">
                          Waiters
                        </label>

                        <Field
                          name="additional_info.waiters"
                          as={FormikMultiSelect}
                          multi={false}
                          options={[
                            { label: 'Yes', value: 'yes' },
                            { label: 'No', value: 'no' },
                            { label: 'Not Sure', value: 'not_sure' },
                          ]}
                        />
                      </div>

                      <div className="field">
                        <label className="field__label form-control-label">
                          Water
                        </label>

                        <Field
                          name="additional_info.water"
                          as={FormikMultiSelect}
                          multi={false}
                          options={[
                            { label: 'Yes', value: 'yes' },
                            { label: 'No', value: 'no' },
                            { label: 'Not Sure', value: 'not_sure' },
                          ]}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="col-md-6">
                  {hasSupplierSetup && (
                    <div className="field">
                      <label className="field__label form-control-label">
                        Supplier Setup
                      </label>

                      <Field
                        name="additional_info.supplier_setup"
                        as={FormikMultiSelect}
                        multi={false}
                        options={
                          ADDITIONAL_INFO_SUPPLIER_SETUP_OPTIONS[
                            eventRequest?.categories_tier2?.sys_name
                          ]
                        }
                      />
                    </div>
                  )}

                  {isBarVertical && (
                    <div className="field">
                      <label className="field__label form-control-label">
                        Bar type
                      </label>

                      <Field
                        name="additional_info.bar_type"
                        as={FormikMultiSelect}
                        multi={false}
                        options={[
                          { label: 'Cash', value: 'cash' },
                          { label: 'Open', value: 'open' },
                          { label: 'Mixed', value: 'mixed' },
                          { label: 'Not Sure', value: 'not_sure' },
                        ]}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-6">
                <button
                  disabled={!dirty}
                  type="submit"
                  className={classNames('button', {
                    'button--grey': !dirty,
                    'button--blue': dirty,
                  })}
                >
                  Save
                </button>

                {hasSubmitted && (
                  <span style={{ marginLeft: '8px' }} className="text-success">
                    Saved Successfully
                  </span>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ requestDetails }) => ({
  request: requestDetails,
});

export default connect(mapStateToProps)(RequestEditForm);
