import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';

import {
  fetchPartnership,
  updatePartnership,
  deletePartnership,
  createPartnership,
} from '../../actions/partnerships';

import PartnershipForm from '../forms/partnership-form';
import { useMutation } from '@apollo/client';
import { DELETE_PARTNERSHIP } from '../../mutations';

const Partnership = ({
  router,
  params: { partnershipId },
  partnership,
  onUpdatePartnership,
  onFetchPartnership,
  onDeletePartnership,
  onCreatePartnership,
}) => {
  const isNew = partnershipId === 'new';
  const [deletePartnershipMutation] = useMutation(DELETE_PARTNERSHIP);

  useEffect(() => {
    const getPartnership = async (id) => {
      await onFetchPartnership(id);
    };
    if (partnershipId && !isNew) {
      getPartnership(partnershipId);
    }
  }, [isNew, onFetchPartnership, partnershipId]);

  const onSave = useCallback(
    (data) => {
      if (isNew) {
        onCreatePartnership(data);
      } else {
        onUpdatePartnership(data);
      }
    },
    [isNew, onCreatePartnership, onUpdatePartnership]
  );

  const onDelete = useCallback(async () => {
    const confirmDeletion = window.confirm(
      'Are you sure you want to delete this partnership?'
    );
    if (confirmDeletion) {
      await deletePartnershipMutation({ variables: { uuid: partnershipId } });
      router.replace('/admin/partnerships');
    }
  }, [onDeletePartnership, partnershipId, router]);

  return (
    <main className="view view--partner">
      <div className="container page-title clearfix">
        <h1 className="pull-left">Edit partnership</h1>
      </div>
      <div className="container">
        <div className="meta">
          <div className="container">
            <div className="clearfix">
              <Link className="pull-left" to="/admin/partnerships">
                <button type="button" className="button button--grey-light">
                  &laquo; Back to Partnerships
                </button>
              </Link>
            </div>
          </div>
        </div>
        <PartnershipForm
          isNew={isNew}
          data={partnership}
          onSave={onSave}
          onDelete={onDelete}
        />
      </div>
    </main>
  );
};

Partnership.propTypes = {
  params: PropTypes.shape({
    partnershipId: PropTypes.string.isRequired,
  }).isRequired,
  router: PropTypes.shape({
    replace: PropTypes.string,
  }).isRequired,
  onFetchPartnership: PropTypes.func.isRequired,
  onUpdatePartnership: PropTypes.func.isRequired,
  onDeletePartnership: PropTypes.func.isRequired,
  onCreatePartnership: PropTypes.func.isRequired,
  partnership: PropTypes.shape({}).isRequired,
};

const matchDispatchToProps = {
  onFetchPartnership: fetchPartnership,
  onUpdatePartnership: updatePartnership,
  onDeletePartnership: deletePartnership,
  onCreatePartnership: createPartnership,
};

const mapStateToProps = ({ partnership }) => {
  return { partnership };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(Partnership));
