import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router';
import classNames from 'classnames';

import { useMutation, useQuery } from '@apollo/client';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import { FormDatePicker } from '../../components/date-picker';
import { getChangedValues } from '../../libs/form-validation-utils';
import { FormikMultiSelect } from '../../components/multi-select';
import { fetchTagsByType } from '../../actions/tags';
import { EVENT_TYPE_TAGS_PID } from '../../constants/tags';
import { EDIT_EVENT_MUTATION } from '../../mutations/edit-event';
import { EVENT_QUERY } from '../../queries/event';
import { format } from 'date-fns';
import { captureError } from '../../modules/monitoring';

const EventEditForm = ({ params, onFetchTags, childrenTags }) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [eventTags, setEventTags] = useState([]);
  const [editEventMutation, { error }] = useMutation(EDIT_EVENT_MUTATION);
  const [hasError, setHasError] = useState(false);

  const { data: eventData } = useQuery(EVENT_QUERY, {
    variables: {
      eventId: params.eventId,
    },
    skip: !params.eventId,
    onError: (error) => captureError(error),
  });
  const { result: eventResult } = eventData ?? {};
  const [event] = eventResult ?? [];

  const quotes = event?.event_requests.map((request) => request.quotes).flat();
  const hasBookedRequests = quotes?.some((quote) => quote.state === 'booked');

  const eventTypesTagPid = EVENT_TYPE_TAGS_PID;
  const urgencyOptions = [
    { label: 'ASAP' },
    { label: 'Just started looking' },
    { label: 'Within a few weeks' },
    { label: 'Within a week' },
    { label: 'Within two months' },
  ];

  useEffect(() => {
    onFetchTags('event');
  }, [params.eventId, onFetchTags]);

  useEffect(() => {
    if (childrenTags && childrenTags.length) {
      const eventTags = childrenTags
        .filter((tag) => tag.pid === eventTypesTagPid)
        .map(({ name }) => ({ label: name }));
      setEventTags(eventTags);
    }
  }, [childrenTags, eventTypesTagPid]);

  const handleSubmit = async (values) => {
    const {
      type: { label: eventTypeLabel },
      urgency: { label: urgencyLabel },
    } = values;

    const changedValues = getChangedValues(
      {
        ...values,
        type: eventTypeLabel,
        urgency: urgencyLabel,
      },
      initialValues
    );

    const isSubmissionConfirmed =
      changedValues.date && hasBookedRequests
        ? window.confirm(
            'You are about to change the date of event with at least one booked request, are you sure you want to proceed?'
          )
        : true;

    try {
      if (isSubmissionConfirmed) {
        await editEventMutation({
          variables: {
            eventUuid: event.uuid,
            data: {
              ...values,
              type: eventTypeLabel,
              urgency: urgencyLabel,
            },
          },
        });

        setHasSubmitted(true);
        setTimeout(() => setHasSubmitted(false), 3000);
      }
    } catch (error) {
      setHasError(true);
    }
  };

  const initialValues = useMemo(() => {
    return omitBy(
      {
        date: event?.date ? format(new Date(event?.date), 'yyyy-MM-dd') : null,
        start_time: event?.start_time ?? null,
        end_time: event?.end_time ?? null,
        location_line_1: event?.location_line_1 ?? null,
        location_line_2: event?.location_line_2 ?? null,
        location_city: event?.location_city ?? null,
        postcode: event?.postcode ?? null,
        custom_event_name: event?.custom_event_name ?? null,
        urgency: { label: event?.urgency ?? null },
        type: { label: event?.type },
      },
      isNil
    );
  }, [event, event?.date]);

  return (
    <div className="container">
      <h1 style={{ margin: '0 0 16px 0' }}>Edit event</h1>
      <Link to={`/admin/events/${params.eventId}`}>
        <button
          style={{ margin: '0 0 16px 0' }}
          type="button"
          className="button button--grey-light"
        >
          &laquo; Back to event
        </button>
      </Link>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ dirty }) => (
          <Form>
            <div className="row">
              <div className="col-md-6">
                <div className="field">
                  <label className="field__label form-control-label">
                    Event name
                  </label>
                  <Field className="form-control" name="custom_event_name" />
                </div>

                <div className="field">
                  <label className="field__label form-control-label">
                    Location line 1
                  </label>
                  <Field className="form-control" name="location_line_1" />
                </div>

                <div className="field">
                  <label className="field__label form-control-label">
                    Location line 2
                  </label>
                  <Field className="form-control" name="location_line_2" />
                </div>

                <div className="field">
                  <label className="field__label form-control-label">
                    Location city
                  </label>
                  <Field className="form-control" name="location_city" />
                </div>

                <div className="field">
                  <label className="field__label form-control-label">
                    Postcode
                  </label>
                  <Field className="form-control" name="postcode" />
                </div>
              </div>

              <div className="col-md-6">
                <div className="field">
                  <label className="field__label form-control-label">
                    Event type
                  </label>
                  <Field
                    name="type"
                    as={FormikMultiSelect}
                    multi={false}
                    options={eventTags}
                  />
                </div>

                <div className="field">
                  <label
                    className="field__label form-control-label"
                    htmlFor="date"
                  >
                    Date
                  </label>
                  <Field
                    name="date"
                    label="Date of event"
                    as={FormDatePicker}
                    className="form-control"
                    labelClassName="form-control-label"
                    dateFormat="yyyy-MM-dd"
                  />
                </div>

                <div className="field">
                  <label className="field__label form-control-label">
                    Start time
                  </label>
                  <Field
                    type="time"
                    name="start_time"
                    className="form-control"
                  />
                </div>

                <div className="field">
                  <label className="field__label form-control-label">
                    End time
                  </label>
                  <Field type="time" name="end_time" className="form-control" />
                </div>

                <div className="field">
                  <label className="field__label form-control-label">
                    Urgency
                  </label>
                  <Field
                    name="urgency"
                    as={FormikMultiSelect}
                    multi={false}
                    options={urgencyOptions}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <button
                  disabled={!dirty}
                  type="submit"
                  className={classNames('button', {
                    'button--grey': !dirty,
                    'button--blue': dirty,
                  })}
                >
                  Save
                </button>

                {hasSubmitted && (
                  <span style={{ marginLeft: '8px' }} className="text-success">
                    Saved Successfully
                  </span>
                )}
                {hasError && (
                  <span style={{ marginLeft: '8px' }} className="text-danger">
                    {error.message}
                  </span>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapDispatchToProps = {
  onFetchTags: fetchTagsByType,
  onFetchRequest: fetch,
};

const mapStateToProps = ({ childrenTags }) => ({
  childrenTags,
});

export default connect(mapStateToProps, mapDispatchToProps)(EventEditForm);
