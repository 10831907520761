import React from 'react';
import classNames from 'classnames';
import { formatCurrency } from '../libs/formatting';

const BasketItem = ({
  quantity,
  id,
  priceModifier,
  name,
  onAdd,
  onRemove,
  minValue,
  maxValue,
}) => (
  <li className="basket-item">
    <div className="basket-item__name">
      {name} ({formatCurrency(priceModifier)}pp)
    </div>
    <div className="basket-item__buttons">
      <button
        className={classNames('basket-item__button', {
          'basket-item__button--disabled': quantity <= minValue,
        })}
        type="button"
        onClick={quantity <= minValue ? null : () => onRemove(id)}
      >
        -
      </button>
      {quantity}
      <button
        className={classNames('basket-item__button', {
          'basket-item__button--disabled': quantity >= maxValue,
        })}
        type="button"
        onClick={
          quantity >= maxValue
            ? null
            : () =>
                onAdd({
                  id,
                  name,
                  priceModifier,
                  quantity: quantity + 1,
                })
        }
      >
        +
      </button>
    </div>
  </li>
);

export default BasketItem;
