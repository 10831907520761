import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import toggleHeaderDropdown from '../actions/header-dropdown';
import { setUser } from '../actions/user';

class Dropdown extends Component {
  handleClickOutside() {
    if (this.props.headerDropdownIsVisible) {
      this.props.dispatch(toggleHeaderDropdown(false));
    }
  }

  handleLogout = () => {
    this.props.dispatch(setUser(null));
    this.props.router.replace('/login');
  };

  render() {
    return (
      <nav
        className={classNames('dropdown', {
          'dropdown--hidden': !this.props.headerDropdownIsVisible,
        })}
      >
        <button
          className="u-unstyled-btn dropdown__item"
          type="button"
          onClick={this.handleLogout}
        >
          Sign Out
        </button>
      </nav>
    );
  }
}

function mapStateToProps({ headerDropdownIsVisible }) {
  return { headerDropdownIsVisible };
}

export default withRouter(connect(mapStateToProps)(onClickOutside(Dropdown)));
