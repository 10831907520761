import { Field } from 'redux-form';

import FormCheckbox from '../../components/form-checkbox';
import { SUPPLIER_PERMISSION_TYPES } from '../../constants/supplier';

export function SupplierPermissions() {
  const customerPhoneAllowed =
    SUPPLIER_PERMISSION_TYPES.CUSTOMER_PHONE_NUMBER_ALLOWED;

  return (
    <div className="supplier-recommendations">
      <label>Permissions</label>

      <Field
        name={`permissions.${customerPhoneAllowed}.enabled`}
        className="form-control"
        component={FormCheckbox}
        label="Customer phone number viewable"
        labelClassName="form-control-label"
      />
    </div>
  );
}
