import {
  FETCH_ROOT_TAGS,
  FETCH_TAGS,
  FETCH_TAG,
  RECEIVED_TAGS,
} from '../constants/action-types';
import { httpService } from '../../app-config';

export function fetchRootTags() {
  return (dispatch) =>
    httpService
      .request({ path: `/admin/tags` })
      .then((response) => response)
      .then((payload) => dispatch({ type: FETCH_ROOT_TAGS, payload }));
}

export function fetchChildrenTags(pid = 'all') {
  return (dispatch) =>
    httpService
      .request({ path: `/admin/tags/${pid}/tags` })
      .then((response) => response)
      .then((payload) => dispatch({ type: FETCH_TAGS, payload }));
}

export function fetchTagsByType(type) {
  return (dispatch) =>
    httpService
      .request({ path: `/tags/${type}/type` })
      .then((response) => response)
      .then((payload) => dispatch({ type: FETCH_TAGS, payload }));
}

export function createTag(tag) {
  return (dispatch) =>
    httpService
      .request({
        path: `/admin/tags`,
        method: 'POST',
        body: tag,
      })
      .then((response) => response)
      .then((payload) => dispatch({ type: FETCH_TAG, payload }));
}

export function updateTag(tag) {
  return (dispatch) =>
    httpService
      .request({
        path: `/admin/tags/${tag.uuid}`,
        method: 'PATCH',
        body: { name: tag.name },
      })
      .then((response) => response)
      .then((payload) => dispatch({ type: FETCH_TAG, payload }));
}

export function removeTag(tagId) {
  return (dispatch) =>
    httpService
      .request({ path: `/admin/tags/${tagId}`, method: 'DELETE' })
      .then((response) => response)
      .then((payload) => dispatch({ type: FETCH_TAG, payload }));
}

export const getAllTags = () => (dispatch) => {
  return httpService
    .request({
      path: '/tags/all/tags/',
    })
    .then((tags) => dispatch({ type: RECEIVED_TAGS, payload: tags }));
};
