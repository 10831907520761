import HttpService from './js/services/http-service';
import BrowserStorageIdentityService from './js/services/browser-storage-identity-service';

import jsonPayload from './js/httpMiddleware/json-payload';
import createAuthorization from './js/httpMiddleware/authorization';

export const properties = {
  baseApiUri: process.env.REACT_APP_API_BASE_URI, // eslint-disable-line no-undef,
  PARSE_AND_SHOW_SERVER_ERRORS: true,
  deployedEnvironment: process.env.REACT_APP_DEPLOYED_ENV,
  datadogApplicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  datadogClientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  splitSdkKey: process.env.REACT_APP_SPLIT_SDK_KEY,
  cognitoPoolId: process.env.REACT_APP_USER_POOL_ID,
  cognitoPoolDomain: process.env.REACT_APP_USER_POOL_DOMAIN,
  cognitoClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
};

export const identityService = new BrowserStorageIdentityService();

const httpMiddleware = [
  jsonPayload,
  createAuthorization({
    identityService,
  }),
];

export const httpService = new HttpService(
  properties.baseApiUri,
  httpMiddleware
);
