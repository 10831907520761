import { paths } from '../constants/paths';
import { getStoredIdentity } from '../services/browser-storage-identity-service';
import { getLatestCognitoToken } from './cognito';

export const handleOnImpersonateUser = async (
  impersonateMutation,
  userId,
  redirect = ''
) => {
  try {
    const { data: impersonateUserMutationResponse } = await impersonateMutation(
      {
        variables: {
          userId,
        },
      }
    );

    const {
      impersonateUser: { token: authorizationToken },
    } = impersonateUserMutationResponse;

    window.open(paths.IMPERSONATE_USER(authorizationToken, redirect));
  } catch (error) {
    console.error(error);
  }
};

export const handleOnImpersonateUserV2 = async ({ userId, userType }) => {
  try {
    await getLatestCognitoToken();
    const { token } = getStoredIdentity() ?? {};
    const redirect =
      userType === 'supplier' ? '/suppliers/dashboard' : '/dashboard';

    window.open(paths.IMPERSONATE_USER_V2(token, userId, redirect), '_blank');
  } catch (error) {
    console.error(error);
  }
};
