import _ from 'lodash';
import zenscroll from 'zenscroll';

export function keyValidator(validationSet) {
  const fields = Object.keys(validationSet);
  return async (form, dispatch, props) => {
    const errors = {};
    for (const field of fields) {
      const validator = validationSet[field];
      const value = form[field];
      const isSubKeyvalidator = typeof validator !== 'function';

      if (isSubKeyvalidator) {
        errors[field] = await keyValidator(validator)(value, dispatch, props);
      } else {
        let errorMessage;

        try {
          errorMessage = await validator(form, dispatch, props);
        } catch (err) {
          errorMessage =
            err.message || 'An error occurred during validation of this field';
        }

        if (errorMessage != null) {
          errors[field] = errorMessage;
        }
      }
    }
    return errors;
  };
}

export const isProbablyValidEmailAddress = (email) =>
  email && /(.+)@(.+){2,}\.(.+){2,}/.test(email);

export const isValidSubdomain = (subdomain) =>
  subdomain && /^[a-z0-9-]+$/.test(subdomain);

export const getSubdomainFromName = (name) =>
  name &&
  name
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

export function getErrorFieldNames(obj, name = '') {
  // eslint-disable-next-line array-callback-return
  const errorArr = Object.keys(obj).reduce((acc, key) => {
    if (Array.isArray(obj[key])) {
      return [
        ...acc,
        obj[key].map((x, i) => getErrorFieldNames(x, `${key}[${i}].`)),
      ];
    }

    if (typeof obj[key] === 'string') {
      return [...acc, `${name}${key}`];
    }

    if (typeof obj[key] === 'object') {
      return [...acc, ...getErrorFieldNames(obj[key], `${key}.`)];
    }
  }, []);

  return _.flatten(errorArr);
}

export function scrollToFirstError(errors) {
  if (!errors) {
    return;
  }

  const errorFields = getErrorFieldNames(errors);

  const el = document.querySelectorAll(`[name="${errorFields[0]}"]`)[0];

  zenscroll.center(el);
  el.focus();
}

export function getChangedValues(values, initialValues) {
  return Object
    .entries(values)
    .reduce((changedValues, [key, value]) => {
      const hasChanged = initialValues[key] !== value;

      if (hasChanged) {
        changedValues[key] = value;
      }

      return changedValues;
    }, {})
};
