import { SET_QUOTE, FETCH_QUOTE } from '../constants/action-types';

const initialState = {
  id: '',
  supplier_id: '',
  covers: '',
  total: '',
  state: '',
  commission_fee: '',
  service_fee: '',
  supportText: '',
  published: false,
  emailSent: false,
};

function enquiry(state = initialState, action) {
  switch (action.type) {
    case SET_QUOTE:
    case FETCH_QUOTE:
      return action.payload;
    default:
      return state;
  }
}

export default enquiry;
