import React from 'react';
import { connect } from 'formik';
import PropTypes from 'prop-types';

import { ReactComponent as TickIcon } from '../../images/sprite/ui/tick.svg';

const FormCheckbox = ({ input, label, disabled, meta }) => (
  <div className="checkbox">
    <input
      id={label}
      disabled={disabled}
      {...input}
      type="checkbox"
      checked={input.value}
    />
    <label
      htmlFor={label}
      className={`checkbox__label ${disabled && 'checkbox__label--disabled'}`}
    >
      <TickIcon />
      {label}
    </label>
    {meta.touched && meta.error && (
      <span className="checkbox__error">{meta.error}</span>
    )}
  </div>
);

FormCheckbox.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.bool,
  }).isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

FormCheckbox.defaultProps = {
  disabled: false,
  label: '',
};

export default FormCheckbox;

const FormikCheckboxBase = ({
  name,
  value,
  onBlur,
  onChange,
  label,
  formik: { errors, touched },
}) => (
  <FormCheckbox
    label={label || name}
    input={{
      value,
      onBlur,
      onChange,
      name,
    }}
    meta={{
      error: errors[name],
      name: touched[name],
    }}
  />
);

FormikCheckboxBase.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    errors: PropTypes.objectOf(PropTypes.string).isRequired,
    touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  }).isRequired,
};

export const FormikCheckbox = connect(FormikCheckboxBase);
