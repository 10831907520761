import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

export default function TableList({
  data,
  columns,
  defaultPageSize,
  ...props
}) {
  const tableColumns = columns.map((column) => ({
    Header: column.title,
    accessor: column.name,
    Cell: column.cell,
    ...column,
  }));

  return (
    <ReactTable
      data={data}
      columns={tableColumns}
      defaultPageSize={defaultPageSize || 10}
      showPageSizeOptions={defaultPageSize === null}
      {...props}
    />
  );
}

TableList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  defaultPageSize: PropTypes.number,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      cell: PropTypes.func,
    }).isRequired
  ).isRequired,
};

TableList.defaultProps = {
  defaultPageSize: null,
};
