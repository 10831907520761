import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import setHeaderColor from '../../actions/header';
import { setUser } from '../../actions/user';
import SettingsForm from '../forms/settings-form';
import { fetchSettings, updateSetting } from '../../actions/settings';
import _ from 'lodash';

class Settings extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      submitting: false,
      saved: false,
      disabled: false,
      error: '',
    };
  }
  componentWillMount() {
    this.props.setHeaderColor('white');
    this.update(this.props);
    this.loadSettings();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user === null) {
      this.update(nextProps);
    }
  }

  update(props) {
    const { user, router } = props;

    if (user == null) {
      router.replace('/login');
    }
  }

  loadSettings() {
    this.setState({ isLoading: true });
    this.props
      .fetchSettings()
      .then(() =>
        this.setState({ isLoading: false, settings: this.props.settings })
      )
      .catch((err) => {
        if (err.status === 401) {
          this.props.setUser(null);
        }
      });
  }

  onSaveClick(formData) {
    const settings = formData;
    const keys = _.keys(settings);
    const promises = keys.map((option_name) => {
      this.setState({
        submitting: true,
        saved: false,
        disabled: true,
        error: '',
      });
      return this.props
        .updateSetting({ option_name, option_value: settings[option_name] })
        .then(() => {
          return true;
        });
    });

    Promise.all(promises)
      .then((result) => {
        this.loadSettings();
        this.setState({ submitting: false, saved: true });

        setTimeout(
          () => this.setState({ saved: false, disabled: false }),
          1000
        );
      })
      .catch((error) => {
        this.setState({
          error: error.response,
          submitting: false,
          disabled: false,
        });
      });
  }

  render() {
    const settings = this.props.settings;
    return (
      <main className="view admin">
        <h1>Settings</h1>
        <SettingsForm
          onSubmit={this.onSaveClick.bind(this)}
          data={settings}
          state={this.state}
        />
      </main>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    { setUser, setHeaderColor, fetchSettings, updateSetting },
    dispatch
  );
}

const mapStateToProps = ({ settings, user }) => ({
  settings,
  user,
});

export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(Settings)
);
