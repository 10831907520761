import { createSelector } from 'reselect';

const getCalendarItems = (state) => state.calendar.items;

function getTitle({ title, state, booking }) {
  if (booking) {
    return `${booking.customer.firstName}'s ${booking.type}`;
  }

  return title || state;
}

export const getCalendarEvents = createSelector(getCalendarItems, (items) =>
  items.map((item) => ({
    ...item,
    title: getTitle(item),
    allDay: true,
    startTime: new Date(item.startTime),
    endTime: new Date(item.endTime),
    raw: item,
  }))
);
