import {
  FETCH_SUPPLIERS,
  FETCH_SUPPLIER_CALENDAR,
  CREATE_SUPPLIER_CALENDAR_ITEM,
  UPDATE_SUPPLIER_CALENDAR_ITEM,
  DELETE_SUPPLIER_CALENDAR_ITEM,
} from '../constants/action-types';

const initialSupplierState = {
  items: [],
  numPages: 0,
};

const initialSupplierCalendarsState = {
  items: [],
};

export const suppliers = (state = initialSupplierState, action) => {
  switch (action.type) {
    case FETCH_SUPPLIERS:
      return Object.assign({}, state, {
        items: action.payload.results,
        totalRecords: action.payload.totalRecords,
      });
    default:
      return state;
  }
};

export const calendar = (state = initialSupplierCalendarsState, action) => {
  switch (action.type) {
    case FETCH_SUPPLIER_CALENDAR:
      return { ...state, items: action.payload };
    case CREATE_SUPPLIER_CALENDAR_ITEM:
      return { ...state, items: [...state.items, action.payload] };
    case UPDATE_SUPPLIER_CALENDAR_ITEM:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.uuid === action.payload.uuid) {
            return { ...item, ...action.payload };
          }

          return item;
        }),
      };
    case DELETE_SUPPLIER_CALENDAR_ITEM:
      return {
        ...state,
        items: state.items.filter(({ uuid }) => uuid !== action.payload),
      };
    default:
      return state;
  }
};
