import { gql } from '@apollo/client';

export const REQUEST_BY_ID = gql`
  query eventRequests($request_id: String!) {
    result: eventRequests(where: { id: { equals: $request_id } }) {
      uuid
      id
      completed
      close_date
      close_source
      categories_tier2 {
        sys_name
        name
        parent
      }
      description
      budget
      budget_flexible
      freshsales_stage
      freshsales_id
      admin_note
      accepting_quotes
      additional_info {
        courses
        children
        waiters
        water
        crockery
        power
        bar_type
        supplier_setup
      }
      item_quantity
      categories_tier2 {
        parent
        name
        sys_name
      }
      event_request_tags {
        tags_event_request_tags_tag_uuidTotags {
          name
          uuid
          root_tags {
            key
          }
        }
      }
    }
  }
`;
