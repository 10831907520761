import React, { useEffect, useState, useCallback } from 'react';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { format } from 'date-fns';
import { useQuery, useMutation } from '@apollo/client';
import { Tooltip, baseTheme } from '@feast-it/ui';
import { ThemeProvider } from 'emotion-theming';

import {
  updateRequest,
  deleteRequest,
  fetchRequest,
  sendAllQuotesEmail,
} from '../../actions/requests';
import RequestField from '../../components/booking-field';
import Quote from '../partials/quote';
import { copyToClipboard } from '../../libs/dom-utils';
import Button from '../../components/button';
import { paths } from '../../constants/paths';
import { HIDDEN_EVENT_REQUESTS } from '../../constants/hidden-event-requests';
import { EVENT_AREAS } from '../../constants/event-areas';
import { captureError } from '../../modules/monitoring';
import { REQUEST_WITH_EVENT_BY_ID } from '../../queries/requestWithEventById';
import {
  ADDITIONAL_INFO_BAR_TYPE,
  ADDITIONAL_INFO_COURSES,
} from '../../constants/additional-info';
import {
  RUN_ROBEX_MATCHING,
  RUN_VENUES_MATCHING,
  DECLINE_QUOTE,
} from '../../mutations';
import { handleOnImpersonateUser } from '../../modules/impersonateUser';
import { IMPERSONATE_USER_MUTATION } from '../../mutations/impersonate-user';
import {
  filterRequestTagsByRootTag,
  getAllRequestTagsNames,
} from '../../modules/tags';
import { SUPPLIER_PARTNERSHIP_FEATURE } from '../../constants/supplierPartnerships';
import { CANCEL_REQUEST } from '../../mutations/cancel-request';

const Request = ({
  onFetchRequest,
  onDeleteRequest,
  onUpdateRequest,
  params: { requestId },
  allQuotesEmail,
  onSendAllQuotesEmail,
}) => {
  const [urlCopied, setUrlCopied] = useState(false);
  const [robexStarted, setRobexStarted] = useState(false);
  const [suppliersNotified, setSuppliersNotified] = useState(0);
  const [impersonateMutation] = useMutation(IMPERSONATE_USER_MUTATION, {});
  const { treatments, isReady } = useSplitTreatments({
    names: [SUPPLIER_PARTNERSHIP_FEATURE],
  });

  const isSupplierPartnershipVisible =
    treatments[SUPPLIER_PARTNERSHIP_FEATURE].treatment === 'on' && isReady;

  useEffect(() => {
    onFetchRequest(requestId);
  }, [requestId, onFetchRequest]);

  const {
    data: requestQueryData,
    error: requestQueryError,
    loading: requestQueryLoading,
    refetch: refetchRequestQuery,
  } = useQuery(REQUEST_WITH_EVENT_BY_ID, {
    variables: {
      request_id: requestId,
    },
    skip: !requestId,
    onError: (error) => captureError(error),
  });

  const eventRequest = requestQueryData?.result?.[0];
  const event = eventRequest?.events;
  const user = event?.users;

  const eventAreas = event?.event_areas
    ?.map((area) => EVENT_AREAS[area.area])
    .join(' • ');
  const tagGroup =
    eventRequest?.event_request_tags?.map(
      (tag) => tag.tags_event_request_tags_tag_uuidTotags.name
    ) ?? [];
  const additionalInfoCourses =
    eventRequest?.additional_info.courses?.map(
      (course) => ADDITIONAL_INFO_COURSES[course]
    ) ?? [];
  const eventTagGroup = event?.event_tags?.map((tag) => tag.tags.name) ?? [];
  const flexibleBudget = eventRequest?.budget_flexible ? ' - Flexible' : '';
  const isBarVertical =
    eventRequest?.categories_tier2?.sys_name === 'mobile_bar' ||
    eventRequest?.categories_tier2?.sys_name === 'event_bar';
  const isVenueVertical = eventRequest?.categories_tier2?.sys_name === 'venues';
  const isCateringVertical =
    eventRequest?.categories_tier2?.sys_name === 'catering';
  const layoutTag = eventRequest?.event_request_tags?.find(
    (tag) =>
      tag.tags_event_request_tags_tag_uuidTotags.root_tags.key === 'layout'
  );
  const layoutName = layoutTag?.name ?? 'Not sure about';
  const noOfGuests = isVenueVertical
    ? `${eventRequest?.item_quantity} - ${layoutName} Layout`
    : eventRequest?.item_quantity;
  const requestTags = eventRequest?.event_request_tags;
  const venueTypeTags = getAllRequestTagsNames(
    filterRequestTagsByRootTag(requestTags, 'venues')
  );
  const venueStyleTags = getAllRequestTagsNames(
    filterRequestTagsByRootTag(requestTags, 'venue_style')
  );
  const venueServicesTags = getAllRequestTagsNames(
    filterRequestTagsByRootTag(requestTags, 'venue_services')
  );

  const notifiedSuppliers = eventRequest?.event_request_supplier_notified?.map(
    (supplier) => supplier.suppliers.name
  );

  const requestIsOpen = eventRequest?.completed && eventRequest?.open;

  const [
    runRobexMatchingMutation,
    { loading: isLoadingRunRobexMatching, error: runRobexMatchingError },
  ] = useMutation(RUN_ROBEX_MATCHING, {
    variables: {
      requestId: eventRequest?.uuid,
      callMethod: 'admin',
      sendToFreshsales: true,
    },
    onError: (robexMatchingError) => captureError(robexMatchingError),
    onCompleted: () => refetchRequestQuery(),
  });

  const [
    runVenuesMatchingMutation,
    { loading: isLoadingRunVenuesMatching, error: runVenuesMatchingError },
  ] = useMutation(RUN_VENUES_MATCHING, {
    variables: {
      requestId: eventRequest?.uuid,
      callMethod: 'admin',
      sendToFreshsales: true,
    },
    onError: (venuesMatchingError) => captureError(venuesMatchingError),
    onCompleted: () => refetchRequestQuery(),
  });

  const matchingError = isVenueVertical
    ? runVenuesMatchingError
    : runRobexMatchingError;
  const isLoadingMatching =
    isLoadingRunRobexMatching || isLoadingRunVenuesMatching;

  const showRunRobexButton = !eventRequest?.quote_limited;

  const triggerRobex = useCallback(async () => {
    setRobexStarted(true);

    if (isVenueVertical) {
      await runVenuesMatchingMutation();
    } else {
      const { data: robexData } = await runRobexMatchingMutation();

      setSuppliersNotified(robexData?.runRobexMatching?.suppliersNotified);
    }
  }, [eventRequest?.uuid, isVenueVertical]);

  const [declineQuote, { loading: declineQuoteInProgress }] =
    useMutation(DECLINE_QUOTE);

  const handleDeclineQuote = async (quoteId, declinedReason) => {
    await declineQuote({
      variables: {
        quoteId,
        declinedReason,
        declinedContext: 'ADMIN',
      },
      onError: (declineQuoteError) => captureError(declineQuoteError),
      onCompleted: () => onFetchRequest(requestId),
    });
  };

  const [cancelRequestMutation] = useMutation(CANCEL_REQUEST, {
    onError: (robexMatchingError) => captureError(robexMatchingError),
  });

  const onCloseRequest = useCallback(
    async (eventRequestUuid) => {
      await cancelRequestMutation({
        variables: {
          requestId: eventRequestUuid,
          reason: 'Closed by Platform',
        },
      });
      await refetchRequestQuery();
      await onFetchRequest(requestId);
    },
    [cancelRequestMutation, refetchRequestQuery, onFetchRequest, requestId]
  );
  const hasBookedQuote = eventRequest?.quotes?.some(
    (quote) => quote.state === 'booked'
  );

  return (
    <main className="view view--bookings">
      <div className="container page-title clearfix">
        {eventRequest && (
          <h1 className="u-mb-s">
            {requestId} - {user?.firstName} {user?.lastName} - £
            {eventRequest?.budget}{' '}
            {event?.location_city ? `- ${event?.location_city}` : ''}
          </h1>
        )}

        {!eventRequest && !requestQueryLoading && (
          <h1 className="u-mb-s">This request no longer exists</h1>
        )}
      </div>
      <div className="row--grey">
        {!eventRequest && !requestQueryLoading ? (
          <div className="button-container">
            <Link className="pull-left" to="/admin/requests">
              <Button type="button" className="button button--grey-light">
                &laquo; Back to all Requests
              </Button>
            </Link>
          </div>
        ) : (
          <div className="container">
            <div className="clearfix">
              <div className="row" style={{ margin: '20px 0 0' }}>
                <Link className="pull-left" to="/admin/requests">
                  <Button type="button" className="button button--grey-light">
                    &laquo; Back to all Requests
                  </Button>
                </Link>

                {!isVenueVertical &&
                  (Boolean(event?.partnership_id) &&
                  isSupplierPartnershipVisible ? (
                    <ThemeProvider theme={baseTheme}>
                      <Tooltip
                        description="This event belongs to a venue partner and quotes
                              will need to be added via impersonation"
                        infoText=""
                      >
                        <Button
                          type="button"
                          className="button button--green"
                          disabled={Boolean(event?.partnership_id)}
                        >
                          + Add Quote
                        </Button>
                      </Tooltip>
                    </ThemeProvider>
                  ) : (
                    <Link
                      className="pull-left"
                      to={`/admin/requests/${requestId}/add-quote`}
                      style={{ marginLeft: '15px' }}
                    >
                      <Button type="button" className="button button--green">
                        + Add Quote
                      </Button>
                    </Link>
                  ))}

                <Link
                  className="pull-left"
                  to={`/admin/requests/${requestId}/edit`}
                  style={{ marginLeft: '15px' }}
                >
                  <Button type="button" className="button button--blue">
                    Edit request
                  </Button>
                </Link>

                {eventRequest?.freshsales_id && (
                  <Link
                    className="pull-left"
                    to={`https://feastit.freshsales.io/${
                      eventRequest?.freshsales_stage === 'lead'
                        ? 'leads'
                        : 'deals'
                    }/${eventRequest?.freshsales_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: '15px' }}
                  >
                    <Button className="button">View on Freshsales</Button>
                  </Link>
                )}

                <Button
                  className="button pull-left"
                  style={{ marginLeft: '15px' }}
                  onClick={() => {
                    copyToClipboard(
                      `https://togather.com/suppliers/dashboard/open-events/${requestId}`
                    );
                    setUrlCopied(true);
                  }}
                >
                  {urlCopied ? 'Supplier url copied' : 'Copy supplier url'}
                </Button>

                {showRunRobexButton && (
                  <Button
                    color="blue"
                    text="Run robex again"
                    style={{ marginLeft: '15px' }}
                    onClick={() => {
                      triggerRobex(eventRequest?.uuid);
                    }}
                    isLoading={isLoadingMatching}
                  />
                )}

                <Button
                  color="red"
                  text="Delete Request"
                  onClick={() => {
                    if (
                      window.confirm(
                        'Are you sure you would like to delete this request?'
                      )
                    ) {
                      onDeleteRequest(eventRequest?.uuid);
                    }
                  }}
                />
              </div>
            </div>

            {robexStarted && (
              <div className="row--white clearfix" style={{ margin: '20px 0' }}>
                <div className="col-md-12">
                  {!matchingError && !isLoadingMatching && (
                    <span className="text-success">
                      {isVenueVertical || !suppliersNotified
                        ? 'Robex service successfully ran'
                        : `[${suppliersNotified}] suppliers notified`}
                    </span>
                  )}

                  {matchingError && (
                    <span className="text-danger">{matchingError}</span>
                  )}
                </div>
              </div>
            )}

            <div className="row--white clearfix" style={{ margin: '20px 0' }}>
              <div className="col-md-12">
                <div className="col-md-3">
                  <h3 className="u-mb--0 u-mt--s">Summary</h3>
                </div>
              </div>

              <div className="col-md-12">
                <div className="col-md-3">
                  <RequestField
                    label="Accepting Quotes"
                    value={eventRequest?.accepting_quotes ? 'Yes' : 'No'}
                  />
                </div>

                <div className="col-md-3">
                  <RequestField
                    label="Budget (£)"
                    value={eventRequest?.budget + flexibleBudget}
                  />
                </div>

                <div className="col-md-3">
                  <RequestField label="Number of Guests" value={noOfGuests} />
                </div>

                <div className="col-md-3">
                  <RequestField
                    label="Completed"
                    value={eventRequest?.completed ? 'Yes' : 'No'}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="col-md-3">
                  <RequestField
                    label="Preferred Suppliers"
                    value={
                      eventRequest?.preferred_supplier_names?.length
                        ? eventRequest.preferred_supplier_names.join(' • ')
                        : ''
                    }
                  />
                </div>

                <div className="col-md-3">
                  <RequestField
                    label="Suppliers Notified"
                    value={
                      notifiedSuppliers?.length
                        ? notifiedSuppliers.join(' • ')
                        : ''
                    }
                  />
                </div>

                <div className="col-md-3">
                  <RequestField
                    label="Note from Togather"
                    value={eventRequest?.admin_note}
                  />
                </div>

                {isBarVertical && (
                  <div className="col-md-3">
                    <RequestField
                      label="Bar Type"
                      value={`${
                        ADDITIONAL_INFO_BAR_TYPE[
                          eventRequest?.additional_info?.bar_type
                        ]
                      } bar`}
                    />
                  </div>
                )}
              </div>

              <div className="col-md-12">
                <div className="col-md-3">
                  <RequestField label="Name" value={user?.firstName} />
                </div>

                <div className="col-md-3">
                  <Link to={paths.USER(user?.id)}>
                    <RequestField label="Email" value={user?.email} />
                  </Link>
                </div>

                <div className="col-md-3">
                  <RequestField label="Phone" value={user?.phone} />
                </div>

                <div className="col-md-3">
                  {eventRequest?.created && (
                    <RequestField
                      label="Date of request"
                      value={format(
                        new Date(eventRequest?.created),
                        'do MMMM yyyy'
                      )}
                    />
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="col-md-3">
                  <h3 className="u-mb--0 u-mt--l">Request Details</h3>
                </div>
              </div>

              <div className="col-md-12">
                <div className="col-md-3">
                  <RequestField
                    label="Request Vertical"
                    value={eventRequest?.categories_tier2?.name}
                  />
                </div>

                <div className="col-md-3">
                  <RequestField
                    label="Description"
                    value={eventRequest?.description}
                  />
                </div>

                {isVenueVertical && venueServicesTags.length >= 1 && (
                  <div className="col-md-3">
                    <RequestField
                      label="Venue Requirements"
                      value={venueServicesTags.join(' • ')}
                    />
                  </div>
                )}
              </div>
              <div className="col-md-12">
                {!isVenueVertical && (
                  <div className="col-md-3">
                    <RequestField label="Tags" value={tagGroup.join(' • ')} />
                  </div>
                )}

                {isVenueVertical && (
                  <>
                    <div className="col-md-3">
                      <RequestField
                        label="Venue Type"
                        value={venueTypeTags?.join(' • ')}
                      />
                    </div>

                    <div className="col-md-3">
                      <RequestField
                        label="Venue Style"
                        value={venueStyleTags?.join(' • ')}
                      />
                    </div>
                  </>
                )}

                <div className="col-md-3">
                  <RequestField
                    label="Supplier Setup"
                    value={eventRequest?.additional_info?.supplier_setup}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="col-md-3">
                  <h3 className="u-mb--0 u-mt--l">Event logistics</h3>
                </div>
              </div>

              <div className="col-md-12">
                <div className="col-md-3">
                  {event?.date && (
                    <RequestField
                      label="Date of event"
                      value={format(new Date(event?.date), 'do MMMM yyyy')}
                    />
                  )}
                </div>

                <div className="col-md-3">
                  <RequestField label="Start time" value={event?.start_time} />
                </div>

                <div className="col-md-3">
                  <RequestField label="End time" value={event?.end_time} />
                </div>
              </div>

              <div className="col-md-12">
                <div className="col-md-3">
                  {event?.date && (
                    <RequestField
                      label="Date flexible"
                      value={event?.date_flexible.toString()}
                    />
                  )}
                </div>

                <div className="col-md-6">
                  <RequestField
                    label="Date flexiblity notes"
                    value={event?.dateFlexibilityNotes ?? 'n/a'}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="col-md-3">
                  <RequestField
                    label="Address of the event"
                    value={`${event?.location_line_1}, ${
                      event?.location_line_2 && `${event?.location_line_2},`
                    } ${event?.location_city}, ${event?.postcode}`}
                  />
                </div>

                {isVenueVertical && !requestQueryError && (
                  <div className="col-md-3">
                    <RequestField label="London areas" value={eventAreas} />
                  </div>
                )}

                <div className="col-md-3">
                  <RequestField
                    label="Location notes"
                    value={event?.location_notes}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="col-md-3">
                  <RequestField
                    label="Event Tags"
                    value={eventTagGroup.join(' • ')}
                  />
                </div>
              </div>

              {isCateringVertical && eventRequest?.additional_info && (
                <>
                  <div className="col-md-12">
                    <div className="col-md-3">
                      <h3 className="u-mb--0 u-mt--l">Additional Info</h3>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="col-md-3">
                      <RequestField
                        label="Children"
                        value={eventRequest?.additional_info.children}
                      />
                    </div>

                    <div className="col-md-3">
                      <RequestField
                        label="Courses"
                        value={additionalInfoCourses.join(' • ')}
                      />
                    </div>

                    <div className="col-md-3">
                      <RequestField
                        label="Crockery"
                        value={eventRequest?.additional_info.crockery}
                      />
                    </div>

                    <div className="col-md-3">
                      <RequestField
                        label="Power"
                        value={eventRequest?.additional_info.power}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="col-md-3">
                      <RequestField
                        label="Supplier setup"
                        value={eventRequest?.additional_info.supplier_setup}
                      />
                    </div>

                    <div className="col-md-3">
                      <RequestField
                        label="Waiters"
                        value={eventRequest?.additional_info.waiters}
                      />
                    </div>

                    <div className="col-md-3">
                      <RequestField
                        label="Water"
                        value={eventRequest?.additional_info.water}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="col-md-12 u-margin-bottom u-mb--xl">
                <div className="col-md-3">
                  <h3>Request is {requestIsOpen ? 'Open' : 'Cancelled'}</h3>
                  {requestIsOpen ? (
                    <Button
                      color={hasBookedQuote ? 'grey' : 'red'}
                      text="Cancel Request"
                      disabled={hasBookedQuote}
                      title={
                        hasBookedQuote
                          ? 'Cannot cancel as has booked quote(s)'
                          : 'Click to cancel request and decline all pending quotes'
                      }
                      onClick={() => onCloseRequest(eventRequest?.uuid)}
                    />
                  ) : (
                    <Button
                      color="grey"
                      text="Open Request"
                      title="Click to open request, declined quotes will not be reopened"
                      onClick={async () => {
                        onUpdateRequest(eventRequest?.uuid, { open: true });
                        await refetchRequestQuery();
                      }}
                    />
                  )}
                </div>
              </div>

              {Boolean(eventRequest?.declined_event_requests?.length) && (
                <div className="col-md-12 u-margin-bottom u-mb--xl">
                  <div className="col">
                    <h3>Hidden by:</h3>

                    <div className="d-inline-flex p-2">
                      <ul>
                        {eventRequest?.declined_event_requests?.map(
                          (declined_request) => (
                            <li key={declined_request.supplier_id}>
                              {declined_request.supplier_name} :{' '}
                              {
                                HIDDEN_EVENT_REQUESTS[
                                  declined_request.declined_reason
                                ]
                              }
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              {Boolean(eventRequest?.quotes?.length) && (
                <div className="col-md-12 quote-wrapper">
                  <div className="row">
                    <div className="col-md-12 u-mb--s">
                      <h3 className="u-mb--s u-mt--s">Quotes</h3>

                      <Button
                        disabled={
                          eventRequest?.all_quotes_email_sent ||
                          allQuotesEmail.loading
                        }
                        text={
                          eventRequest?.all_quotes_email_sent
                            ? 'All Quotes Notification Sent'
                            : 'Send All Quote Notification'
                        }
                        onClick={() => onSendAllQuotesEmail(eventRequest?.uuid)}
                        color={
                          eventRequest?.all_quotes_email_sent ? 'grey' : 'red'
                        }
                        isLoading={allQuotesEmail.loading}
                      />

                      {allQuotesEmail.error && (
                        <span className="field__error">
                          {allQuotesEmail.error}
                        </span>
                      )}
                    </div>
                  </div>

                  {Boolean(eventRequest?.quotes?.length) &&
                    eventRequest.quotes.map((quote) => {
                      const conversation = eventRequest?.conversations.filter(
                        (conversation) =>
                          conversation.suppliers.uuid === quote.supplier_id
                      )[0];
                      const conversationId = conversation?.uuid;

                      return (
                        <Quote
                          key={quote.uuid}
                          quote={quote}
                          isRequest
                          requestId={requestId}
                          handleDeclineQuote={handleDeclineQuote}
                          declineQuoteInProgress={declineQuoteInProgress}
                          conversationId={conversationId}
                          userId={user?.id}
                          handleOnImpersonateUser={handleOnImpersonateUser}
                          impersonateMutation={impersonateMutation}
                        />
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  onFetchRequest: fetchRequest,
  onDeleteRequest: deleteRequest,
  onUpdateRequest: updateRequest,
  onSendAllQuotesEmail: sendAllQuotesEmail,
};

const mapStateToProps = ({ allQuotesEmail }) => ({
  allQuotesEmail,
});

export default connect(mapStateToProps, mapDispatchToProps)(Request);
