import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SupplierTagsFields from '../forms/supplier-tags-fields';
import { SETUP_TAGS_PID } from '../../constants/tags';

const SupplierTypes = ({ supplierTypes, onChange, childrenTags }) => {
  const tags = childrenTags
    .filter(({ pid }) => pid === SETUP_TAGS_PID)
    .map((tag) => ({ ...tag, tagId: tag.id }));

  return (
    <SupplierTagsFields
      tags={tags}
      supplierTags={supplierTypes}
      onChange={onChange}
      label="Supplier types"
    />
  );
};

SupplierTypes.propTypes = {
  supplierTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  childrenTags: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ supplier, childrenTags }) => ({
  supplier,
  childrenTags,
});

export default connect(mapStateToProps)(SupplierTypes);
