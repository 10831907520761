import { datadogRum } from '@datadog/browser-rum';

import { properties } from '../../app-config';

export const initDatadogRum = () => {
  if (properties.datadogApplicationId && properties.datadogClientToken) {
    datadogRum.init({
      applicationId: properties.datadogApplicationId,
      clientToken: properties.datadogClientToken,
      site: 'datadoghq.eu',
      service: 'admin',
      version: properties.version,
      env: properties.deployedEnvironment,
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [/https:\/\/[A-Za-z]*-*api-[A-Za-z]*\.feast-it\.com/],
    });
  }
};

export const captureError = (error, context) =>
  datadogRum.addError(error, context);
