import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { uploadFile } from '../actions/upload';

class Upload extends Component {
  constructor() {
    super();
    this.state = {
      files: [],
      uploadedFiles: [],
      error: '',
    };
  }

  onDrop(eid, files) {
    files.map((file) => this.uploadImage(eid, file));
  }

  async uploadImage(eid, file) {
    const image = await uploadFile(eid, file);

    this.props.setUploadedFile(image);
  }

  render() {
    const { eid, className, name, size = '100px' } = this.props;

    const { error } = this.state;

    const styles = {
      width: size,
      height: size,
      borderWidth: '2px',
      borderColor: 'rgb(102, 102, 102)',
      borderStyle: 'dashed',
      borderRadius: '5px',
    };
    return (
      <div className={className}>
        {eid && (
          <Dropzone
            onDrop={(files) => this.onDrop(eid, files)}
            accept="image/*"
            name={name}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} style={{ textAlign: 'center' }}>
                <input {...getInputProps()} style={styles} />
              </div>
            )}
          </Dropzone>
        )}
        {error && (
          <span className="text-danger">Error while uploading, try again.</span>
        )}
      </div>
    );
  }
}

export default Upload;
