import groupBy from 'lodash/groupBy';
import {
  FETCH_ROOT_TAGS,
  FETCH_TAGS,
  FETCH_TAG,
  RECEIVED_TAGS,
} from '../constants/action-types';

const tagInitialState = {
  id: '',
  name: '',
  pid: '',
};
const tagsInitialState = [];

export function rootTags(state = tagsInitialState, action) {
  switch (action.type) {
    case FETCH_ROOT_TAGS:
      return action.payload;
    default:
      return state;
  }
}

export function childrenTags(state = tagsInitialState, action) {
  switch (action.type) {
    case FETCH_TAGS:
      return action.payload;
    default:
      return state;
  }
}

export function tag(state = tagInitialState, action) {
  switch (action.type) {
    case FETCH_TAG:
      return action.payload;
    case RECEIVED_TAGS:
      return Object.assign(
        {},
        state,
        groupBy(action.payload, (t) => t.pid)
      );
    default:
      return state;
  }
}
