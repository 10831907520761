import { gql } from '@apollo/client';

export const DECLINE_QUOTE = gql`
  mutation declineQuote(
    $quoteId: String!
    $declinedReason: declined_reason!
    $declinedContext: DeclinedContext
  ) {
    declineQuote(
      quoteId: $quoteId
      declinedReason: $declinedReason
      declinedContext: $declinedContext
    ) {
      complete
    }
  }
`;
