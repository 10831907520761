import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';
import moment from 'moment';
import { Query } from '@apollo/client/react/components';
import { client as apolloClient } from '../../modules/apollo/apollo';
import {
  calculateQuotePriceBreakdown,
  priceBeforeRateAdded,
  priceAfterRateRemoved,
} from '@feast-it/currency';

import {
  QUOTE_BOOKED,
  QUOTE_PENDING,
  QUOTE_DECLINED,
  QUOTE_CANCELLED,
  INVOICE_REQUESTED,
} from '../../constants/quote-states';
import {
  adminCancelQuote,
  fetchQuote,
  adminUndeclineQuote,
} from '../../actions/quotes';
import setHeaderColor from '../../actions/header';
import { setUser } from '../../actions/user';
import Button from '../../components/button';
import BookingField from '../../components/booking-field';
import SEODataFields from '../../components/seo-data-fields';
import { fetchPartnership } from '../../actions/partnerships';
import { QUOTE_BY_UUID } from '../../queries/quoteByUuid';
import { MARK_QUOTE_AS_PAID } from '../../mutations';
import { captureError } from '../../modules/monitoring';
import { EDIT_EVENT_WITH_PARTNERSHIP_ID_MUTATION } from '../../mutations/edit-event';
import { GET_PARTNERSHIP_BY_SLUG } from '../../queries/partnershipId';

class Quote extends Component {
  static defaultProps = { user: null };

  static propTypes = {
    setHeaderColor: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
    user: PropTypes.shape({}),
    quote: PropTypes.shape({
      uuid: PropTypes.string,
      state: PropTypes.string.isRequired,
    }).isRequired,
    router: PropTypes.shape({ replace: PropTypes.func }).isRequired,
    params: PropTypes.shape({ quoteId: PropTypes.string }).isRequired,
    fetchQuote: PropTypes.func.isRequired,
    fetchPartnership: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
    };
  }

  componentWillMount() {
    this.loadQuote();
    this.props.setHeaderColor('white');
    this.update(this.props);
  }

  componentDidMount() {
    this.props.getSupplierPayments({
      variables: { quoteId: this.props.params.quoteId },
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user === null) {
      this.update(nextProps);
    }
  }

  getAndSetPartnershipId = async () => {
    const { quote } = this.props;

    if (!quote?.supplier?.slug) {
      return null;
    }

    const { data } = await apolloClient.query({
      query: GET_PARTNERSHIP_BY_SLUG,
      variables: { slug: quote.supplier.slug },
      fetchPolicy: 'network-only',
    });

    const partnershipId = data?.partnership?.uuid;
    const supplierCoordinates = quote?.supplier?.coordinates;

    if (partnershipId) {
      try {
        await apolloClient.mutate({
          mutation: EDIT_EVENT_WITH_PARTNERSHIP_ID_MUTATION,
          variables: {
            eventUuid: quote.eventRequest.event.uuid,
            data: {
              partnershipId: partnershipId,
              coordinates: {
                lat: Number(supplierCoordinates.lat),
                lon: Number(supplierCoordinates.lon),
              },
            },
          },
        });
      } catch (err) {
        this.setState({
          errorMessage: 'Failed to update partnership ID.',
        });

        captureError(err);
      }
    }
  };

  setAsPaid = async () => {
    const { state, uuid } = this.props.quote;

    if (
      (state === QUOTE_PENDING || state === INVOICE_REQUESTED) &&
      window.confirm(
        'This will mark the quote as paid and send the client and supplier associated emails. This cannot be undone'
      )
    ) {
      try {
        await apolloClient.mutate({
          mutation: MARK_QUOTE_AS_PAID,
          variables: { quoteId: uuid, isProtectPlus: false },
        });

        await this.getAndSetPartnershipId();
      } catch (e) {
        captureError(e);
      }

      this.loadQuote();
    }
  };

  setAsPaidWithProtectPlus = async () => {
    const { state, uuid } = this.props.quote;

    if (
      (state === QUOTE_PENDING || state === INVOICE_REQUESTED) &&
      window.confirm(
        'This will mark the quote as paid and send the client and supplier associated emails. This cannot be undone'
      )
    ) {
      try {
        await apolloClient.mutate({
          mutation: MARK_QUOTE_AS_PAID,
          variables: { quoteId: uuid, isProtectPlus: true },
        });

        await this.getAndSetPartnershipId();
      } catch (e) {
        captureError(e);
      }

      this.loadQuote();
    }
  };

  handleCancelQuote = async () => {
    if (window.confirm('Are you sure you want to cancel this quote?')) {
      await adminCancelQuote(this.props.params.quoteId);
      this.loadQuote();
    }
  };

  handleUndeclineQuote = async () => {
    await adminUndeclineQuote(this.props.quote.uuid);
    this.loadQuote();
  };

  handleMarkSupplierAsPaid = () => {
    if (
      this.props.quote.payments?.length &&
      !this.props.supplerPayments?.length
    ) {
      this.props.markSupplierAsPaid({
        variables: { quoteId: this.props.params.quoteId },
      });
    }
  };

  loadPartnership() {
    if (this.props.quote && this.props.quote.eventRequest) {
      const { event } = this.props.quote.eventRequest;
      if (event && event.partnership_id) {
        this.props
          .fetchPartnership(event.partnership_id)
          .catch((err) => console.log('failure loading partnership'));
      }
    }
  }

  loadQuote() {
    const { quoteId } = this.props.params;

    this.props
      .fetchQuote(quoteId)
      .catch((err) => {
        if (err.status === 401) {
          this.props.setUser(null);
        }
      })
      .then(() => this.loadPartnership());
  }

  // loadQuoteLineItems() {
  //   const { quoteId } = this.props.params;

  //   const { quoteData } = useQuery({
  //     variables: {
  //       quoteUuid: quoteId,
  //     },
  //     skip: !uuid,
  //   });
  //   console.log(quoteData.quotes);
  // }

  update = (props) => {
    const { user, router } = props;

    if (user == null) {
      router.replace('/login');
    }
  };

  render() {
    const { errorMessage } = this.state;
    const { quote, partnership } = this.props;

    if (!quote || !quote.eventRequest || !quote.eventRequest.event)
      return 'Quote not found';

    const {
      location_line_1,
      postcode,
      location_city,
      start_time,
      end_time,
      date,
      SEOData,
      user: { email },
      partnership: isLinkedToPartnerVenue,
    } = quote.eventRequest.event;

    const {
      uuid,
      supplier,
      state,
      created,
      booked_date,
      service_fee,
      commission_fee,
      covers,
      payments,
      protection_type,
      published,
      total,
    } = quote;

    const standardVatRate = 20;
    const totalBeforeVatAndCommission = priceBeforeRateAdded(
      priceAfterRateRemoved(total, commission_fee),
      standardVatRate
    );

    const {
      includingVatPrices: {
        formattedToCurrency: {
          priceExclCommission: traderValue,
          priceInclCommissionAndServiceFee: customerTotal,
          priceInclCommissionAndServiceFeeAndProtection:
            customerTotalWithProtectPlus,
          protectionPrice: protectPlusFee,
          serviceFeePrice: serviceValue,
          commissionPrice: commissionValue,
        },
      },
    } = calculateQuotePriceBreakdown({
      subtotal: totalBeforeVatAndCommission,
      vatRate: standardVatRate,
      commissionRate: commission_fee,
      serviceFeeRate: service_fee,
      protectionRate: 5,
      protectionRounding: 5,
    });

    const paymentTypeCopy = {
      invoice: 'with Invoice',
      stripe_payment_plan: 'with Payment Plan',
      stripe_single_payment: 'with Single Card Payment',
    };

    const isSupplierPaid = this.props.supplierPayments?.length;
    const showBasicProtectionPrice =
      (state === QUOTE_BOOKED && protection_type === 'basic') ||
      state !== QUOTE_BOOKED;

    const showPremiumProtectionPrice =
      (state === QUOTE_BOOKED && protection_type === 'premium') ||
      state !== QUOTE_BOOKED;

    return (
      <Query query={QUOTE_BY_UUID} variables={{ quoteUuid: uuid }}>
        {({ data }) => {
          const quoteLineItems = data?.quotes?.[0]?.quote_line_items;
          const hasQuoteLineItems = quoteLineItems?.length > 0;

          return (
            <main className="view view--bookings">
              <div className="container page-title clearfix">
                <h1 className="pull-left">Quote Page</h1>
              </div>

              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              <div className="row--grey">
                <div className="container">
                  <div className="clearfix">
                    <Link
                      className="pull-left"
                      to="/admin/quotes"
                      style={{ margin: '10px 15px 10px 0' }}
                    >
                      <button className="button button--grey-light">
                        &laquo; Back to all Quotes
                      </button>
                    </Link>
                    <Link
                      className="pull-left"
                      to={`/admin/requests/${quote.eventRequest.id}`}
                      style={{ margin: '10px 15px' }}
                    >
                      <button className="button button--green">
                        See associated event request
                      </button>
                    </Link>

                    <Link
                      className="pull-left"
                      to={`/admin/requests/${quote.eventRequest.id}/${quote.uuid}`}
                      style={{ margin: '10px 15px' }}
                    >
                      <button className="button button--blue">
                        Edit quote
                      </button>
                    </Link>

                    {state === QUOTE_BOOKED && (
                      <>
                        <div
                          className="pull-left"
                          style={{ margin: '10px 15px' }}
                        >
                          <button
                            className={`button button--${
                              isSupplierPaid ? 'grey' : 'green'
                            }`}
                            onClick={this.handleMarkSupplierAsPaid}
                            disabled={isSupplierPaid}
                          >
                            {isSupplierPaid
                              ? 'Supplier Paid'
                              : 'Mark Supplier As Paid'}
                          </button>
                        </div>

                        <div
                          className="pull-left"
                          style={{ margin: '10px 15px' }}
                        >
                          <button
                            className="button button--yellow"
                            disabled={state === QUOTE_BOOKED}
                          >
                            {`Paid ${
                              paymentTypeCopy[quote.payment_type] ?? ''
                            }`}
                          </button>
                        </div>
                      </>
                    )}
                    {state === QUOTE_CANCELLED && quote.payment_type && (
                      <div
                        className="pull-left"
                        style={{ margin: '10px 15px' }}
                      >
                        <button
                          className="button button--red"
                          disabled={state === QUOTE_CANCELLED}
                        >
                          {`Paid ${paymentTypeCopy[quote.payment_type] ?? ''}`}
                        </button>
                      </div>
                    )}
                    {state === QUOTE_DECLINED && (
                      <div
                        className="pull-left"
                        style={{ margin: '10px 15px' }}
                      >
                        <button
                          className="button button--red"
                          onClick={this.handleUndeclineQuote}
                        >
                          Undecline quote
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {(state === QUOTE_PENDING || state === INVOICE_REQUESTED) && (
                <div className="row--grey">
                  <div className="container">
                    <div className="clearfix">
                      <div
                        className="pull-left"
                        style={{ margin: '10px 15px 10px 0' }}
                      >
                        <button
                          className="button button--yellow"
                          onClick={this.setAsPaid}
                          disabled={state === QUOTE_BOOKED}
                        >
                          {`Set as Offline Payment - ${customerTotal}`}
                        </button>
                      </div>

                      <div
                        className="pull-left"
                        style={{ margin: '10px 15px' }}
                      >
                        <button
                          className="button button--yellow"
                          onClick={this.setAsPaidWithProtectPlus}
                        >
                          {`Set as Offline Payment with Protect PLUS - ${customerTotalWithProtectPlus} (+${protectPlusFee})`}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row--grey">
                <div className="container">
                  <div
                    className="row--white clearfix"
                    style={{ margin: '20px 0' }}
                  >
                    <div className="col-md-12">
                      <div className="col-md-3">
                        <BookingField
                          label="Supplier's name"
                          value={supplier.name}
                        />
                        <BookingField
                          label="Published"
                          value={
                            published
                              ? 'Quote has been published'
                              : 'Not yet published'
                          }
                        />
                        <BookingField
                          label="Service Fee"
                          value={`${service_fee}% (${serviceValue})`}
                        />
                        <BookingField
                          label="Supplier's Fee"
                          value={traderValue}
                        />
                      </div>
                      <div className="col-md-3">
                        <BookingField label="Customer's email" value={email} />
                        <BookingField
                          label="Address of the event"
                          value={`${
                            location_line_1 ? `${location_line_1},` : ''
                          } ${postcode}, ${location_city}`}
                        />
                        <BookingField
                          label="Commission Fee"
                          value={`${commission_fee}% (${commissionValue})`}
                        />
                        <BookingField
                          label="Protection Type"
                          value={
                            protection_type === 'premium'
                              ? `Protect PLUS (${protectPlusFee})`
                              : `Basic £0`
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <BookingField
                          label="Date of event"
                          value={moment(date).format('Do MMMM YYYY')}
                        />
                        <BookingField
                          label="Date created"
                          value={moment(created).format('Do MMMM YYYY')}
                        />

                        {showBasicProtectionPrice && (
                          <BookingField
                            label="Customer's Price (without Protect PLUS)"
                            value={`${customerTotal}`}
                          />
                        )}

                        {showPremiumProtectionPrice && (
                          <BookingField
                            label="Customer's Price (including Protection)"
                            value={`${customerTotalWithProtectPlus}`}
                          />
                        )}
                      </div>
                      <div className="col-md-3">
                        <BookingField
                          label="Start time"
                          value={start_time ?? 'N/a'}
                        />
                        <BookingField
                          label="End time"
                          value={end_time ?? 'N/a'}
                        />
                        <BookingField
                          label="Date booked"
                          value={
                            booked_date
                              ? moment(booked_date).format('Do MMMM YYYY')
                              : 'N/a'
                          }
                        />
                        <BookingField label="Number of guests" value={covers} />
                      </div>
                    </div>

                    <div className="col-md-12 u-margin-bottom">
                      <div className="col-md-12 u-margin-bottom">
                        {isLinkedToPartnerVenue && partnership ? (
                          <>
                            <h3>Partnership Information</h3>
                            <h4>This quote is linked to a partner venue.</h4>
                            <h4>Partner Details:</h4>
                            <p>Name: {partnership.name}</p>
                            <p>Postcode: {partnership.postcode} </p>
                            <p>Commission: {partnership.commission}%</p>
                          </>
                        ) : (
                          <h3>This quote is not linked to a partner venue</h3>
                        )}
                      </div>
                    </div>

                    {SEOData && <SEODataFields {...SEOData} />}

                    <div className="col-md-12 u-margin-bottom">
                      <div className="col-md-12 u-margin-bottom">
                        <h3>Quote is {state}</h3>
                        {state === QUOTE_BOOKED && (
                          <div>
                            <Button
                              color="grey"
                              text="Cancel Booking"
                              onClick={this.handleCancelQuote}
                            />
                          </div>
                        )}

                        {!payments.length && (
                          <span>The quote has no payments</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = ({ quote, user, partnership }) => ({
  quote,
  user,
  partnership,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUser,
      setHeaderColor,
      fetchQuote,
      fetchPartnership,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Quote));
