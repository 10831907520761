import { gql } from '@apollo/client';

export const UPLOAD_PUBLIC_IMAGE_MUTATION = gql`
  mutation uploadPublicImage($entityId: String!, $entityType: String!, $file: Upload!) {
    uploadPublicImage(entityId: $entityId, entityType: $entityType, file: $file) {
      entityType
      entityId
      imageUrl
    }
  }
`;
