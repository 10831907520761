import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import SupplierTagsFields from '../forms/supplier-tags-fields';
import { httpService } from '../../../app-config';
import FormSelect from '../../components/form-select';

const SupplierTags = ({ supplierTags, onChange, tier1, tier2 }) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    let isCancelled = false;

    const fetchAllTier2Tags = async (supplierTier2s) =>
      supplierTier2s?.reduce(async (allCategories, category) => {
        const categoryGroupTags = await httpService.request({
          path: `/tags/${category}/groups`,
        });
        return [...(await allCategories), ...categoryGroupTags];
      }, []);

    const fetchTags = async () => {
      const [tier1Tags, tier2Tags] = await Promise.all([
        await httpService.request({ path: `/tags/${tier1}/vertical` }),
        await fetchAllTier2Tags(tier2),
      ]);

      const data = [
        ...tier1Tags,
        ...tier2Tags?.reduce((allTier2Tags, tier2TagGroup) => {
          return [...allTier2Tags, ...tier2TagGroup.tags];
        }, []),
      ];

      if (data) {
        setTags(data);
      }
    };

    if (tier1 && !isCancelled) {
      fetchTags();
    }

    return () => {
      isCancelled = true;
    };
  }, [tier1, tier2]);

  const PRIMARY_TAG_OPTIONS = supplierTags?.reduce((tagOptions, tag) => {
    if (tag.pid === '3' || tag.pid === '0') {
      tagOptions.push({
        value: tag.tag_uuid,
        label: tag.name,
      });
    }

    return tagOptions;
  }, []);

  return (
    <>
      <Field
        name="public.logistics.primaryTag"
        className="form-control"
        component={FormSelect}
        label="Primary tag"
        labelClassName="form-control-label"
        options={PRIMARY_TAG_OPTIONS}
      />

      <SupplierTagsFields
        tags={tags}
        supplierTags={supplierTags}
        onChange={onChange}
        label="Supplier tags"
      />
    </>
  );
};

SupplierTags.propTypes = {
  supplierTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  tier1: PropTypes.string.isRequired,
  tier2: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SupplierTags;
