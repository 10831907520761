import { connect } from 'react-redux';
import compose from 'just-compose';
import React, { useCallback, useEffect } from 'react';
import { Link, withRouter } from 'react-router';
import PropTypes from 'prop-types';

import {
  created,
  read,
  updated,
  deleted,
} from '../../actions/supplier-reviews';

import { getReview } from '../../selectors/supplier-reviews';

import Spinner from '../../components/spinner';
import ReviewForm from '../forms/review-form';

const newReview = {
  supplier: null,
  published: false,
  authenticated: true,
  source: 'admin',
  fields: {},
  externalEvent: {
    userFullName: '',
    userEmail: null,
    eventType: '',
  },
  ratings: {
    valueForMoney: null,
    quality: null,
    professionalism: null,
    friendliness: null,
  },
  eventDate: null,
  dateSubmitted: '',
  publicComment: '',
  privateComment: '',
};

function Review({
  params: { reviewId },
  review,
  loading,
  onCreate,
  onRead,
  onUpdate,
  onDelete,
}) {
  const isNew = reviewId === 'new';
  const isLoading = !isNew && (!review || loading);

  useEffect(() => {
    if (reviewId && !isNew) {
      onRead(reviewId);
    }
  }, [onRead, reviewId, isNew]);

  const onSave = useCallback(
    (data) => {
      if (isNew) {
        onCreate(data);
      } else {
        onUpdate(reviewId, data);
      }
    },
    [onCreate, onUpdate, isNew, reviewId]
  );

  const data = isNew ? newReview : review;

  return (
    <main className="view view--review">
      <div className="container page-title clearfix">
        <h1 className="pull-left">
          {isNew ? 'Create' : 'Edit'}
          {' review'}
        </h1>
      </div>
      {isLoading && <Spinner />}
      {!isLoading && (
        <div className="container">
          <div className="meta">
            <div className="container">
              <div className="clearfix">
                <Link className="pull-left" to="/admin/reviews">
                  <button type="button" className="button button--grey-light">
                    &laquo; Back to Reviews
                  </button>
                </Link>
                {data.bookingId && (
                  <Link
                    className="pull-left"
                    to={`/admin/bookings/${data.bookingId}`}
                  >
                    <button type="button" className="button button--blue">
                      View booking
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
          <ReviewForm
            isNew={isNew}
            data={data}
            onSave={onSave}
            onDelete={onDelete}
          />
        </div>
      )}
    </main>
  );
}

Review.propTypes = {
  params: PropTypes.shape({
    reviewId: PropTypes.string.isRequired,
  }).isRequired,
  review: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    bookingId: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onRead: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

Review.defaultProps = {
  review: null,
};

const mapStateToProps = (state) => ({
  review: getReview(state),
  loading: state.supplierReviews.loading,
});

const mapDispatchToProps = {
  onCreate: created,
  onRead: read,
  onUpdate: updated,
  onDelete: deleted,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Review);
