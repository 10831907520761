import React from 'react';
import { Link } from 'react-router';

function getParamsFromString(str = '') {
  const params = {};
  // eslint-disable-next-line array-callback-return
  str.split('&').map((keyVal) => {
    const param = keyVal.split('=');
    if (param.length > 1) {
      params[param[0]] = param[1];
    } else {
      params[param[0]] = '';
    }
  });
  return params;
}
const Pagination = (props) => {
  const { paging, currentUrl, totalRecords = 30 } = props;
  const prevParamsStr = paging.previous ? paging.previous.split('?')[1] : '';
  const nextParamsStr = paging.next ? paging.next.split('?')[1] : '';
  const nextParams = getParamsFromString(nextParamsStr);
  const currentPage = Math.ceil(nextParams.offset / nextParams.limit);
  const totalPages = Math.ceil(totalRecords / nextParams.limit);
  const text = `Page ${currentPage} of ${totalPages}`;
  const prevClass =
    currentPage === 1 ? 'pagination__item hideit' : 'pagination__item';
  const nextClass =
    totalPages === currentPage ? 'pagination__item hideit' : 'pagination__item';
  return (
    <nav className="pagination">
      <Link to={`${currentUrl}?${prevParamsStr}`} className={prevClass}>
        {'< Prev'}
      </Link>
      <span className="pagination__item">{text}</span>
      <Link to={`${currentUrl}?${nextParamsStr}`} className={nextClass}>
        {'Next >'}
      </Link>
    </nav>
  );
};

export default Pagination;
