import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';

import LoginForm from '../forms/log-in-form';
import { login, setUser } from '../../actions/user';
import setHeaderColor from '../../actions/header';

import { ReactComponent as MarqueIcon } from '../../../images/sprite/logos/marque.svg';
import { isCognitoUserAdmin } from '../../modules/cognito';

class Login extends Component {
  componentWillMount() {
    this.props.dispatch(setHeaderColor('white'));
    this.update(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user !== nextProps.user) {
      this.update(nextProps);
    }
  }

  update(props) {
    const { user, redirect, router } = props;

    if (user && (user.type === 'admin' || isCognitoUserAdmin(user.token))) {
      router.replace(redirect);
    }
  }

  async handleLogin({ email, password }) {
    const { dispatch } = this.props;

    // Redux form onSubmit() callback must return a promise
    try {
      const user = await dispatch(login(email, password));
      return dispatch(setUser(user));
    } catch (err) {
      throw err;
    }
  }

  render() {
    const isUnauthorisedCognitoUser = Boolean(
      this.props.location.query.isAdmin === 'false'
    );

    return (
      <main className="view admin">
        <Link className="admin__logo" to="/">
          <MarqueIcon className="icon--marque" />
        </Link>
        <p>Sign in to manage suppliers and bookings</p>
        {isUnauthorisedCognitoUser && (
          <p>Please ask to be added as admin in AWS Cognito</p>
        )}
        <LoginForm onSubmit={this.handleLogin.bind(this)} />
      </main>
    );
  }
}

const mapStateToProps = ({ user }, { location }) => ({
  user,
  redirect: location.query.redirect || '/admin/requests',
});

export default withRouter(connect(mapStateToProps)(Login));
