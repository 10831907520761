import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router';
import compose from 'just-compose';
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getStateFromQuery, locationShape } from '../../libs/pagination';

import setHeaderColor from '../../actions/header';
import { searched } from '../../actions/supplier-reviews';

import { getSearchResults } from '../../selectors/supplier-reviews';

import Button from '../../components/button';
import TableSearch from '../../components/table-search';
import TableList from '../../components/table-list';

const Source = ({ value }) => (
  <span className={classNames('source', `source--${value}`)}>{value}</span>
);

Source.propTypes = {
  value: PropTypes.string.isRequired,
};

const Status = ({ value }) => (
  <span
    className={classNames('status', {
      'status--authenticated': value,
    })}
  >
    {value ? 'Verified' : 'Unverified'}
  </span>
);

Status.propTypes = {
  value: PropTypes.bool.isRequired,
};

const Published = ({ value }) => (
  <span
    className={classNames('published', {
      'published--yes': value,
    })}
  >
    {value ? 'True' : 'False'}
  </span>
);

Published.propTypes = {
  value: PropTypes.bool.isRequired,
};

const EditLink = ({ value }) => (
  <Link to={`/admin/reviews/${value}`} activeClassName="t--bold">
    <Button color="grey-light" text="Edit" />
  </Link>
);

EditLink.propTypes = {
  value: PropTypes.string.isRequired,
};

const columns = [
  {
    name: 'supplier',
    title: 'Supplier',
  },
  {
    name: 'publicComment',
    title: 'Review text',
  },
  {
    name: 'rating',
    title: 'Rating',
  },
  {
    name: 'dateSubmitted',
    title: 'Date Submitted',
  },
  {
    name: 'source',
    title: 'Source',
    cell: Source,
  },
  {
    name: 'authenticated',
    title: 'Status',
    cell: Status,
  },
  {
    name: 'published',
    title: 'Published',
    cell: Published,
  },
  {
    name: 'uuid',
    title: 'Manage',
    width: 140,
    cell: EditLink,
  },
];

function Reviews({
  router,
  location,
  user,
  reviews,
  numPages,
  onSearch,
  onSetHeaderColor,
}) {
  const [{ page, limit, query }, setSearch] = useState(
    getStateFromQuery({ location })
  );

  const onChangeQuery = (value) => {
    setSearch((last) => ({
      ...last,
      query: value,
      page: 0,
    }));
  };

  useEffect(() => {
    if (!user) {
      router.replace('/login');
    }
  }, [user, router]);

  useEffect(() => {
    onSearch(query, page, limit);
  }, [onSearch, query, page, limit]);

  useEffect(() => {
    onSetHeaderColor('white');
  }, [onSetHeaderColor]);

  const onCreateReview = useCallback(() => {
    router.push('/admin/reviews/new');
  }, [router]);

  const onFetchData = useCallback(({ page: nextPage, pageSize }) => {
    setSearch((last) => ({
      ...last,
      page: nextPage,
      limit: pageSize,
    }));
  }, []);

  return (
    <main className="view view--reviews">
      <div className="container page-title">
        <h1>Reviews</h1>
      </div>
      <div className="row--padded-m">
        <div className="container">
          <div className="clearfix">
            <TableSearch
              className="pull-left"
              label="Filter by supplier"
              input={query}
              onChange={onChangeQuery}
            />
            <Button
              className="pull-right"
              color="grey"
              text="+ Add new review"
              icon="filter"
              onClick={onCreateReview}
            />
          </div>
          <TableList
            pages={numPages}
            defaultPageSize={limit}
            data={reviews}
            columns={columns}
            manual
            onFetchData={onFetchData}
          />
        </div>
      </div>
    </main>
  );
}

Reviews.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  location: locationShape.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      supplier: PropTypes.string.isRequired,
      publicComment: PropTypes.string.isRequired,
      rating: PropTypes.string.isRequired,
      dateSubmitted: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      authenticated: PropTypes.bool.isRequired,
      published: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
  numPages: PropTypes.number.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSetHeaderColor: PropTypes.func.isRequired,
};

Reviews.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  user: state.user,
  reviews: getSearchResults(state),
  numPages: state.supplierReviews.numPages,
  loading: state.supplierReviews.loading,
});

const mapDispatchToProps = {
  onSearch: searched,
  onSetHeaderColor: setHeaderColor,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Reviews);
