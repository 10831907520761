import React from 'react';
import classNames from 'classnames';

import Spinner from './spinner';

export default function Button({
  text,
  color,
  onClick,
  disabled,
  isLoading,
  className,
  type = 'button',
  children,
  ...props
}) {
  return (
    <button
      type={type}
      disabled={disabled || isLoading}
      className={classNames('button', `button--${color}`, className, {
        'button--loading': isLoading,
      })}
      onClick={onClick}
      {...props}
    >
      <span className="button__spinner">
        <Spinner />
      </span>
      <span className="button__content">
        <span>{text || children}</span>
      </span>
    </button>
  );
}
