import { call } from 'redux-saga/effects';
import axios from 'axios';
import { store } from '../../index';
import { setUser } from '../actions/user';

import { properties } from '../../app-config';
import { withAuthHeader } from '../httpMiddleware/authorization';

export function* request({ url = '', ...requestOptions } = {}, options = {}) {
  const { auth = true } = options;

  let req = {
    ...requestOptions,
    url: `${properties.baseApiUri}${url}`,
  };

  if (auth) {
    req = yield call(withAuthHeader, req);
  }

  let res;

  try {
    res = yield call(axios, req);
  } catch (err) {
    if (err && err.response && err.response.status === 401) {
      store.dispatch(setUser(null));
      return null;
    }
  }
  return res.data;
}
