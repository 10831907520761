import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'formik';
import Asterisk from './asterisk';

const FormField = ({
  input,
  label,
  labelText,
  type,
  labelClassName = '',
  meta: { touched, error, dirty, pristine },
  className = '',
  disabled = false,
  serverError,
  required,
  ...props
}) => (
  <div className="field">
    <label
      htmlFor={label}
      className={classNames(
        'field__label',
        { 'field__label--touched': !pristine },
        labelClassName
      )}
    >
      {labelText || label}
      <Asterisk required={required} />
    </label>
    <div>
      {type === 'textarea' ? (
        <textarea
          disabled={disabled}
          id={label}
          className={classNames(
            'field__input',
            'form-control',
            { 'field__input--error': (touched && error) || serverError },
            className
          )}
          {...input}
          {...props}
        />
      ) : (
        <input
          disabled={disabled}
          id={label}
          className={classNames(
            'field__input',
            { 'field__input--error': (touched && error) || serverError },
            className
          )}
          {...input}
          type={type}
          {...props}
        />
      )}
      {((touched && error) || serverError) && (
        <span className="field__error">{error || serverError}</span>
      )}
    </div>
  </div>
);

export default FormField;

FormField.propTypes = {
  input: PropTypes.shape({}),
  label: PropTypes.string,
  labelText: PropTypes.string,
  type: PropTypes.string,
  labelClassName: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    dirty: PropTypes.bool,
    pristine: PropTypes.bool,
  }),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  serverError: PropTypes.string,
  required: PropTypes.bool,
};

FormField.defaultProps = {
  input: {},
  label: '',
  labelText: null,
  labelClassName: '',
  type: 'text',
  meta: {},
  className: '',
  disabled: false,
  serverError: null,
  required: false,
};

const FormikFieldBase = ({
  name,
  value,
  onBlur,
  onChange,
  label,
  formik: { errors, touched },
  ...props
}) => (
  <FormField
    input={{
      value,
      onBlur,
      onChange,
    }}
    meta={{
      touched: touched[name],
      error: errors[name],
      pristine: !touched[name],
    }}
    label={name}
    labelText={label}
    {...props}
  />
);

FormikFieldBase.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  formik: PropTypes.shape({
    errors: PropTypes.objectOf(PropTypes.string).isRequired,
    touched: PropTypes.objectOf(PropTypes.bool).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

export const FormikField = connect(FormikFieldBase);
