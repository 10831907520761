import { gql } from '@apollo/client';

export const RUN_ROBEX_MATCHING = gql`
  mutation runRobexMatching(
    $requestId: String!
    $callMethod: CallMethod!
    $sendToFreshsales: Boolean
    $robexOptions: RobexOptions
  ) {
    runRobexMatching(
      requestId: $requestId
      callMethod: $callMethod
      sendToFreshsales: $sendToFreshsales
      robexOptions: $robexOptions
    ) {
      suppliersNotified
      suppliers {
        uuid
        name
      }
      potentialSuppliers
    }
  }
`;
