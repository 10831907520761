import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'formik';
import Asterisk from './asterisk';

const FormSelect = ({
  input,
  label,
  labelText,
  required,
  options,
  meta: { touched, error },
  placeholder,
}) => (
  <div className="field select-field">
    <label
      htmlFor={label}
      className="field__label form-control-label"
      name={input.name}
    >
      {labelText || label}
      <Asterisk required={required} />
    </label>
    <Select
      name={input.name}
      value={input.value}
      searchable={false}
      clearable={false}
      options={options}
      placeholder={placeholder}
      onChange={(obj) => input.onChange(obj.value)}
      onBlur={input.onChange}
      required
    />
    {touched && error && <span className="field__error">{error}</span>}
  </div>
);

export default FormSelect;

FormSelect.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  label: PropTypes.string,
  labelText: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  placeholder: PropTypes.string,
};

FormSelect.defaultProps = {
  input: {},
  label: '',
  labelText: null,
  meta: {},
  required: false,
  placeholder: 'Select...',
};

function FormikSelectBase({
  name,
  value,
  label,
  formik: { errors, touched, setFieldValue },
  ...props
}) {
  const onChange = useCallback(
    (nextValue) => {
      if (typeof nextValue !== 'string') {
        return;
      }

      setFieldValue(name, nextValue);
    },
    [setFieldValue, name]
  );

  return (
    <FormSelect
      input={{
        name,
        value,
        onChange,
      }}
      meta={{
        touched: touched[name],
        error: errors[name],
      }}
      label={name}
      labelText={label}
      {...props}
    />
  );
}

FormikSelectBase.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  formik: PropTypes.shape({
    errors: PropTypes.objectOf(PropTypes.string).isRequired,
    touched: PropTypes.objectOf(PropTypes.bool).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

export const FormikSelect = connect(FormikSelectBase);
