import { useCallback } from 'react';

export function useOnClick(onClick, id = null, name = null) {
  const onActivate = useCallback(
    (event) => {
      event.stopPropagation();
      onClick(id, name);
    },
    [onClick, id, name]
  );

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        onActivate();
      }
    },
    [onActivate]
  );

  if (!onClick) {
    return {};
  }

  return { onClick: onActivate, onKeyDown };
}
