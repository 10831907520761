import {
  GET_ALL_REQUESTS,
  GET_REQUEST,
  UPDATE_REQUEST,
  CLOSE_REQUEST,
  DELETE_REQUEST,
  RUN_ROBEX,
  SEND_ALL_QUOTES_EMAIL,
} from '../constants/action-types';

export const fetchRequests = (page, pageSize, query = '') => ({
  type: GET_ALL_REQUESTS,
  page,
  pageSize,
  query,
});

export const fetchRequest = (requestId) => ({
  type: GET_REQUEST,
  requestId,
});

export const updateRequest = (requestId, data) => ({
  type: UPDATE_REQUEST,
  requestId,
  data,
});

export const closeRequest = (requestId) => ({
  type: CLOSE_REQUEST,
  requestId,
});

export const deleteRequest = (requestId) => ({
  type: DELETE_REQUEST,
  requestId,
});

export const runRobex = (requestId) => ({
  type: RUN_ROBEX,
  requestId,
});

export const sendAllQuotesEmail = (requestId) => ({
  type: SEND_ALL_QUOTES_EMAIL,
  requestId,
});
