import {
  SUPPLIER_REVIEW_SEARCHED,
  SUPPLIER_REVIEW_SEARCH_RECEIVED,
  SUPPLIER_REVIEW_CREATED,
  SUPPLIER_REVIEW_READ,
  SUPPLIER_REVIEW_UPDATED,
  SUPPLIER_REVIEW_DELETED,
  SUPPLIER_REVIEW_RECEIVED,
  SUPPLIER_REVIEW_ERRORED,
} from '../constants/action-types';

export const searched = (supplier = null, page = 0, limit = 10) => ({
  type: SUPPLIER_REVIEW_SEARCHED,
  supplier,
  page,
  limit,
});

export const searchReceived = (res) => ({
  type: SUPPLIER_REVIEW_SEARCH_RECEIVED,
  res,
});

export const created = (data) => ({
  type: SUPPLIER_REVIEW_CREATED,
  data,
});

export const read = (uuid) => ({
  type: SUPPLIER_REVIEW_READ,
  uuid,
});

export const updated = (uuid, data) => ({
  type: SUPPLIER_REVIEW_UPDATED,
  uuid,
  data,
});

export const deleted = (uuid) => ({
  type: SUPPLIER_REVIEW_DELETED,
  uuid,
});

export const received = (review = null) => ({
  type: SUPPLIER_REVIEW_RECEIVED,
  review,
});

export const errored = (err, metadata = {}) => ({
  type: SUPPLIER_REVIEW_ERRORED,
  err,
  metadata,
});
