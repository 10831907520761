import { createSelector } from 'reselect';
import format from 'date-fns/format';

const getReviewList = (state) => state.supplierReviews.list;

const getReviewItem = (state) => state.supplierReviews.item;

function getRating(review) {
  const values = Object.keys(review.ratings)
    .map((key) => review.ratings[key])
    .filter((value) => value !== null);

  if (!values.length) {
    return '-';
  }

  return values
    .reduce((last, value) => last + value / values.length, 0)
    .toFixed(1);
}

const processReview = (review) =>
  review && {
    ...review,
    supplier: review.supplier.name,
    publicComment: review.fields.publicComment,
    privateComment: review.fields.privateComment,
    rating: getRating(review),
    eventDate:
      review.externalEvent && review.externalEvent.eventDate
        ? format(new Date(review.externalEvent.eventDate), 'dd/MM/yyyy')
        : '',
    dateSubmitted: format(new Date(review.created), 'dd/MM/yyyy'),
  };

export const getSearchResults = createSelector(getReviewList, (items) =>
  items.map(processReview)
);

export const getReview = createSelector(getReviewItem, processReview);
