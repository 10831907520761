import PropTypes from 'prop-types';

export const getStateFromQuery = (props) => ({
  page: Number(props.location.query.page) || 0,
  limit: Number(props.location.query.limit) || 10,
  query: props.location.query.q || '',
});

export const locationShape = PropTypes.shape({
  query: PropTypes.shape({
    page: PropTypes.string,
    limit: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
});
