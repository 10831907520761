import { httpService } from '../../app-config';

export const uploadFile = (eid, file) => {
  const formData = new FormData();

  formData.append(file.name, file);

  return httpService.request({
    path: `/uploads/upload/${eid}`,
    method: 'POST',
    body: formData,
  });
};

export const deleteImage = (file) => (dispatch) => {
  const { eid, id, url } = file;

  const re = /([^/]+)\/?$/gim;

  const [fileName] = re.exec(url);

  return httpService.request({
    path: `/uploads/${eid}/${id}/${fileName}`,
    method: 'DELETE',
  });
};
