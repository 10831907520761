import { FETCH_SETTINGS, FETCH_SETTING } from '../constants/action-types';

const settingsInitialState = {
  commission_fee: '',
  service_fee: '',
  low_price_end: '',
  middle_price_start: '',
  middle_price_end: '',
  high_price_start: '',
};
const settingInitialState = {
  options_name: null,
  options_value: null,
};

export function settings(state = settingsInitialState, action) {
  const values = {};
  switch (action.type) {
    case FETCH_SETTINGS:
      action.payload.map(
        (setting) => (values[setting.option_name] = setting.option_value)
      );
      return values;
    default:
      return state;
  }
}

export function setting(state = settingInitialState, action) {
  switch (action.type) {
    case FETCH_SETTING:
      return action.payload;
    default:
      return state;
  }
}
