import { LOCATION_CHANGE } from 'react-router-redux';
import { TOGGLE_HEADER_DROPDOWN } from '../constants/action-types';

export default function (state = false, action) {
  switch (action.type) {
    case TOGGLE_HEADER_DROPDOWN:
      return action.payload;
    case LOCATION_CHANGE:
      return false;
    default:
      return state;
  }
}
