import { gql } from "@apollo/client";

export const EVENT_QUERY = gql`
  query eventRequest($eventId: String!) {
    result: events(where: { id: { equals: $eventId } }) {
      date
      id
      uuid
      type
      location_line_1
      location_line_2
      location_city
      postcode
      start_time
      end_time
      users {
        firstName
        id
      }
      custom_event_name
      high_demand
      urgency
      event_requests {
        quotes {
          state
        }
      }
      event_areas {
        area
      }
    }
  }
`;
