import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'just-debounce';
import { Link, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { setUser } from '../../actions/user';
import setHeaderColor from '../../actions/header';
import { fetchSuppliers } from '../../actions/suppliers';
import TableSearch from '../../components/table-search';
import Button from '../../components/button';
import TableList from '../../components/table-list';

import { getStateFromQuery, locationShape } from '../../libs/pagination';

class Suppliers extends Component {
  static propTypes = {
    numPages: PropTypes.number.isRequired,
    location: locationShape.isRequired,
  };

  state = {
    isLoading: false,
    ...getStateFromQuery(this.props),
  };

  componentWillMount() {
    this.props.setHeaderColor('white');
    this.update(this.props);
    this.loadSuppliers();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user === null) {
      this.update(nextProps);
    }
  }

  update = (props) => {
    const { user, router } = props;

    if (user == null) {
      router.replace('/login');
    }
  };

  loadSuppliers = debounce(() => {
    this.setState({ isLoading: true });
    this.props
      .fetchSuppliers(this.state.page, {
        limit: this.state.limit,
        name: this.state.query,
      })
      .then(() => this.setState({ isLoading: false }))
      .catch((err) => {
        if (err.status === 401) {
          this.props.setUser(null);
        }
      });
  }, 500);

  onFetchData = (state) =>
    this.setState(
      {
        page: state.page,
        limit: state.pageSize,
      },
      this.loadSuppliers
    );

  changeQuery = (query) => {
    this.setState({ query, page: 0 }, this.loadSuppliers);
  };

  newSupplierClick = () => {
    this.props.router.push('/admin/suppliers/new');
  };

  render() {
    const { suppliers } = this.props;

    const columns = [
      {
        name: 'name',
        title: 'Supplier Name',
      },
      {
        name: 'favourites',
        title: 'Favourited',
        cell: (props) => (props.value ? props.value.length : null),
      },
      {
        name: 'uuid',
        title: 'Calendar',
        width: 140,
        cell: (props) => (
          <Link
            className=""
            to={`/admin/suppliers/${props.value}/calendar`}
            activeClassName="t--bold"
          >
            <Button color="grey-light" text="Manage" />
          </Link>
        ),
      },
      {
        name: 'uuid',
        title: 'Manage',
        width: 140,
        cell: (props) => {
          const url = `/admin/suppliers/${props.value}`;

          return (
            <Link className="" to={url} activeClassName="t--bold">
              <button className="button button--green pull-right">Edit</button>
            </Link>
          );
        },
      },
    ];
    return (
      <main className="view view--bookings">
        <div className="container page-title">
          <h1>Suppliers</h1>
        </div>
        <div className="row--padded-m">
          <div className="container">
            <div className="clearfix">
              <TableSearch
                className="pull-left"
                label="Search by name"
                input={this.state.query}
                onChange={this.changeQuery}
              />
              <Button
                className="pull-right"
                color="grey"
                text="+ Add new supplier"
                icon="filter"
                onClick={this.newSupplierClick}
              />
            </div>
            <TableList
              pages={Math.ceil(this.props.totalRecords / this.state.limit)}
              defaultPageSize={this.state.limit}
              data={suppliers}
              columns={columns}
              manual
              onFetchData={this.onFetchData}
              page={this.state.page}
            />
          </div>
        </div>
      </main>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUser,
      setHeaderColor,
      fetchSuppliers,
    },
    dispatch
  );
}

const mapStateToProps = ({ suppliers, user }) => ({
  suppliers: suppliers.items,
  totalRecords: suppliers.totalRecords,
  user,
});

export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(Suppliers)
);
