import * as Yup from 'yup';

export const schema = Yup.object().shape({
  partnerName: Yup.string().required('Required'),
  postcode: Yup.string().required('Required'),
  salesforceOpportunityId: Yup.string()
    .nullable()
    .trim()
    .length(18, 'Must be 18 characters')
    .matches(/^006/, { message: `Invalid format. Must start with '006'.`, excludeEmptyString: true })
    .notRequired(),
  slug: Yup.string().required(),
  locationLine1: Yup.string(),
  locationLine2: Yup.string(),
  power: Yup.boolean(),
  water: Yup.boolean(),
  commission: Yup.number()
    .min(0, 'Cannot be negative')
    .required('Required')
    .nullable(),
});
