import React from 'react';

import BookingField from './booking-field';

export default function SEODataFields(props) {
  const utmParams =
    typeof props.utmParams === 'string'
      ? JSON.parse(props.utmParams)
      : props.utmParams;

  if (props.referrer || utmParams) {
    return (
      <div className="u-margin-bottom">
        <div className="col-md-12">
          <h3 className="col-md-12">{`${props.type} source`}</h3>
        </div>

        <div className="col-md-12">
          {props.referrer.length ? (
            <div className="col-md-4">
              {props.referrer.map((source, index) => (
                <>
                  <div className="col-md-1">
                    <BookingField label="Visit" value={`${index + 1}.`} />
                  </div>
                  <div className="col-md-11">
                    <BookingField label="referrer" value={source} />
                  </div>
                </>
              ))}
            </div>
          ) : null}

          {Object.keys(utmParams).map((key) => (
            <div className="col-md-2" key={key}>
              <BookingField label={`UTM ${key}`} value={utmParams[key]} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
}
