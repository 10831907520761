import { gql } from '@apollo/client';

export const REQUEST_WITH_EVENT_BY_ID = gql`
  query eventRequests($request_id: String!) {
    result: eventRequests(where: { id: { equals: $request_id } }) {
      uuid
      id
      open
      item_quantity
      description
      admin_note
      created
      preferred_supplier_names
      completed
      accepting_quotes
      budget
      budget_flexible
      category_tier2
      all_quotes_email_sent
      freshsales_stage
      freshsales_id
      event_request_supplier_notified {
        suppliers {
          name
        }
      }
      declined_event_requests {
        supplier_id
        declined_reason
        supplier_name
      }
      additional_info {
        courses
        children
        waiters
        water
        crockery
        power
        bar_type
        supplier_setup
      }
      categories_tier2 {
        sys_name
        name
        parent
      }
      event_request_tags {
        tags_event_request_tags_tag_uuidTotags {
          name
          uuid
          root_tags {
            key
          }
        }
      }
      quotes {
        uuid
        total
        commission_fee
        service_fee
        covers
        state
        published
        emailSent
        internalNote
        supplier_id
        old_commission
      }
      events {
        id
        date
        start_time
        end_time
        date_flexible
        dateFlexibilityNotes
        location_city
        location_line_1
        location_line_2
        location_notes
        postcode
        partnership_id
        event_areas {
          area
        }
        event_tags {
          tags {
            name
          }
        }
        users {
          id
          firstName
          lastName
          email
          phone
        }
      }
      conversations {
        uuid
        suppliers {
          uuid
          suppliers_users {
            user_id
          }
        }
      }
      quote_limited
      quote_limited_source
    }
  }
`;
