export const paths = {
  IMPERSONATE_USER: (authorizationToken, redirect = '') =>
    `${process.env.REACT_APP_PLATFORM_URI}/impersonate/${authorizationToken}${
      redirect ? `?redirect=/${redirect.replace(/^(\/)+/, '')}` : ''
    }`,
  IMPERSONATE_USER_V2: (adminToken, userId, redirect) =>
    `${
      process.env.REACT_APP_PLATFORM_URI
    }/impersonateV2?userId=${userId}&adminToken=${adminToken}${
      redirect && `&redirect=${redirect}`
    }`,
  USER: (userId) => `/admin/users/${userId}`,
  LOGIN: `${window.location.origin}/login`,
  LOGOUT: `${window.location.origin}/logout`,
};
