import React from 'react';
import { Field, reduxForm, propTypes } from 'redux-form';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  updateSupplierCalendarItem,
  createSupplierCalendarItem,
} from '../../actions/suppliers';
import Button from '../../components/button';
import FormSelect from '../../components/form-select';
import FormField from '../../components/form-field';

import { ReactComponent as CloseIcon } from '../../../images/sprite/ui/close.svg';

const STATES = [
  { value: 'unavailable', label: 'Unavailable' },
  { value: 'booked', label: 'Booked' },
  { value: 'available', label: 'Available' },
  { value: 'ask', label: 'Ask' },
];

const validate = ({ startTime, endTime }) => {
  const errors = {};

  if (new Date(startTime) > new Date(endTime)) {
    errors.startTime = 'Start date must be the same or before the end date';
  }

  return errors;
};

const required = (value) => (value ? undefined : 'Required');

const handleOnSubmit = (formData, dispatch) => {
  const { supplierId, uuid, title } = formData;

  const data = {
    title: typeof title === 'string' ? title : '',
    startTime: moment(formData.startTime).startOf('day').toDate(),
    endTime: moment(formData.endTime).endOf('day').toDate(),
    state: formData.state,
  };

  if (uuid) {
    dispatch(updateSupplierCalendarItem(supplierId, uuid, data));
  } else {
    dispatch(createSupplierCalendarItem(supplierId, data));
  }
};
const handleOnSubmitSuccess = (result, dispatch, props) => props.closeForm();

const CalendarItem = ({
  handleSubmit,
  submitting,
  initialValues,
  handleDelete,
  closeForm,
}) => (
  <form onSubmit={handleSubmit}>
    <div className="col-md-2">
      <button className="mobile-menu__close" type="button" onClick={closeForm}>
        <CloseIcon className="icon--close" />
      </button>

      <div className="calendar-form">
        {initialValues.uuid && <h3>Update/Delete Event</h3>}
        {!initialValues.uuid && <h3>Add Event</h3>}

        <Field
          name="title"
          component={FormField}
          className="form-control"
          label="Title"
          labelClassName="form-control-label"
        />

        <Field
          name="state"
          component={FormSelect}
          className="form-control"
          label="State"
          labelClassName="form-control-label"
          options={STATES}
          validate={required}
          required
        />

        <Field
          name="startTime"
          component={FormField}
          className="form-control"
          type="date"
          label="Date"
          labelClassName="form-control-label"
          validate={required}
          required
        />

        <Field
          name="endTime"
          component={FormField}
          className="form-control"
          type="date"
          label="End Date"
          labelClassName="form-control-label"
          validate={required}
          required
        />

        {!initialValues.uuid ? (
          <Button
            text="Add"
            color="green"
            type="submit"
            isLoading={submitting}
          />
        ) : (
          <div className="calendar-buttons--group">
            <Button
              text="Update"
              color="grey-light"
              type="submit"
              isLoading={submitting}
            />
            <Button
              text="Delete"
              color="red"
              onClick={() => handleDelete(initialValues.uuid)}
            />
          </div>
        )}
      </div>
    </div>
  </form>
);

CalendarItem.propTypes = {
  ...propTypes,
  handleDelete: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'calendar-item',
  enableReinitialize: true,
  onSubmit: handleOnSubmit,
  validate,
  onSubmitSuccess: handleOnSubmitSuccess,
})(CalendarItem);
