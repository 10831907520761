import PropTypes from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import format from 'date-fns/format';
import locale from 'date-fns/locale/en-GB';
import { connect } from 'formik';
import classNames from 'classnames';

function DatePicker({
  className,
  dateFormat,
  placeholder,
  value,
  onChange,
  minDate,
  maxDate,
  ...otherProps
}) {
  const onChangeValue = useCallback(
    (val) => onChange(format(val, dateFormat)),
    [onChange, dateFormat]
  );

  const selectedValue = useMemo(() => {
    try {
      const parsedValue = parse(value, dateFormat, new Date());
      if (parsedValue && isValid(parsedValue)) {
        return parsedValue;
      }

      return null;
    } catch {
      return null;
    }
  }, [value, dateFormat]);

  return (
    <div className={classNames('datepicker', className)}>
      <ReactDatePicker
        fixedHeight
        selected={selectedValue}
        onChange={onChangeValue}
        dateFormat={dateFormat}
        className="search__input"
        locale={locale}
        minDate={minDate}
        maxDate={maxDate}
        placeholderText={placeholder}
        customInput={<input aria-label={placeholder} />}
        {...otherProps}
      />
    </div>
  );
}

DatePicker.defaultProps = {
  className: '',
  placeholder: 'Click to select a date',
  dateFormat: 'dd/MM/yyyy',
  minDate: new Date(),
  maxDate: null,
  value: '',
};

DatePicker.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  value: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
};

export default DatePicker;

function FormDatePickerBase({
  name,
  formik: { setFieldValue, touched, errors },
  ...props
}) {
  const onChange = useCallback(
    (value) => setFieldValue(name, value),
    [name, setFieldValue]
  );

  return (
    <div>
      <DatePicker {...props} name={name} onChange={onChange} />
      {touched[name] && errors[name] && (
        <span className="field__error">{errors[name]}</span>
      )}
    </div>
  );
}

FormDatePickerBase.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.shape({
    errors: PropTypes.objectOf(PropTypes.string).isRequired,
    touched: PropTypes.objectOf(PropTypes.bool).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

export const FormDatePicker = connect(FormDatePickerBase);
