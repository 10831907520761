import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import format from 'date-fns/format';
import { Formik, Form, Field } from 'formik';
import Select from 'react-select';
import RatingsField from '../../components/form-ratings';
import FormField, { FormikField } from '../../components/form-field';
import { FormDatePicker } from '../../components/date-picker';
import { FormikSelect } from '../../components/form-select';

import SupplierField from '../partials/supplier-field';

import { FormikCheckbox } from '../../components/form-checkbox';

import { eventTypes, createSchema, updateSchema } from './schema/reviews';

const eventTypeOptions = eventTypes.map((value) => ({ value, label: value }));

export default function ReviewForm({ isNew, data, onSave, onDelete }) {
  const externalEvent = data.externalEvent || {};

  const { booking } = data;
  const { customer } = booking || {};

  let userEmail = '';
  let customerName = '';
  let eventDate = data.eventDate || '';
  let eventType = '';

  if (data.source === 'external' || data.source === 'admin') {
    eventType = externalEvent.eventType || '';
    userEmail = externalEvent.userEmail || '';
    customerName = externalEvent.userFullName || '';
    eventDate = externalEvent.eventDate || eventDate;
  } else if (booking) {
    eventDate = booking.date || '';
    eventType = booking.type || '';
    userEmail = customer.email || '';
    customerName = `${customer.firstName || ''} ${customer.lastName || ''}`;
  }

  const validationSchema = isNew ? createSchema : updateSchema;

  const onSubmit = useCallback(
    (rawValues) => {
      const values = validationSchema.cast(rawValues);

      onSave({
        supplierId: isNew ? values.supplierId : data.supplierId,
        published: values.published,
        authenticated: isNew ? true : data.authenticated,
        source: isNew ? 'admin' : data.source,
        externalEvent: {
          eventType: values.eventType,
          eventDate: isNew
            ? format(values.eventDate, 'yyyy-MM-dd')
            : values.eventDate,
          userFullName: values.customerName,
          userEmail,
        },
        ratings: {
          valueForMoney: values.ratingValue,
          quality: values.ratingQuality,
          professionalism: values.ratingProfessionalism,
          friendliness: values.ratingFriendliness,
        },
        fields: {
          publicComment: values.publicComment,
          privateComment: isNew
            ? values.privateComment
            : data.fields.privateComment,
        },
      });
    },
    [
      data.authenticated,
      data.fields.privateComment,
      data.source,
      data.supplierId,
      isNew,
      onSave,
      userEmail,
      validationSchema,
    ]
  );

  const onDeleteCallback = useCallback(() => {
    if (data.uuid) {
      onDelete(data.uuid);
    }
  }, [onDelete, data.uuid]);

  const initialValues = useMemo(
    () => ({
      supplierId: null,
      published: Boolean(data.published),
      publicComment: data.fields.publicComment || '',
      privateComment: '',
      customerName,
      eventType,
      eventDate,
      ratingValue: (data.ratings && data.ratings.valueForMoney) || null,
      ratingQuality: (data.ratings && data.ratings.quality) || null,
      ratingProfessionalism:
        (data.ratings && data.ratings.professionalism) || null,
      ratingFriendliness: (data.ratings && data.ratings.friendliness) || null,
    }),
    [
      customerName,
      data.fields.publicComment,
      data.published,
      data.ratings,
      eventDate,
      eventType,
    ]
  );
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="row">
            <div className="col-md-6">
              <Field name="published" as={FormikCheckbox} />
              {isNew && (
                <Field
                  name="supplierId"
                  as={SupplierField}
                  label="supplier"
                  labelText="Supplier"
                />
              )}
              {!isNew && (
                <div className="field field--supplier select-field">
                  <label className="field__label form-control-label">
                    Supplier
                  </label>
                  <Select
                    value={data.supplier}
                    searchable={false}
                    clearable={false}
                    disabled
                    options={[{ value: data.supplier, label: data.supplier }]}
                  />
                </div>
              )}
              <div className="field field--public-comment">
                <label
                  className="field__label form-control-label"
                  htmlFor="publicComment"
                >
                  Customer review
                </label>
                <Field
                  name="publicComment"
                  as="textarea"
                  className={classNames('field__input', 'form-control', {
                    'field__input--error':
                      errors.publicComment && touched.publicComment,
                  })}
                />
                {errors.publicComment && touched.publicComment && (
                  <div className="field__error">{errors.publicComment}</div>
                )}
              </div>
              <div className="field field--rating field--rating-value">
                <label
                  className="field__label form-control-label"
                  htmlFor="ratingValue"
                >
                  Value for money
                </label>
                <Field name="ratingValue" as={RatingsField} />
              </div>
              <div className="field field--rating field--rating-quality">
                <label
                  className="field__label form-control-label"
                  htmlFor="ratingQuality"
                >
                  Quality
                </label>
                <Field name="ratingQuality" as={RatingsField} />
              </div>
              <div className="field field--rating field--rating-professionalism">
                <label
                  className="field__label form-control-label"
                  htmlFor="ratingProfessionalism"
                >
                  Professionalism
                </label>
                <Field name="ratingProfessionalism" as={RatingsField} />
              </div>
              <div className="field field--rating field--rating-friendliness">
                <label
                  className="field__label form-control-label"
                  htmlFor="ratingFriendliness"
                >
                  Friendliness
                </label>
                <Field name="ratingFriendliness" as={RatingsField} />
              </div>
            </div>
            <div className="col-md-6">
              {!isNew && (
                <div className="field-group">
                  <div
                    className={classNames('field', 'field--status', {
                      authenticated: data.authenticated,
                    })}
                  >
                    <label className="field__label form-control-label">
                      Status
                    </label>
                    {data.authenticated ? 'Verified' : 'Unverified'}
                  </div>
                  <div className={classNames('field', 'field--source')}>
                    <label className="field__label form-control-label">
                      Source
                    </label>
                    {data.source}
                  </div>
                </div>
              )}
              {isNew && (
                <div>
                  <Field
                    name="eventType"
                    label="Event type"
                    as={FormikSelect}
                    options={eventTypeOptions}
                    placeholder="Select event type..."
                  />
                  <Field
                    name="customerName"
                    label="Customer name"
                    as={FormikField}
                    className="form-control"
                    labelClassName="form-control-label"
                  />
                </div>
              )}
              {!isNew && (
                <div>
                  <label className="field__label form-control-label">
                    Event date
                  </label>
                  <input readOnly value={eventDate} />
                  <br />

                  <label className="field__label form-control-label">
                    Customer name{' '}
                  </label>
                  <input value={customerName} />
                  <br />

                  <label className="field__label form-control-label">
                    Event type{' '}
                  </label>
                  <input value={eventType} />
                </div>
              )}

              {!isNew && (
                <FormField
                  className="form-control"
                  label="Customer email address"
                  labelClassName="form-control-label"
                  value={userEmail}
                  disabled
                />
              )}

              {!isNew && (
                <FormField
                  className="form-control"
                  label="Date submitted"
                  labelClassName="form-control-label"
                  value={data.dateSubmitted}
                  disabled
                />
              )}
              {isNew && (
                <Field
                  name="privateComment"
                  as={FormikField}
                  label="Private comment"
                  labelClassName="form-control-label"
                  type="textarea"
                />
              )}
              {!isNew && (
                <FormField
                  className="form-control"
                  label="Private comment"
                  labelClassName="form-control-label"
                  type="textarea"
                  value={data.fields.privateComment || ''}
                  disabled
                />
              )}
              {isNew && (
                <Field
                  name="eventDate"
                  label="Date of event"
                  as={FormDatePicker}
                  className="form-control"
                  labelClassName="form-control-label"
                  minDate={null}
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-buttons">
              <button type="submit" className="button button--blue">
                Save
              </button>
              {data.uuid && (
                <button
                  type="button"
                  className="button button--red"
                  onClick={onDeleteCallback}
                >
                  Delete review
                </button>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

ReviewForm.propTypes = {
  isNew: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    uuid: PropTypes.string,
    supplier: PropTypes.string,
    supplierId: PropTypes.string,
    published: PropTypes.bool,
    authenticated: PropTypes.bool,
    source: PropTypes.string,
    fields: PropTypes.shape({
      publicComment: PropTypes.string,
      privateComment: PropTypes.string,
    }).isRequired,
    externalEvent: PropTypes.shape({
      userFullName: PropTypes.string,
      userEmail: PropTypes.string,
      eventType: PropTypes.string,
      eventDate: PropTypes.string,
    }),
    ratings: PropTypes.shape({
      valueForMoney: PropTypes.number,
      quality: PropTypes.number,
      professionalism: PropTypes.number,
      friendliness: PropTypes.number,
    }),
    eventDate: PropTypes.string,
    dateSubmitted: PropTypes.string.isRequired,
    booking: PropTypes.shape({
      customer: PropTypes.string,
      date: PropTypes.string,
      type: PropTypes.string,
    }),
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
