import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
  toPounds,
  toPennies,
  calculateCommissionFromTotal,
} from '@feast-it/finance';
import {
  formatCurrency,
  replaceLinebreaksWithSpaces,
} from '../../libs/formatting';
import { calculateCostBreakdown } from '../../libs/finance';
import { quoteShape } from '../../prop-types/quotes';
import { fetchQuote, updateQuote, deleteQuote } from '../../actions/quotes';
import { fetchSupplier } from '../../actions/suppliers';
import { fetchPartnership } from '../../actions/partnerships';
import { setEvent, resetEvent } from '../../actions/event';
import formField from '../../components/form-field';
import formCheckbox from '../../components/form-checkbox';
import Button from '../../components/button';
import TextEditor from '../../components/text-editor';
import { client as apolloClient } from '../../modules/apollo/apollo';
import { DELETE_QUOTE } from '../../mutations';
import { captureError } from '../../modules/monitoring';

const handleOnSubmit = (data, dispatch, props) => {
  const {
    event,
    params: { requestId },
  } = props;
  const supportText = replaceLinebreaksWithSpaces(
    data.supportText ? data.supportText : ''
  );

  // remove unneeded data to send to update
  const {
    created,
    supplier,
    declined_reason,
    uuid,
    is_excl_vat_price,
    ...updateData
  } = data;

  const { baseTotal } = calculateCostBreakdown({
    total: toPounds(data.total),
    service_fee: data.service_fee,
    commission: data.commission_fee,
    old_commission: data.old_commission,
  });

  const quote = {
    ...updateData,
    dietary: {
      tags: event.dietaryItems,
      notes: updateData.dietary ? updateData.dietary.notes : '',
    },
    supportText,
    internalNote: updateData.internalNote ? updateData.internalNote : '',
    published: data.published === true,
    total: Math.round(data.old_commission ? data.total : baseTotal),
  };

  delete quote.promotions;

  return dispatch(updateQuote(uuid, requestId, quote));
};

const handleOnSubmitSuccess = (result, dispatch, props) =>
  props.router.push(`/admin/requests/${props.params.requestId}`);

class QuoteForm extends Component {
  constructor() {
    super();
    this.state = {
      deleteQuoteError: '',
    };
  }

  componentWillMount() {
    this.loadQuote();
  }

  handleDeleteQuoteClick = async () => {
    const { requestId, quoteId } = this.props.params;

    if (
      window.confirm(
        'Are you sure you want to delete this quote? If not, please speak to an admin.'
      )
    ) {
      try {
        await apolloClient.mutate({
          mutation: DELETE_QUOTE,
          variables: { quoteId },
        });

        this.props.router.push(`/admin/requests/${requestId}`);
      } catch (deleteQuoteError) {
        captureError(deleteQuoteError);
        this.setState({ deleteQuoteError });
      }
    }
  };

  loadPartnership() {
    if (this.props.quote && this.props.quote.eventRequest) {
      const { event } = this.props.quote.eventRequest;
      if (event && event.partnership_id) {
        this.props
          .fetchPartnership(event.partnership_id)
          .catch((err) => console.log('failure loading partnership'));
      }
    }
  }

  loadQuote() {
    const { quoteId } = this.props.params;
    this.props.fetchQuote(quoteId).then(() => {
      this.loadSupplier();
      this.loadPartnership();
    });
  }

  loadSupplier() {
    const { supplier_id: id } = this.props.quote;
    this.props.fetchSupplier(id);
  }

  render() {
    const {
      handleSubmit,
      error,
      submitting,
      supplier,
      quote,
      formState,
      eventDetails,
      params: { requestId },
      partnership,
    } = this.props;

    const { total, service_fee, commission_fee, covers } = formState;
    const {
      traderValue,
      commissionValue,
      baseTotal,
      serviceValue,
      customerTotal,
    } = calculateCostBreakdown({
      total: toPounds(total),
      service_fee,
      commission: commission_fee,
      old_commission: quote.old_commission,
    });

    const perHead = Number(covers) ? baseTotal / covers : 0;
    const isLinkedToPartnership =
      quote.eventRequest &&
      quote.eventRequest.event &&
      quote.eventRequest.event.partnership;

    return (
      <div className="edit-quote-form">
        <div className="container">
          <div className="clearfix">
            <Link
              className="pull-left"
              to={`/admin/requests/${requestId}`}
              style={{ margin: '10px 0' }}
            >
              <button className="button button--grey-light" type="button">
                &laquo; Back to Request
              </button>
            </Link>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="container">
          <div className="row">
            <div className="col-md-6">
              <Field
                name="published"
                className="form-control"
                component={formCheckbox}
                label="Published"
                labelClassName="form-control-label"
              />
              <Field
                name="covers"
                type="number"
                className="form-control"
                component={formField}
                label="Covers"
                required
                labelClassName="form-control-label"
                min={0}
                max={100000}
              />
              <Field
                name="total"
                type="number"
                component={formField}
                className="form-control"
                label="Total price (not including commission or fees)"
                labelClassName="form-control-label"
                step={0.01}
                min={0}
                required
                format={toPounds}
                parse={toPennies}
              />
              <Field
                name="commission_fee"
                type="number"
                component={formField}
                className="form-control"
                label="Commission Fee (OPTIONAL - DEFAULT 12.5%)"
                labelClassName="form-control-label"
                min={0}
                step="any"
              />
              <Field
                name="service_fee"
                type="number"
                component={formField}
                className="form-control"
                label="Service Fee (OPTIONAL - DEFAULT 2.5%)"
                labelClassName="form-control-label"
                min={0}
                step="any"
              />
              <Field
                name="supportText"
                type="textarea"
                component={TextEditor}
                className="form-control"
                label="Quote section text"
                labelClassName="form-control-label"
              />
            </div>
            <div className="col-md-6">
              <div className="field">
                <label
                  htmlFor="Supplier name"
                  className="field__label form-control-label"
                >
                  Supplier name
                </label>
                <input
                  disabled
                  type="text"
                  value={supplier.name || ''}
                  className="field__input form-control"
                />
              </div>
              {eventDetails.partnership && (
                <div className="field">
                  <label
                    htmlFor="Partner name"
                    className="field__label form-control-label"
                  >
                    Partner name
                  </label>
                  <input
                    disabled
                    type="text"
                    value={eventDetails?.partnershipData?.name || ''}
                    className="field__input form-control"
                  />
                </div>
              )}
              <Field
                name="supplier_id"
                disabled
                type="text"
                className="form-control"
                component={formField}
                label="Supplier id"
                labelClassName="form-control-label"
              />
              <label
                htmlFor="Menu Price"
                className="field__label form-control-label form-control-label--prices"
              >
                Price Per Head
              </label>
              <span className="basket-item__name">{`${formatCurrency(
                toPounds(perHead)
              )}`}</span>

              <label
                htmlFor="Commission Value"
                className="field__label form-control-label form-control-label--prices"
              >
                Commission Value
              </label>
              <span className="basket-item__name">{`${formatCurrency(
                toPounds(commissionValue)
              )}`}</span>

              <label
                htmlFor="Service Value"
                className="field__label form-control-label form-control-label--prices"
              >
                Service Value
              </label>
              <span className="basket-item__name">{`${formatCurrency(
                toPounds(serviceValue)
              )}`}</span>

              <label
                htmlFor="Supplier's Fee"
                className="field__label form-control-label form-control-label--prices"
              >
                Supplier&#39;s Fee
              </label>
              <span className="basket-item__name">{`${formatCurrency(
                toPounds(traderValue)
              )}`}</span>

              <label
                htmlFor="Customer's Price"
                className="field__label form-control-label form-control-label--prices"
              >
                Customer&#39;s Price
              </label>
              <span className="basket-item__name">{`${formatCurrency(
                toPounds(customerTotal)
              )}`}</span>

              <label
                htmlFor="Note to Togather"
                className="field__label form-control-label"
              >
                Note to Togather
              </label>
              <span className="basket-item__name">{quote.internalNote}</span>

              <label
                htmlFor="Partnership Information"
                className="field__label form-control-label"
              >
                Partnership Information
              </label>
              {isLinkedToPartnership ? (
                <>
                  <span className="basket-item__name">
                    {`Partner venue linked with Commission: ${partnership.commission}%`}
                  </span>
                  <Link
                    className="pull-right"
                    to={`/admin/partnerships/${quote.eventRequest.event.partnership_id}`}
                    style={{ margin: '10px 0' }}
                  >
                    <Button color="green" type="button">
                      View/Edit Partnership
                    </Button>
                  </Link>
                </>
              ) : (
                <span className="basket-item__name">
                  This quote is not linked to a partner venue.
                </span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="edit-quote-form__buttons col-md-12 action-bar">
              <Button
                isLoading={submitting}
                type="submit"
                color="blue"
                text="Save"
                icon="save"
              />
              <Button
                color="red"
                text="Delete Quote"
                onClick={() => this.handleDeleteQuoteClick()}
              />
              {this.state.deleteQuoteError && (
                <p>
                  <span className="form-error">
                    {this.state.deleteQuoteError}
                  </span>
                </p>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const matchDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSupplier,
      setEvent,
      fetchQuote,
      resetEvent,
      deleteQuote,
      fetchPartnership,
    },
    dispatch
  );

const mapStateToProps = ({
  supplier,
  tag,
  event,
  quote,
  eventDetails,
  partnership,
  ...state
}) => {
  const traderValue = quote.old_commission
    ? quote.total
    : quote.total -
      calculateCommissionFromTotal(quote.total, quote.commission_fee);

  return {
    formState: getFormValues('quoteEdit')(state),
    initialValues: { ...quote, total: traderValue },
    quote,
    event,
    supplier,
    tag,
    eventDetails,
    partnership,
  };
};

QuoteForm.propTypes = {
  quote: quoteShape.isRequired,
  formState: PropTypes.shape({
    total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    service_fee: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    commission_fee: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    covers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  supplier: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    dietary: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  error: PropTypes.shape({}),
  handleSubmit: PropTypes.func.isRequired,
  resetEvent: PropTypes.func.isRequired,
  fetchQuote: PropTypes.func.isRequired,
  deleteQuote: PropTypes.func.isRequired,
  setEvent: PropTypes.func.isRequired,
  params: PropTypes.shape({ quoteId: PropTypes.string }).isRequired,
  fetchSupplier: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

QuoteForm.defaultProps = {
  error: null,
  formState: {},
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(
  reduxForm({
    form: 'quoteEdit',
    validate: () => ({}),
    onSubmit: handleOnSubmit,
    onSubmitSuccess: handleOnSubmitSuccess,
    enableReinitialize: true,
  })(QuoteForm)
);
