/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { unflatten } from 'flat';

import Button from '../../components/button';
import SupplierTags from '../partials/supplier-tags';
import SupplierTypes from '../partials/supplier-types';
import SupplierRecommendations from '../partials/supplier-recommendations';
import SupplierVerticals from '../partials/supplier-verticals';
import SupplierUsers from '../partials/supplier-users';

import SupplierFields, { isPostcodeRequired } from './supplier-fields';
import { SupplierPermissions } from '../partials/supplier-permissions';

const selector = formValueSelector('supplier-form');

const validations = {
  truthy: (value) => Boolean(value),
  defined: (value) => typeof value !== 'undefined',
  number: (value) =>
    value !== null && String(value).length && !Number.isNaN(Number(value)),
  between: (min, max) => (value) => {
    const numericValue = Number(value);

    return numericValue >= min && numericValue <= max;
  },
  integer: (value) => /^\d+$/.test(String(value)),
};

const composeValidators =
  (...validators) =>
  (value) =>
    validators.every((validator) => validator(value));

const numberMessage = 'Is not a valid number';

const requiredFields = (values) =>
  [
    ['name'],
    ['public.logistics.radius', validations.defined],
    ['public.logistics.radius', validations.integer, numberMessage],
    ['public.basePrice', validations.number, numberMessage],
    [
      'public.commission',
      composeValidators(validations.number, validations.between(0, 100)),
      'Must be a number between 0 and 100',
    ],
    isPostcodeRequired(values) && ['public.logistics.postcode'],
    ['public.logistics.minSpend', validations.number, numberMessage],
  ].filter((validation) => validation);

const validateSupplierForm = (values) =>
  requiredFields(values).reduce(
    (
      last,
      [keyPath, validation = validations.truthy, message = 'Required']
    ) => {
      const keys = keyPath.split('.');

      const errors = unflatten(
        keys.reduce(
          ([lastErrors, lastValues], key, index) => {
            if (index === keys.length - 1) {
              if (!validation(lastValues[key])) {
                return { ...lastErrors, [keyPath]: message };
              }

              return lastErrors;
            }

            return [lastErrors, lastValues[key]];
          },
          [last, values]
        )
      );

      return errors;
    },
    {}
  );

class SupplierForm extends Component {
  state = {
    new: !this.props.data.uuid,
    saved: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.data.uuid) {
      this.setState({ new: false });
    }
  }

  render() {
    const {
      submitting,
      submitSucceeded,
      submitFailed,
      data,
      handleSubmit,
      cancelClick,
      deleteClick,
      categoryTier1,
      tier2,
      products,
      supplierTags,
      supplierTypes,
      supplierImages,
      supplierUsers,
      setUploadedFile,
      updateVerticals,
      updateTags,
      updateTypes,
      recommendations,
      handleStateChange,
      addRecommendation,
      updateRecommendation,
      deleteRecommendation,
      addUser,
      searchUsers,
      removeUser,
      userSearchResults,
      usersLoading,
      stateValue,
      showSuccess,
      errors,
      setProfilePicture,
      profilePicture,
      removeProfilePicture,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <SupplierFields
              data={data}
              setProfilePicture={setProfilePicture}
              removeProfilePicture={removeProfilePicture}
              state={this.state}
              stateValue={stateValue}
              profilePicture={profilePicture}
              setUploadedFile={setUploadedFile}
            />
          </div>

          <div className="col-md-6">
            <SupplierVerticals
              categoryTier1={categoryTier1}
              tier2={tier2}
              products={products}
              onChange={updateVerticals}
            />

            <SupplierTags
              tier1={categoryTier1}
              tier2={tier2}
              supplierTags={supplierTags}
              onChange={updateTags}
            />

            <SupplierTypes
              supplierTypes={supplierTypes}
              onChange={updateTypes}
            />
            {data.uuid && (
              <>
                <SupplierUsers
                  supplierUsers={supplierUsers}
                  addUser={addUser}
                  removeUser={removeUser}
                  searchUsers={searchUsers}
                  handleStateChange={handleStateChange}
                  userSearchResults={userSearchResults}
                  usersLoading={usersLoading}
                />
                <SupplierRecommendations
                  recommendations={recommendations}
                  supplierImages={supplierImages}
                  addRecommendation={addRecommendation}
                  updateRecommendation={updateRecommendation}
                  deleteRecommendation={deleteRecommendation}
                />
              </>
            )}

            <SupplierPermissions />
          </div>
        </div>

        <div className="row">
          <hr />
          <div className="col-md-12 action-bar">
            <Button
              disabled={submitting}
              color="grey-light"
              text="Cancel"
              icon="cancel"
              onClick={cancelClick}
            />

            <Button
              isLoading={submitting}
              type="submit"
              color="grey"
              text="Save"
              icon="save"
            />

            {submitFailed === true && (
              <span className="text-danger"> Saving Failed</span>
            )}
            {errors && <span className="text-danger"> {errors} </span>}

            {submitSucceeded === true && showSuccess && (
              <span className="text-success"> Saved Successfully</span>
            )}

            <Button
              className="u-float-right"
              // disabled={submitting}
              color="red"
              text="Delete"
              onClick={deleteClick}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default connect((state) => ({
  stateValue: selector(state, 'state'),
}))(
  reduxForm({
    form: 'supplier-form',
    validate: validateSupplierForm,
    enableReinitialize: true,
  })(SupplierForm)
);
