import { v4 as uuidv4 } from 'uuid';

export const generateSupplierPermissionsValues = (supplierPermissions) => {
  return supplierPermissions?.reduce((acc, permission) => {
    return {
      ...acc,
      [permission.permissionType]: {
        uuid: permission.uuid,
        permissionType: permission.permissionType,
        enabled: Boolean(permission.uuid),
      },
    };
  }, {});
};

export const generateSupplierPermissionsMutationVariables = (permissions) => {
  return Object.values(permissions).reduce(
    (variables, permission) => {
      if (permission.enabled && !permission.uuid) {
        return {
          createPermissions: [
            ...variables.createPermissions,
            {
              uuid: uuidv4(),
              permissionType: permission.permissionType,
            },
          ],
          deletePermissions: [...variables.deletePermissions],
        };
      }

      if (!permission.enabled && permission.uuid) {
        return {
          createPermissions: [...variables.createPermissions],
          deletePermissions: [...variables.deletePermissions, permission.uuid],
        };
      }

      return variables;
    },
    { createPermissions: [], deletePermissions: [] }
  );
};
