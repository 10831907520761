import { gql } from '@apollo/client';

export const UPDATE_SUPPLIER_PERMISSIONS = gql`
  mutation updateSupplierPermissions(
    $supplierUuid: String!
    $createPermissions: [SupplierPermissionsCreateManyDataInput!]!
    $deletePermissions: [String!]!
  ) {
    updateSupplier(
      where: { uuid: $supplierUuid }
      data: {
        supplierPermissions: {
          createMany: { data: $createPermissions }
          deleteMany: { uuid: { in: $deletePermissions } }
        }
      }
    ) {
      uuid
      supplierPermissions {
        uuid
        permissionType
      }
    }
  }
`;
