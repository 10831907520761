import { SubmissionError } from 'redux-form';
import {
  FETCH_VENDOR_OWNER,
  SET_USER,
  FETCH_USER_BY_EMAIL,
} from '../constants/action-types';

import { httpService, identityService } from '../../app-config';
import { signOut } from '@aws-amplify/auth';

async function getTokenWithCredentials(email, password) {
  const { accessToken } = await httpService.request({
    path: '/users/login/',
    method: 'POST',

    body: {
      email,
      password,
    },
  });

  return accessToken;
}

async function me(token) {
  return httpService.request({
    path: '/users/me',
    token,
  });
}

export const login = (email, password) => async () => {
  try {
    const token = await getTokenWithCredentials(email, password);
    const user = await me(token);

    if (user.type !== 'admin') {
      throw new SubmissionError({ _error: 'Login failed!' });
    }

    return { token, ...user };
  } catch (err) {
    // Refactor this once we have the real API error responses
    // maybe into a form validator service
    throw new SubmissionError({ _error: 'Login failed!' });
  }
};

export const setUser = (user) => {
  const identityExists = identityService.getIdentity() !== null;

  if (identityExists && user === null) {
    signOut();
  }

  // Save user to localStorage
  identityService.setIdentity(user);

  return { type: SET_USER, payload: user };
};

export function fetchUser(token, extraParams) {
  return (dispatch) => {
    me(token)
      .then((payload) =>
        dispatch(setUser({ token, ...extraParams, ...payload }))
      )
      .catch((err) => {
        if (err.status === 401) {
          dispatch(setUser(null));
        }
      });
  };
}

export function fetchVendorOwner(id) {
  return (dispatch) =>
    httpService
      .request({ path: `/admin/customers/${id}` })
      .then((response) => response)
      .then((payload) => dispatch({ type: FETCH_VENDOR_OWNER, payload }));
}

export function updateVendorOwnerName(data) {
  const { email, firstName, lastName } = data;
  return (dispatch) =>
    httpService
      .request({
        path: `/admin/customers/${data.id}`,
        method: 'PATCH',
        body: {
          email,
          type: 'supplier',
          firstName,
          lastName,
        },
      })
      .then((response) => response)
      .then((payload) => dispatch({ type: FETCH_VENDOR_OWNER, payload }));
}

export function createUserForVendor(data) {
  const { email, firstName, lastName } = data;
  const body = {
    email,
    type: 'customer',
    firstName,
    lastName,
  };

  return httpService
    .request({ path: '/admin/customers', method: 'POST', body })
    .then((response) => response);
}

export function fetchUserByEmail(mailbox) {
  return (dispatch) =>
    httpService
      .request({ path: `/admin/users/${mailbox}` })
      .then((response) => response)
      .then((payload) => dispatch({ type: FETCH_USER_BY_EMAIL, payload }));
}
export function fetchUserFromSearch(id) {
  return httpService
    .request({ path: `/admin/search/users/?q=id:"${id}"` })
    .then((response) => response.results[0] || {});
}

export const addUser = (data) =>
  httpService.request({
    path: '/admin/users',
    method: 'POST',
    body: { ...data, email: data.email.toLowerCase() },
  });
