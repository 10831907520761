import React from 'react';

import Button from './button';
import { ReactComponent as InvalidIcon } from '../../images/sprite/ui/invalid.svg';

const TagGroup = ({
  groupTags,
  rootTag,
  clickHandler,
  newTag,
  changeHandler,
  removeHandler,
  selectedTag = { uuid: 0, name: '' },
  toggleEditTag,
  updateTag,
  changeTagHandler,
}) => {
  newTag = newTag ? newTag : { uuid: 0, name: '' };
  return (
    <div className="row--padded-m">
      <h3>{rootTag.name}</h3>
      <div className="tag-group clearfix">
        {!!groupTags.length &&
          groupTags.map((tag, i) => (
            <div
              key={i}
              className="btn btn-default button--grey-light tag-item"
            >
              {selectedTag.uuid !== tag.uuid && (
                <div>
                  <span
                    className="pull-left"
                    onClick={toggleEditTag.bind({}, tag)}
                  >
                    {tag.name}
                  </span>
                  <InvalidIcon
                    className="pull-right icon"
                    onClick={removeHandler.bind({}, { uuid: tag.uuid })}
                  />
                </div>
              )}
              {selectedTag.uuid === tag.uuid && (
                <div>
                  <input
                    placeholder="Tag name"
                    className="form-control"
                    value={selectedTag.name}
                    name={`some_tag_${tag.uuid}`}
                    onBlur={updateTag}
                    onChange={changeTagHandler}
                  />
                </div>
              )}
            </div>
          ))}
        <br />
        <div className="col-md-5 new-tag-input">
          <label>Enter new tag</label>
          <input
            placeholder="Tag name"
            className="form-control"
            name="create_tag"
            value={newTag.name}
            onChange={changeHandler}
          />
        </div>
        <div className="col-md-2">
          <div className="new-tag">
            <Button onClick={clickHandler} color="grey" text="+Add new tag" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagGroup;
