import {
  SET_VENDOR_DATA,
  FETCH_VENDOR,
  FETCH_VENDOR_OWNER,
  RESET_VENDOR,
} from '../constants/action-types';

const initialState = {
  dietary: [],
  cuisine: [],
  type: [],
  menu: [],
  deleteMenus: [],
  deleteItems: [],
  menuPrice: 0,
  menuPriceBand: [],
  id: undefined,
  published: false,
  name: '',
  descriptor: '',
  about: '',
  url: '',
  deleteImages: [],
  location: {
    lat: undefined,
    long: undefined,
    radius: undefined,
  },
  logistics: '',
  created: '',
  updated: '',
  user: {
    firstName: '',
    lastName: '',
    email: '',
  },
  user_id: undefined,
};

function vendor(state = initialState, action) {
  switch (action.type) {
    case RESET_VENDOR:
      return initialState;
    case FETCH_VENDOR:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_VENDOR_OWNER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_VENDOR_DATA:
      return action.payload
        ? {
            ...state,
            ...action.payload,
          }
        : {
            ...initialState,
          };
    default:
      return state;
  }
}

export default vendor;
