import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import Menus from 'react-burger-menu';

const Menu = Menus.push;

const SidebarMenu = ({ user }) => {
  return (
    <div className="sidebar-menu">
      {user ? (
        <Menu isOpen={false} width={280} pageWrapId="childWrap">
          <Link
            className="header__nav-item sb-menu-item"
            to="/admin/users"
            activeClassName="t--bold"
          >
            Users
          </Link>
          <Link
            className="header__nav-item sb-menu-item"
            to="/admin/suppliers"
            activeClassName="t--bold"
          >
            Suppliers
          </Link>
          <Link
            className="header__nav-item sb-menu-item"
            to="/admin/events"
            activeClassName="t--bold"
          >
            Events
          </Link>
          <Link
            className="header__nav-item sb-menu-item"
            to="/admin/requests"
            activeClassName="t--bold"
          >
            Requests
          </Link>
          <Link
            className="header__nav-item sb-menu-item"
            to="/admin/quotes"
            activeClassName="t--bold"
          >
            Quotes
          </Link>
          <Link
            className="header__nav-item sb-menu-item"
            to="/admin/reviews"
            activeClassName="t--bold"
          >
            Reviews
          </Link>
          <Link
            className="header__nav-item sb-menu-item"
            to="/admin/partnerships"
            activeClassName="t--bold"
          >
            Partnerships
          </Link>
          <Link
            className="header__nav-item sb-menu-item"
            to="/admin/tags"
            activeClassName="t--bold"
          >
            Tags
          </Link>
          <Link
            className="header__nav-item sb-menu-item"
            to="/admin/upload-public-image"
            activeClassName="t--bold"
          >
            Upload Public Image
          </Link>
          <Link
            className="header__nav-item sb-menu-item"
            to="/admin/settings"
            activeClassName="t--bold"
          >
            Settings
          </Link>
        </Menu>
      ) : null}
    </div>
  );
};

SidebarMenu.defaultProps = {
  user: null,
};

SidebarMenu.propTypes = {
  user: PropTypes.shape({}),
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(SidebarMenu);
