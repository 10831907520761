import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Header from './header';
import SidebarMenu from './sidebar-menu';
import Footer from '../../components/footer';

class Shell extends Component {
  constructor(props) {
    super();
    this.children = props.children;
  }

  render() {
    const classes = classNames('shell', {
      'shell--shade-mobile': this.props.mobileMenu,
    });
    return (
      <div className={classes}>
        <Header />
        <SidebarMenu />
        <div id="childWrap">{this.props.children}</div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps({ mobileMenu }) {
  return { mobileMenu };
}

export default connect(mapStateToProps)(Shell);
