import {
  FETCH_SUPPLIER,
  SET_SUPPLIER_DATA,
  RESET_SUPPLIER,
} from '../constants/action-types';
import { SUPPLIER_PERMISSION_TYPES } from '../constants/supplier';

export const initialState = {
  name: null,
  slug: null,
  categoryTier1: null,
  tier2: [],
  products: [],
  trusted: null,
  state: 'pending',
  visible: null,
  algorithmDiscoverable: true,
  external: {
    foodSafetyAgencyId: null,
    hygieneRating: null,
    iZettleStaffName: null,
  },
  permissions: {
    [SUPPLIER_PERMISSION_TYPES.CUSTOMER_PHONE_NUMBER_ALLOWED]: {
      uuid: '',
      permissionType: SUPPLIER_PERMISSION_TYPES.CUSTOMER_PHONE_NUMBER_ALLOWED,
      enabled: false,
    },
  },
  public: {
    basePrice: null,
    description: null,
    images: [],
    profilePicture: {},
    social: [],
    tags: [],
    logistics: {
      maxWorkingHours: null,
      minSpend: null,
      radius: null,
      primaryTag: '',
      registeredAddress: {
        line1: '',
        line2: '',
        city: '',
        postcode: '',
      },
      registeredName: null,
      VATNumber: null,
      VATRegistered: false,
      availableHours: {
        start: null,
        end: null,
      },
      setup: {
        power: null,
        water: null,
        details: null,
      },
      location: {
        lat: 0,
        long: 0,
      },
    },
  },
  dietary: [],
  cuisines: [],
};

const withRegisteredAddress = (state) => ({
  ...state,
  public: {
    ...state.public,
    logistics: {
      ...state.public.logistics,
      registeredAddress: {
        line1: '',
        line2: '',
        city: '',
        postcode: '',
      },
    },
  },
});

const supplier = (state = initialState, action) => {
  switch (action.type) {
    case RESET_SUPPLIER:
      return initialState;
    case FETCH_SUPPLIER:
      return withRegisteredAddress({
        ...state,
        ...action.payload,
      });
    case SET_SUPPLIER_DATA:
      return withRegisteredAddress(
        action.payload
          ? {
              ...state,
              ...action.payload,
            }
          : {
              ...initialState,
            }
      );
    default:
      return state;
  }
};

export default supplier;
