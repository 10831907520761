import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SupplierRecommendation from '../../components/supplier-recommendation';

const SupplierRecommendations = ({
  recommendations,
  teamMembersOptions,
  supplierImages,
  addRecommendation,
  updateRecommendation,
  deleteRecommendation,
}) => (
  <div className="supplier-recommendations">
    <label>
      {!recommendations.length ? 'Add Recommendation' : 'Recommendations'}
    </label>
    {recommendations.map((recommendation) => (
      <SupplierRecommendation
        key={recommendation.uuid}
        data={recommendation}
        supplierImages={supplierImages}
        updateRecommendation={updateRecommendation}
        deleteRecommendation={deleteRecommendation}
        teamMembersOptions={teamMembersOptions}
      />
    ))}
    {!recommendations.length && (
      <div>
        <SupplierRecommendation
          newRecommendation
          addRecommendation={addRecommendation}
          supplierImages={supplierImages}
          teamMembersOptions={teamMembersOptions}
        />
      </div>
    )}
  </div>
);

SupplierRecommendations.propTypes = {
  recommendations: PropTypes.arrayOf(
    PropTypes.shape({
      highlight: PropTypes.string,
      recommendation: PropTypes.string,
      image: PropTypes.shape({}),
      teamMemberName: PropTypes.string,
    })
  ).isRequired,
  teamMembersOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  supplierImages: PropTypes.arrayOf(PropTypes.object).isRequired,
  addRecommendation: PropTypes.func.isRequired,
  updateRecommendation: PropTypes.func.isRequired,
  deleteRecommendation: PropTypes.func.isRequired,
};

const mapStateToProps = ({ tag }) => ({
  teamMembersOptions: tag[3000] || [],
});

export default connect(mapStateToProps)(SupplierRecommendations);
