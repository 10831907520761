import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { useMutation } from '@apollo/client';

import { fetchUser } from '../../actions/users';
import {
  handleOnImpersonateUser,
  handleOnImpersonateUserV2,
} from '../../modules/impersonateUser';
import { IMPERSONATE_USER_MUTATION } from '../../mutations/impersonate-user';
import { useImpersonationV2 } from '../../hooks/useImpersonationV2';

function UserPage(props) {
  const [impersonateMutation] = useMutation(IMPERSONATE_USER_MUTATION);
  const { isImpersonationV2Enabled } = useImpersonationV2();

  useEffect(() => {
    props.fetchUser(props.params.id);
  }, [props.fetchUser, props.params.id]);

  const redirect = `${
    props.user.type === 'supplier' ? 'suppliers/' : ''
  }dashboard`;

  return (
    <main className="container">
      <div className="user--header">
        <h1>
          {props.user.firstName} {props.user.lastName}
        </h1>

        <div className="user--header--buttons">
          <Link to="/admin/users">
            <button type="button" className="button button--grey-light">
              « Back to users
            </button>
          </Link>

          <Link to={`/admin/users/${props.user.id}/edit`}>
            <button type="button" className="button button--blue">
              Edit
            </button>
          </Link>

          <button
            type="button"
            className="button button--blue"
            onClick={() => {
              if (isImpersonationV2Enabled) {
                handleOnImpersonateUserV2({
                  userId: props.user.id,
                  userType: props.user.type,
                });
              } else {
                handleOnImpersonateUser(
                  impersonateMutation,
                  props.user.id,
                  redirect
                );
              }
            }}
          >
            Impersonate
          </button>
        </div>
      </div>

      <div className="col-md-12 user--row ">
        <div className="col-md-3">
          <div className="user--row--item-heading">ID</div>
          <div>{props.user.id}</div>
        </div>

        <div className="col-md-3">
          <div className="user--row--item-heading">First Name</div>
          <div>{props.user.firstName}</div>
        </div>

        <div className="col-md-3">
          <div className="user--row--item-heading">Last Name</div>
          <div>{props.user.lastName}</div>
        </div>

        <div className="col-md-3">
          <div className="user--row--item-heading">Email Address</div>
          <div>{props.user.email}</div>
        </div>
      </div>

      <div className="col-md-12 user--row ">
        <div className="col-md-3">
          <div className="user--row--item-heading">Phone</div>
          <div>{props.user.phone ?? '—'}</div>
        </div>

        <div className="col-md-3">
          <div className="user--row--item-heading">User Type</div>
          <div>{props.user.type}</div>
        </div>

        <div className="col-md-3">
          <div className="user--row--item-heading">Segment</div>
          <div>{props.user.segment ?? '—'}</div>
        </div>

        <div className="col-md-3">
          <div className="user--row--item-heading">Togather Business User</div>
          <div>{props.user.business_user ? 'Yes' : 'No'}</div>
        </div>
      </div>
    </main>
  );
}

UserPage.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  params: {
    id: PropTypes.string.isRequired,
  },
};

const mapDispatchToProps = { fetchUser };

const mapStateToProps = ({ customer }) => ({
  user: customer,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
