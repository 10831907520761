// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
// Components
import BasketItem from '../../components/basket-item';
// Actions
import {
  addDietaryRequirement,
  removeDietaryRequirement,
} from '../../actions/event';

class DietaryCalculator extends Component {
  render() {
    const dietaryItems = this.props.dietaryItems;
    return (
      <div className={`fieldset ${this.props.className}`}>
        <label
          htmlFor={this.props.label}
          className={classNames('field__label', this.props.labelClassName)}
        >
          {this.props.label}
        </label>
        <ul className="unstyled-list u-mb--s  u-pl--0">
          {dietaryItems.map((item) => (
            <BasketItem
              key={item.id}
              id={item.id}
              quantity={item.value}
              onAdd={this.props.addDietaryRequirement}
              onRemove={this.props.removeDietaryRequirement}
              priceModifier={item.price}
              name={item.name}
              minValue={0}
              maxValue={1000}
            />
          ))}
        </ul>
      </div>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    { addDietaryRequirement, removeDietaryRequirement },
    dispatch
  );
}

function mapStateToProps({ supplier, event }) {
  function getQuantity(id) {
    const res = event.dietaryItems.find((item) => item.id === id);
    return res ? res.quantity : 0;
  }
  const selectedDietaryItems = supplier.dietary.map((item) => {
    const { tagId: id, name, pid } = item;
    return {
      id,
      name,
      value: getQuantity(id),
      price: item.priceModifier,
      pid,
    };
  });

  return {
    dietaryItems: selectedDietaryItems,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(DietaryCalculator);
