import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import formField from '../../components/form-field';
import Button from '../../components/button';

const FIELDS = [
  'commission_fee',
  'service_fee',
  'low_price_end',
  'middle_price_start',
  'middle_price_end',
  'high_price_start',
];

const validate = (values) => {
  const errors = {};
  // eslint-disable-next-line array-callback-return
  FIELDS.map((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    } else if (values[field].match(/[^0-9.]/g)) {
      errors[field] = 'Is not a valid number';
    }
  });

  return errors;
};

class SettingsForm extends Component {
  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.props.initialize(nextProps.data);
    }
  }

  render() {
    const { handleSubmit, state } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="commission_fee"
          type="text"
          component={formField}
          label="Commission Fee"
          labelClassName="form-control-label"
          className="form-control"
        />
        <Field
          name="service_fee"
          type="text"
          component={formField}
          label="Service Fee"
          labelClassName="form-control-label"
          className="form-control"
        />
        <label>Price Bracket Logic</label>
        <Field
          name="low_price_end"
          type="text"
          component={formField}
          label="“£” (less than)"
          labelClassName="form-control-label"
          className="form-control"
        />
        <Field
          name="middle_price_start"
          type="text"
          component={formField}
          label="“££” (between) start"
          labelClassName="form-control-label"
          className="form-control"
        />
        <Field
          name="middle_price_end"
          type="text"
          component={formField}
          label="“££” (between) end"
          labelClassName="form-control-label"
          className="form-control"
        />
        <Field
          name="high_price_start"
          type="text"
          component={formField}
          label="“£££” (more than)"
          labelClassName="form-control-label"
          className="form-control"
        />
        <Button
          disabled={state.disabled}
          color="grey"
          text="Save"
          type="submit"
        />
        {state.submitting === true && (
          <span className="text-muted"> Saving...</span>
        )}
        {state.saved === true && <span className="text-success"> Saved</span>}
        {state.error !== false && (
          <span className="text-danger"> {state.error}</span>
        )}
      </form>
    );
  }
}

export default reduxForm({
  form: 'SettingsForm', // a unique identifier for this form,
  validate,
})(SettingsForm);
