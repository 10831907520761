import { push } from 'react-router-redux';
import queryString from 'query-string';

import {
  FETCH_SUPPLIER,
  UPDATE_SUPPLIER,
  FETCH_SUPPLIERS,
  SET_SUPPLIER_DATA,
  FETCH_SUPPLIER_CALENDAR,
  CREATE_SUPPLIER_CALENDAR_ITEM,
  UPDATE_SUPPLIER_CALENDAR_ITEM,
  DELETE_SUPPLIER_CALENDAR_ITEM,
  RESET_SUPPLIER,
} from '../constants/action-types';

import { httpService } from '../../app-config';

export function createSupplier(data) {
  return (dispatch) =>
    httpService
      .request({
        path: '/suppliers',
        method: 'POST',
        body: data,
      })
      .then((payload) => dispatch({ type: FETCH_SUPPLIER, payload }));
}

export const updateSupplier = (id, data) => (dispatch) =>
  httpService
    .request({
      path: `/suppliers/${id}`,
      method: 'PATCH',
      body: data,
    })
    .then((payload) => dispatch({ type: UPDATE_SUPPLIER, payload }));

export function setSupplierData(state) {
  return {
    type: SET_SUPPLIER_DATA,
    payload: state,
  };
}

export const deleteSupplier = (id) => (dispatch) =>
  httpService
    .request({
      path: `/suppliers/${id}`,
      method: 'DELETE',
    })
    .then(() => dispatch(push('/admin/suppliers')));

export const fetchSuppliers =
  (page, query = {}) =>
  (dispatch) =>
    httpService
      .request({
        path: `/suppliers-v2/admin/search/${page}?${queryString.stringify({
          ...query,
        })}`,
      })
      .then((payload) => dispatch({ type: FETCH_SUPPLIERS, payload }));

export const getSupplier = (id) =>
  httpService.request({
    path: `/suppliers-v2/${id}`,
  });

export const fetchSupplier = (id) => async (dispatch) => {
  const payload = await getSupplier(id);
  dispatch({ type: FETCH_SUPPLIER, payload });
};

export const resetSupplier = () => ({ type: RESET_SUPPLIER });

export const updateSupplierVerticals = (id, { tier2, products }) =>
  Promise.all([
    httpService.request({
      method: 'PUT',
      path: `/suppliers/verticals/${id}/tier2`,
      body: tier2,
    }),
    httpService.request({
      method: 'PUT',
      path: `/suppliers/verticals/${id}/products`,
      body: products,
    }),
  ]);

export async function fetchSupplierVerticals(id) {
  const [tier2, products] = await Promise.all([
    httpService.request({ path: `/suppliers/verticals/${id}/tier2` }),
    httpService.request({ path: `/suppliers/verticals/${id}/products` }),
  ]);

  return { tier2, products };
}

export const fetchSupplierTags = (id) =>
  httpService.request({
    path: `/suppliers/${id}/tags`,
  });

export const updateSupplierTags = (id, tags) =>
  httpService.request({
    path: `/suppliers/${id}/tags`,
    body: tags,
    method: 'PUT',
  });

export const fetchSupplierTypes = (id) =>
  httpService.request({
    path: `/suppliers/${id}/types`,
  });

export const fetchSupplierItems = (id) =>
  httpService.request({
    path: `/suppliers/${id}/items`,
  });

export const fetchSupplierGroupsAndItems = async (id) => {
  const groups = await httpService.request({
    path: `/suppliers/${id}/groups`,
  });

  return Promise.all(
    groups.map(async (group) => {
      const { items } = await httpService.request({
        path: `/suppliers/${id}/groups/${group.uuid}/items`,
      });

      return { items, ...group };
    })
  );
};

export const updateSupplierTypes = (id, tags) =>
  httpService.request({
    path: `/suppliers/${id}/types`,
    body: tags,
    method: 'PUT',
  });

export const createSupplierGroup = (id, data) =>
  httpService.request({
    path: `/suppliers/${id}/groups`,
    body: data,
    method: 'POST',
  });

export const updateSupplierGroupItems = (supplierId, groupId, data) =>
  httpService.request({
    path: `/suppliers/${supplierId}/groups/${groupId}/items`,
    body: data,
    method: 'PUT',
  });

export const updateSupplierGroup = (supplierId, groupId, data) =>
  httpService.request({
    path: `/suppliers/${supplierId}/groups/${groupId}`,
    body: data,
    method: 'PATCH',
  });

export const deleteSupplierGroup = (supplierId, groupId) =>
  httpService.request({
    path: `/suppliers/${supplierId}/groups/${groupId}`,
    method: 'DELETE',
  });

export const deleteSupplierItem = (supplierId, itemId) =>
  httpService.request({
    path: `/suppliers/${supplierId}/items/${itemId}`,
    method: 'DELETE',
  });

export const updateSupplierItem = (supplierId, itemId, data) =>
  httpService.request({
    path: `/suppliers/${supplierId}/items/${itemId}`,
    body: data,
    method: 'PATCH',
  });

export const createSupplierItem = (supplierId, data) =>
  httpService.request({
    path: `/suppliers/${supplierId}/items`,
    body: data,
    method: 'POST',
  });

export const fetchSupplierRecommendations = (supplierId) =>
  httpService.request({
    path: `/suppliers/${supplierId}/recommendations`,
  });

export const createSupplierRecommendation = (supplierId, data) =>
  httpService.request({
    path: `/suppliers/${supplierId}/recommendations`,
    body: data,
    method: 'POST',
  });

export const updateSupplierRecommendation = (
  supplierId,
  recommendationId,
  data
) =>
  httpService.request({
    path: `/suppliers/${supplierId}/recommendations/${recommendationId}`,
    body: data,
    method: 'PATCH',
  });

export const deleteSupplierRecommendation = (supplierId, recommendationId) =>
  httpService.request({
    path: `/suppliers/${supplierId}/recommendations/${recommendationId}`,
    method: 'DELETE',
  });

export const fetchSupplierUsers = (supplierId) =>
  httpService.request({
    path: `/suppliers/${supplierId}/users`,
  });

export const deleteSupplierUser = (supplierId, userId) =>
  httpService.request({
    path: `/suppliers/${supplierId}/users/${userId}`,
    method: 'DELETE',
  });

export const createSupplierUser = (supplierId, userId) =>
  httpService.request({
    path: `/suppliers/${supplierId}/users/${userId}`,
    method: 'PUT',
  });

export const fetchSupplierCalendar = (supplierId) => async (dispatch) => {
  const payload = await httpService.request({
    path: `/suppliers/${supplierId}/calendars`,
  });

  return dispatch({ type: FETCH_SUPPLIER_CALENDAR, payload });
};

export const createSupplierCalendarItem =
  (supplierId, data) => async (dispatch) => {
    const payload = await httpService.request({
      path: `/suppliers/${supplierId}/calendars`,
      method: 'POST',
      body: data,
    });

    return dispatch({ type: CREATE_SUPPLIER_CALENDAR_ITEM, payload });
  };

export const updateSupplierCalendarItem =
  (supplierId, calendarId, data) => async (dispatch) => {
    const payload = await httpService.request({
      path: `/suppliers/${supplierId}/calendars/${calendarId}`,
      method: 'PATCH',
      body: data,
    });

    return dispatch({ type: UPDATE_SUPPLIER_CALENDAR_ITEM, payload });
  };

export const deleteSupplierCalendarItem =
  (supplierId, calendarId) => async (dispatch) => {
    await httpService.request({
      path: `/suppliers/${supplierId}/calendars/${calendarId}`,
      method: 'DELETE',
    });

    return dispatch({
      type: DELETE_SUPPLIER_CALENDAR_ITEM,
      payload: calendarId,
    });
  };
