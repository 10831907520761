import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import TableSearch from '../../components/table-search';
import TableList from '../../components/table-list';
import { fetchEvents } from '../../actions/events';

const Events = ({ events, onFetchEvents, totalRecords }) => {
  const [{ page, limit, query }, setParams] = useState({ page: 0, limit: 10 });

  const changeQuery = useCallback(
    (searchQuery) => {
      setParams({
        page,
        limit,
        query: searchQuery,
      });
    },
    [limit, page]
  );

  const columns = [
    {
      name: 'id',
      title: 'ID',
    },
    {
      name: 'user.firstName',
      title: 'First Name',
    },
    {
      name: 'date',
      title: 'Event Date',
    },
    {
      name: 'type',
      title: 'Event Type',
    },
    {
      name: 'location_city',
      title: 'City',
    },
    {
      name: 'source',
      title: 'Booking Source',
    },
    {
      name: 'cta',
      title: 'Manage',
      width: 140,
      cell: ({ original: { id } }) => {
        const url = `/admin/events/${id}`;

        return (
          <Link className="" to={url} activeClassName="t--bold">
            <button type="button" className="button button--green">
              View
            </button>
          </Link>
        );
      },
    },
  ];

  useEffect(() => {
    onFetchEvents(page, limit, query);
  }, [limit, onFetchEvents, page, query]);

  const numberOfRowsPerPage = 10;
  const numberOfPages = Math.ceil(totalRecords / numberOfRowsPerPage);
  const onFetchData = useCallback(
    ({ page: nextPage = 0, pageSize }) => {
      onFetchEvents(nextPage, pageSize, query);
    },
    [onFetchEvents, query]
  );

  return (
    <main className="view view--bookings">
      <div className="container page-title">
        <h1>Events</h1>
      </div>
      <div className="row--padded-m">
        <div className="container">
          <div className="clearfix">
            <TableSearch
              className="pull-left"
              label="Search by ID"
              input={query}
              onChange={changeQuery}
            />
          </div>
          <TableList
            pages={numberOfPages}
            defaultPageSize={numberOfRowsPerPage}
            data={events}
            columns={columns}
            manual
            onFetchData={onFetchData}
          />
        </div>
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  onFetchEvents: fetchEvents,
};

const mapStateToProps = ({ events: { results, totalRecords } }) => ({
  events: results,
  totalRecords,
});

Events.propTypes = {
  events: PropTypes.shape({}).isRequired,
  onFetchEvents: PropTypes.func.isRequired,
  totalRecords: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
