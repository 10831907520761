import { takeLatest, debounce, call, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { request } from './api';
import { withErrorHandling } from './error-handling';
import { searchReceived, received, errored } from '../actions/supplier-reviews';

import {
  SUPPLIER_REVIEW_SEARCHED,
  SUPPLIER_REVIEW_CREATED,
  SUPPLIER_REVIEW_READ,
  SUPPLIER_REVIEW_UPDATED,
  SUPPLIER_REVIEW_DELETED,
} from '../constants/action-types';

function withSupplier(supplier) {
  if (supplier) {
    return (last) => ({ ...last, supplier });
  }

  return (last) => last;
}

const onSearch = withErrorHandling(function* requestList({
  supplier,
  page,
  limit,
}) {
  const res = yield call(request, {
    url: `/reviews/search/${page}`,
    params: withSupplier(supplier)({
      limit,
    }),
  });

  yield put(searchReceived(res));
},
errored);

const onCreate = withErrorHandling(function* create({ data }) {
  const review = yield call(request, {
    method: 'POST',
    url: '/reviews',
    data,
  });

  yield put(push(`/admin/reviews/${review.uuid}`));
}, errored);

const onRead = withErrorHandling(function* read({ uuid }) {
  const review = yield call(request, {
    url: `/reviews/${uuid}`,
  });

  yield put(received(review));
}, errored);

const onUpdate = withErrorHandling(function* update({ uuid, data }) {
  const { uuid: discard, ...rest } = data;

  const review = yield call(request, {
    method: 'PATCH',
    url: `/reviews/${uuid}`,
    data: rest,
  });

  yield put(received(review));
}, errored);

const onDelete = withErrorHandling(function* deleteReview({ uuid }) {
  const confirmed = yield call(
    [window, 'confirm'],
    'Are you sure you want to delete this review?'
  );
  if (!confirmed) {
    yield put(received({}));

    return;
  }

  yield call(request, {
    method: 'DELETE',
    url: `/reviews/${uuid}`,
  });

  yield put(push('/admin/reviews'));
}, errored);

export default function* supplierReviewsSaga() {
  yield debounce(100, SUPPLIER_REVIEW_SEARCHED, onSearch);
  yield takeLatest(SUPPLIER_REVIEW_CREATED, onCreate);
  yield takeLatest(SUPPLIER_REVIEW_READ, onRead);
  yield takeLatest(SUPPLIER_REVIEW_UPDATED, onUpdate);
  yield takeLatest(SUPPLIER_REVIEW_DELETED, onDelete);
}
