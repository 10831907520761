import { TOGGLE_HEADER_DROPDOWN } from '../constants/action-types';

function toggleHeaderDropdown(state) {
  return {
    type: TOGGLE_HEADER_DROPDOWN,
    payload: state,
  };
}

export default toggleHeaderDropdown;
