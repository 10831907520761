import { gql } from '@apollo/client';

export const QUOTE_BY_UUID = gql`
  query QuoteByUuid($quoteUuid: String!) {
    quotes(where: { uuid: { equals: $quoteUuid } }) {
      quote_line_items {
        uuid
      }
    }
  }
`;
