import React, { Component } from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import toggleHeaderDropdown from '../../actions/header-dropdown';

import Dropdown from '../../components/dropdown';
import { ReactComponent as LogoIcon } from '../../../images/sprite/logos/logo.svg';

class Header extends Component {
  toggleDropdown = () => {
    this.props.toggleHeaderDropdown(!this.props.headerDropdownIsVisible);
  };
  render() {
    const { user } = this.props;

    return (
      <header
        className={classNames('header', `header--${this.props.header}`, {
          'header--padding': user,
        })}
      >
        <div className="header__inner">
          <Link className="header__logo" to="/">
            <LogoIcon className="icon--logo" />
          </Link>
          <nav className="header__nav">
            {user ? (
              <div className="header__nav-item u-relative">
                <button
                  className={classNames('header__cta', {
                    'header__cta--disabled': this.props.headerDropdownIsVisible,
                  })}
                  onClick={this.toggleDropdown}
                >
                  <span className="header__cta-text">
                    Hi,&nbsp;<strong>{user.firstName}</strong>
                  </span>
                </button>
                <Dropdown />
              </div>
            ) : (
              <Link
                className="header__nav-item"
                to="/login"
                activeClassName="t--bold"
              >
                Login
              </Link>
            )}
          </nav>
        </div>
      </header>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ toggleHeaderDropdown }, dispatch);
}

function mapStateToProps({ headerDropdownIsVisible, user, header }) {
  return { headerDropdownIsVisible, user, header };
}

export default connect(mapStateToProps, matchDispatchToProps)(Header);
