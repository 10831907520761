import PropTypes from 'prop-types';

export const bookingShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  supplier: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  timings: PropTypes.shape({
    date: PropTypes.string.isRequired,
  }).isRequired,
  total: PropTypes.number.isRequired,
  covers: PropTypes.number.isRequired,
  commission_fee: PropTypes.number.isRequired,
  service_fee: PropTypes.number.isRequired,
});
