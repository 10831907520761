import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import TableSearch from '../../components/table-search';
import TableList from '../../components/table-list';
import { fetchRequests } from '../../actions/requests';

const Requests = ({ requests, onFetchRequests, totalRecords }) => {
  const [{ page, limit, query }, setParams] = useState({ page: 0, limit: 10 });

  const changeQuery = useCallback(
    (searchQuery) => {
      setParams({
        page,
        limit,
        query: searchQuery,
      });
    },
    [limit, page]
  );

  const columns = [
    {
      name: 'id',
      title: 'ID',
    },
    {
      name: 'event.user.firstName',
      title: 'First Name',
    },
    {
      name: 'category_tier2',
      title: 'tier2',
    },
    {
      name: 'budget',
      title: 'Budget',
    },
    {
      name: 'cta',
      title: 'Manage',
      width: 140,
      cell: ({ original: { id } }) => {
        const url = `/admin/requests/${id}`;

        return (
          <Link className="" to={url} activeClassName="t--bold">
            <button type="button" className="button button--green">
              View
            </button>
          </Link>
        );
      },
    },
  ];

  useEffect(() => {
    onFetchRequests(page, limit, query);
  }, [limit, onFetchRequests, page, query]);

  const numberOfRowsPerPage = 10;
  const numberOfPages = Math.ceil(totalRecords / numberOfRowsPerPage);
  const onFetchData = useCallback(
    ({ page: nextPage = 0, pageSize }) => {
      onFetchRequests(nextPage, pageSize, query);
    },
    [onFetchRequests, query]
  );

  return (
    <main className="view view--bookings">
      <div className="container page-title">
        <h1>Requests</h1>
      </div>
      <div className="row--padded-m">
        <div className="container">
          <div className="clearfix">
            <TableSearch
              className="pull-left"
              label="Search by ID"
              input={query}
              onChange={changeQuery}
            />
          </div>
          <TableList
            pages={numberOfPages}
            defaultPageSize={numberOfRowsPerPage}
            data={requests}
            columns={columns}
            manual
            onFetchData={onFetchData}
          />
        </div>
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  onFetchRequests: fetchRequests,
};

const mapStateToProps = ({ requests: { results, totalRecords } }) => ({
  requests: results,
  totalRecords,
});

Requests.propTypes = {
  requests: PropTypes.shape({}).isRequired,
  onFetchRequests: PropTypes.func.isRequired,
  totalRecords: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Requests);
