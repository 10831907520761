import {
  GET_ALL_EVENTS,
  GET_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
} from '../constants/action-types';

export const fetchEvents = (page, pageSize, query = '') => ({
  type: GET_ALL_EVENTS,
  page,
  pageSize,
  query,
});

export const fetchEvent = (eventId) => ({
  type: GET_EVENT,
  eventId,
});

export const deleteEvent = (eventId) => ({
  type: DELETE_EVENT,
  eventId,
});
