import React from 'react';

export default function BookingField({ label, value }) {
  return (
    <div className="field">
      <span className="field__label field__label--touched form-control-label">
        {label}
      </span>
      <h5>{value}</h5>
    </div>
  );
}
