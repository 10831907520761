import React from 'react';
import moment from 'moment';

const Footer = () => (
  <footer className="footer">
    <div className="footer__inner">
      <div className="footer__item">
        <p>Togather {moment().format('YYYY')}</p>
      </div>
    </div>
  </footer>
);

export default Footer;
