import { FETCH_PARTNERSHIP } from '../constants/action-types';

const initialState = {
  uuid: '',
  name: '',
  postcode: '',
  commission: '',
};

function partnership(state = initialState, action) {
  switch (action.type) {
    case FETCH_PARTNERSHIP:
      return action.payload;
    default:
      return state;
  }
}

export default partnership;
