import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import setHeaderColor from '../../actions/header';

import missingIllustration from '../../../images/illustrations/404.svg';

class Missing extends Component {
  componentWillMount() {
    this.props.setHeaderColor('white');
  }

  render() {
    return (
      <main className="view missing">
        <img
          className="missing__img"
          src={missingIllustration}
          alt="Missing Page"
        />
        <h1 className="h2">Keep calm and try again</h1>
        <p>
          The page you’re looking for could have been deleted or never have
          existed.
        </p>
        <a href="/" className="button button--blue">
          Home
        </a>
      </main>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ setHeaderColor }, dispatch);
}

export default connect(null, matchDispatchToProps)(Missing);
