import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { format } from 'date-fns';
import { fetchEvent, deleteEvent } from '../../actions/events';
import EventField from '../../components/booking-field';
import SEODataFields from '../../components/seo-data-fields';

const Event = ({
  event: {
    location_city,
    location_line_1,
    location_line_2,
    location_notes,
    postcode,
    source,
    date,
    partnershipData,
    type,
    urgency,
    description,
    start_time,
    end_time,
    SEOData,
    created,
    regionData,
    user: { firstName, lastName, email, phone } = {},
  },
  onFetchEvent,
  onDeleteEvent,
  params: { eventId },
}) => {
  const { district, uk_region } = regionData ?? {};
  const { name: partnershipName, commission } = partnershipData || {};

  useEffect(() => {
    onFetchEvent(eventId);
  }, [eventId, onFetchEvent]);
  return (
    <main className="view view--bookings">
      <div className="container page-title clearfix">
        <h1 className="u-mb-s">
          {eventId} - {firstName} {lastName}{' '}
          {location_city ? `- ${location_city}` : ''}
        </h1>
      </div>
      <div className="row--grey">
        <div className="container">
          <div className="clearfix">
            <Link
              className="pull-left"
              to="/admin/events"
              style={{ margin: '10px 0' }}
            >
              <button type="button" className="button button--grey-light">
                &laquo; Back to all Events
              </button>
            </Link>
            <Link
              className="pull-left"
              to={`/admin/events/${eventId}/edit`}
              style={{ margin: '10px 16px' }}
            >
              <button type="button" className="button button--blue">
                Edit event
              </button>
            </Link>

            <button
              style={{ marginTop: '10px' }}
              type="button"
              className="button button--red"
              onClick={() => {
                if (
                  window.confirm(
                    'Are you sure you would like to delete this event?'
                  )
                ) {
                  onDeleteEvent(eventId);
                }
              }}
            >
              Delete event
            </button>
          </div>
          <div className="row--white clearfix" style={{ margin: '20px 0' }}>
            <div className="col-md-12">
              <div className="col-md-3">
                <h3 className="u-mb--0 u-mt--s">Summary</h3>
              </div>
            </div>

            <div className="col-md-12">
              <div className="col-md-3">
                <EventField label="Name" value={firstName || ''} />
              </div>

              <div className="col-md-3">
                <EventField label="Email" value={email} />
              </div>

              <div className="col-md-3">
                <EventField label="Phone" value={phone} />
              </div>
              <div className="col-md-3">
                {date && (
                  <EventField
                    label="Created date"
                    value={format(new Date(created), 'do MMMM yyyy')}
                  />
                )}
              </div>
            </div>

            <div className="col-md-12">
              <div className="col-md-3">
                <EventField
                  label="Partner name"
                  value={partnershipName || ''}
                />
              </div>

              <div className="col-md-3">
                <EventField label="Commission" value={commission} />
              </div>

              <div className="col-md-3">
                <EventField label="District" value={district} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="col-md-3">
                <EventField label="UK Region" value={uk_region || ''} />
              </div>
            </div>

            <div className="col-md-12">
              <div className="col-md-3">
                <h3 className="u-mb--0 u-mt--l">Event Details</h3>
              </div>
            </div>

            <div className="col-md-12">
              <div className="col-md-3">
                <EventField label="Event type" value={type || ''} />
              </div>

              <div className="col-md-3">
                <EventField label="Description" value={description} />
              </div>

              <div className="col-md-3">
                <EventField label="Wants booked by" value={urgency} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="col-md-3">
                {date && (
                  <EventField
                    label="Date of event"
                    value={format(new Date(date), 'do MMMM yyyy')}
                  />
                )}
              </div>

              <div className="col-md-3">
                <EventField label="Start time" value={start_time} />
              </div>

              <div className="col-md-3">
                <EventField
                  label="Address of the event"
                  value={`${location_line_1}, ${
                    location_line_2 && `${location_line_2},`
                  } ${location_city}, ${postcode}`}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="col-md-3" />
              <div className="col-md-3">
                <EventField label="End time" value={end_time} />
              </div>
              <div className="col-md-3">
                <EventField label="Location notes" value={location_notes} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="col-md-3" />
              <div className="col-md-3" />
              <div className="col-md-3">
                <EventField label="Booking source" value={source} />
              </div>
            </div>
            {SEOData && <SEODataFields type="Event" {...SEOData} />}
          </div>
        </div>
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  onFetchEvent: fetchEvent,
  onDeleteEvent: deleteEvent,
};

const mapStateToProps = ({ eventDetails }) => ({
  event: eventDetails,
});

Event.propTypes = {
  event: PropTypes.shape({
    location_city: PropTypes.string,
    location_line_1: PropTypes.string,
    location_line_2: PropTypes.string,
    location_notes: PropTypes.string,
    postcode: PropTypes.string,
    source: PropTypes.string,
    date: PropTypes.string,
    partnershipData: PropTypes.shape({}),
    type: PropTypes.string,
    urgency: PropTypes.string,
    description: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    SEOData: PropTypes.shape({}),
    regionData: PropTypes.shape({
      district: PropTypes.string,
      uk_region: PropTypes.string,
    }),
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
  }).isRequired,
  onFetchEvent: PropTypes.func.isRequired,
  params: PropTypes.shape({ eventId: PropTypes.string }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Event);
