import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import debounce from 'just-debounce';
import Select from 'react-select';

import TableSearch from '../../components/table-search';
import TableList from '../../components/table-list';

import setHeaderColor from '../../actions/header';
import { setUser } from '../../actions/user';
import { getQuotesTable } from '../../selectors/quotes';
import { bookingShape } from '../../prop-types/bookings';
import { fetchQuotes } from '../../actions/quotes';
import { getStateFromQuery } from '../../libs/pagination';

class Quotes extends Component {
  static propTypes = {
    setHeaderColor: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
    fetchQuotes: PropTypes.func.isRequired,
    user: PropTypes.shape({}),
    router: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
    quotes: PropTypes.arrayOf(bookingShape.isRequired).isRequired,
    totalRecords: PropTypes.number,
  };

  static defaultProps = {
    user: {},
    totalRecords: 0,
  };

  constructor(props) {
    super(props);

    this.update();
  }

  quoteStateOptions = [
    { id: null, label: 'all quotes' },
    { id: 'pending', label: 'pending' },
    { id: 'booked', label: 'booked' },
    { id: 'declined', label: 'declined' },
    { id: 'cancelled', label: 'cancelled' },
    { id: 'withdrawn', label: 'withdrawn' },
  ];

  state = {
    ...getStateFromQuery(this.props),
    quoteState: this.quoteStateOptions[0],
  };

  componentWillMount() {
    this.props.setHeaderColor('white');
    this.loadQuotes();
  }

  onFetchData = (state) =>
    this.setState(
      {
        ...this.state,
        page: state.page,
        limit: state.pageSize,
      },
      this.loadQuotes
    );

  update() {
    const { user, router } = this.props;

    if (user == null) {
      router.replace('/login');
    }
  }

  loadQuotes = debounce(async () => {
    try {
      await this.props.fetchQuotes({
        query: this.state.query,
        limit: this.state.limit,
        page: this.state.page,
        quoteState: this.state.quoteState.id,
      });
    } catch (err) {
      if (err.status === 401) {
        this.props.setUser(null);
      }
    }
  }, 3000);

  changeQuery = (query) => {
    this.setState(
      { query, page: 0, quoteState: this.state.quoteState },
      this.loadQuotes
    );
  };

  changeQuoteState = (query) => {
    this.setState(
      { ...this.state, quoteState: query ? query : this.quoteStateOptions[0] },
      this.loadQuotes
    );
  };

  render() {
    const { quotes } = this.props;

    const columns = [
      {
        name: 'uuid',
        title: 'Quote ID',
      },
      {
        name: 'supplier',
        title: 'Vendor Name',
        cell: (props) => props.value.name,
      },
      {
        name: 'eventRequest.event.user',
        width: 200,
        title: 'Customer email',
        cell: (props) => props.value.email,
      },
      {
        name: 'eventRequest.event',
        width: 100,
        cell: (props) => moment(props.value.date).format('DD/MM/YYYY'),
        title: 'Event Date',
      },
      {
        name: 'created',
        width: 100,
        cell: (props) => moment(props.value).format('DD/MM/YYYY'),
        title: 'Created Date',
      },
      {
        name: 'state',
        width: 100,
        title: 'Status',
      },
      {
        name: 'published',
        width: 100,
        title: 'Published',
        cell: (props) => (props.value ? 'Yes' : 'No'),
      },
      {
        name: 'customerTotal',
        width: 80,
        cell: (props) => `£${props.value}`,
        title: 'Total',
      },
      {
        name: 'uuid',
        width: 120,
        cell: (props) => {
          const url = `/admin/quotes/${props.value}`;
          return (
            <Link className="" to={url} activeClassName="t--bold">
              <button className="button button--grey-light">More info</button>
            </Link>
          );
        },
        title: 'Manage',
      },
    ];

    return (
      <main className="view view--bookings">
        <div className="container page-title">
          <h1>Quotes</h1>
        </div>

        <div className="row--padded-m">
          <div className="container" style={{ 'margin-bottom': '20px' }}>
            <div className="col-md-3">
              <label for="quote-state">Search Quotes</label>
              <TableSearch
                id="quote-query"
                label="Search by name"
                input={this.state.query}
                onChange={this.changeQuery}
              />
            </div>
            <div className="col-md-3">
              <label for="quote-state">Filter by Quote Status</label>
              <Select
                id="quote-state"
                isMulti={false}
                isClearable={false}
                backspaceRemovesValue={false}
                onChange={this.changeQuoteState}
                value={this.state.quoteState}
                options={this.quoteStateOptions}
              />
            </div>
          </div>
          <div className="container">
            <TableList
              pages={Math.ceil(this.props.totalRecords / this.state.limit)}
              defaultPageSize={this.state.limit}
              data={quotes}
              columns={columns}
              manual
              onFetchData={this.onFetchData}
            />
          </div>
        </div>
      </main>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchQuotes,
      setHeaderColor,
      setUser,
    },
    dispatch
  );

const mapStateToProps = ({ user, ...state }) => ({
  user,
  quotes: getQuotesTable(state),
  totalRecords: state.quotes.totalRecords,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Quotes));
