import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { FormikField } from '../../components/form-field';
import { FormikCheckbox } from '../../components/form-checkbox';

import { schema as validationSchema } from './schema/partnerships';

const PartnershipForm = ({ isNew, data, onSave, onDelete }) => {
  const [saved, setSaved] = useState(false);

  const onSubmit = useCallback(
    async (rawValues) => {
      const values = validationSchema.cast(rawValues);

      const {
        partnerName,
        postcode,
        commission,
        locationLine1,
        locationLine2,
        salesforceOpportunityId,
        slug,
        power,
        water,
      } = values;

      const newData = {
        name: partnerName,
        postcode,
        commission,
        location_line1: locationLine1,
        location_line2: locationLine2,
        salesforce_opportunity_id: salesforceOpportunityId || null,
        slug,
        power,
        water,
      };

      if (!isNew) {
        newData.uuid = data.uuid;
      }

      await onSave(newData);
      setSaved(true);
      setTimeout(setSaved, 2000, false);
    },
    [isNew, onSave, data.uuid]
  );

  const onDeleteCallback = useCallback(() => {
    if (data.uuid) {
      onDelete(data.uuid);
    }
  }, [onDelete, data.uuid]);

  const initialValues = useMemo(() => {
    const getValue = (key, defaultValue) =>
      isNew ? defaultValue : data[key] || defaultValue;

    return {
      partnerName: getValue('name', ''),
      postcode: getValue('postcode', ''),
      commission: getValue('commission', ''),
      locationLine1: getValue('location_line1', ''),
      locationLine2: getValue('location_line2', ''),
      salesforceOpportunityId: getValue('salesforce_opportunity_id', ''),
      slug: getValue('slug', ''),
      power: getValue('power', false),
      water: getValue('water', false),
    };
  }, [data, isNew]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <div className="row">
          <div className="col-md-6">
            <Field
              name="partnerName"
              label="Partner name"
              as={FormikField}
              className="form-control"
              labelClassName="form-control-label"
            />
            <Field
              name="slug"
              label="Slug"
              as={FormikField}
              className="form-control"
              labelClassName="form-control-label"
            />
            <Field
              name="locationLine1"
              label="Location Line 1"
              as={FormikField}
              className="form-control"
              labelClassName="form-control-label"
            />
            <Field
              name="locationLine2"
              label="Location Line 2"
              as={FormikField}
              className="form-control"
              labelClassName="form-control-label"
            />
            <Field
              name="postcode"
              label="Post code"
              as={FormikField}
              className="form-control"
              labelClassName="form-control-label"
            />
            <Field
              name="power"
              label="Power"
              as={FormikCheckbox}
              className="form-control"
              labelClassName="form-control-label"
            />
            <Field
              name="water"
              label="Water"
              as={FormikCheckbox}
              className="form-control"
              labelClassName="form-control-label"
            />
            <Field
              name="commission"
              label="Commission"
              as={FormikField}
              className="form-control"
              labelClassName="form-control-label"
            />
          </div>
          <div className="col-md-6">
            <Field
              name="salesforceOpportunityId"
              label="Salesforce Opportunity ID"
              as={FormikField}
              className="form-control"
              labelClassName="form-control-label"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 form-buttons">
            <div>
              <button type="submit" className="button button--blue">
                Save
              </button>{' '}
              &nbsp;
              {saved && (
                <span className="text-success"> Saved Successfully</span>
              )}
            </div>
            <button
              type="button"
              className="button button--red"
              onClick={onDeleteCallback}
            >
              Delete partnership
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

PartnershipForm.propTypes = {
  data: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    postcode: PropTypes.string,
    commission: PropTypes.string,
    location_line1: PropTypes.string,
    location_line2: PropTypes.string,
    salesforce_opportunity_id: PropTypes.string,
    slug: PropTypes.string,
    power: PropTypes.boolean,
    water: PropTypes.boolean,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
};

export default PartnershipForm;
