import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import {
  FETCH_ENQUIRIES,
  FETCH_ENQUIRY,
  UPDATE_ENQUIRY,
  SET_ENQUIRY_DATA,
  SENT_QUOTE_EMAIL,
  SENT_ALL_QUOTES_EMAIL,
} from '../constants/action-types';
import { httpService } from '../../app-config';

export function fetchEnquiries(params) {
  return (dispatch) =>
    httpService
      .request({
        path: `/admin/enquiries?q=${params.q}&limit=${params.limit}&offset=${params.offset}`,
      })
      .then((response) => response)
      .then((payload) =>
        dispatch({
          type: FETCH_ENQUIRIES,
          payload,
        })
      );
}

export function fetchEnquiry(id) {
  return (dispatch) =>
    httpService
      .request({
        path: `/admin/enquiries/${id}`,
      })
      .then((response) => {
        if (!response.vendor) {
          return response;
        }

        return httpService
          .request({ path: `/admin/vendors/${response.vendor}` })
          .then((vendor) => {
            response.vendor = vendor.name;
            return response;
          });
      })
      .then((payload) => {
        dispatch({
          type: FETCH_ENQUIRY,
          payload,
        });
      });
}

export const updateEnquiry = (enquiry) => (dispatch) => {
  // If somehow vendor is accidentally added, don't update the value.
  delete enquiry.vendor;

  return httpService
    .request({
      path: `/admin/enquiries/${enquiry.id}`,
      method: 'PATCH',
      body: omitBy(enquiry, isNil),
    })
    .then((response) => response)
    .then((payload) => dispatch({ type: UPDATE_ENQUIRY, payload }));
};

export function deleteEnquiry(id) {
  return (dispatch) =>
    httpService
      .request({
        path: `/admin/enquiries/${id}`,
        method: 'DELETE',
      })
      .then((response) => response)
      .then((payload) =>
        dispatch({
          type: UPDATE_ENQUIRY,
          payload,
        })
      );
}

export function createEnquiry(enquiry) {
  return (dispatch) =>
    httpService
      .request({
        path: '/enquiries',
        method: 'POST',
        body: enquiry,
      })
      .then((response) => response)
      .then((payload) =>
        dispatch({
          type: FETCH_ENQUIRY,
          payload,
        })
      );
}

export function setEnquiryData(state) {
  return {
    type: SET_ENQUIRY_DATA,
    payload: state,
  };
}

export function sendQuoteEmail(data) {
  return (dispatch) =>
    httpService
      .request({
        path: '/admin/emails/quotes/single',
        method: 'POST',
        body: data,
      })
      .then(() => dispatch({ type: SENT_QUOTE_EMAIL }));
}

export function sendAllQuotesEmail(data) {
  return (dispatch) =>
    httpService
      .request({
        path: '/admin/emails/quotes/all',
        method: 'POST',
        body: data,
      })
      .then(() => dispatch({ type: SENT_ALL_QUOTES_EMAIL }));
}
