import React from 'react';

const TableSearch = ({
  input,
  label,
  onChange,
  type = 'text',
  options = [],
  className,
}) => {
  const value = input || '';
  className += ' table-search';

  const onChangeCallback = ({ target: { value } }) => onChange(value);

  return (
    <div className={className}>
      {type === 'text' ? (
        <input
          className="form-control"
          placeholder={label}
          value={value}
          type="text"
          onChange={onChangeCallback}
        />
      ) : (
        <select
          className="form-control"
          name="filter"
          value={input}
          onChange={onChangeCallback}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default TableSearch;
