import {
  ADD_DIETARY_REQUIREMENT,
  REMOVE_DIETARY_REQUIREMENT,
  RESET_EVENT,
  SET_EVENT,
} from '../constants/action-types';

export function addDietaryRequirement(obj) {
  return {
    type: ADD_DIETARY_REQUIREMENT,
    payload: obj,
  };
}

export function removeDietaryRequirement(id) {
  return {
    type: REMOVE_DIETARY_REQUIREMENT,
    payload: id,
  };
}

export function resetEvent() {
  return {
    type: RESET_EVENT,
  };
}

export function setEvent(obj) {
  return {
    type: SET_EVENT,
    payload: obj,
  };
}
