import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import {
  IndexRedirect,
  Route,
  Router,
  applyRouterMiddleware,
} from 'react-router';
import { replace } from 'react-router-redux';
import { UserAuthWrapper } from 'redux-auth-wrapper';
import useScroll from 'react-router-scroll/lib/useScroll';
import { ApolloProvider } from '@apollo/client';

import { setUser } from './actions/user';
import { client } from './modules/apollo/apollo';
import AuthProvider from './components/_shared/context/auth';

import Shell from './containers/partials/shell';

// Views
import UsersPage from './containers/pages/UsersPage';
import AddUserForm from './containers/forms/AddUserForm';
import LogIn from './containers/pages/log-in';
import Missing from './containers/pages/missing';
import Settings from './containers/pages/settings';
import Tags from './containers/pages/tags';
import Quotes from './containers/pages/quotes';
import { QuoteWithMarkSupplierAsPaid as Quote } from './containers/pages/quote-with-mark-supplier-as-paid';
import AddQuoteForm from './containers/forms/add-quote-form';
import EditQuoteForm from './containers/forms/edit-quote-form';
import Supplier from './containers/pages/supplier';
import Suppliers from './containers/pages/suppliers';
import Events from './containers/pages/events';
import Event from './containers/pages/event';
import EventEditForm from './containers/forms/event-edit-form';
import Requests from './containers/pages/requests';
import Request from './containers/pages/request';
import RequestEditForm from './containers/forms/request-edit-form';
import SupplierCalendar from './containers/pages/supplier-calendar';
import Reviews from './containers/pages/reviews';
import Review from './containers/pages/review';
import Partnerships from './containers/pages/partnerships';
import Partnership from './containers/pages/partnership';
import EditUserForm from './containers/forms/EditUserForm';
import UserPage from './containers/pages/UserPage';
import UploadPublicImage from './containers/pages/upload-public-image';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { properties } from '../app-config';
import { isCognitoAuthentication, isCognitoUserAdmin } from './modules/cognito';

const sdkConfig = {
  core: {
    authorizationKey: properties.splitSdkKey,
    // key represents your internal user id, or the account id that
    // the user belongs to.
    // This could also be a cookie you generate for anonymous users.
    key: 'key',
  },
};

const userHasPermission = UserAuthWrapper({
  authSelector: ({ user }) => user,
  predicate: (user) => {
    if (user && isCognitoAuthentication(user.token)) {
      return isCognitoUserAdmin(user.token);
    }

    return user && user.type === 'admin';
  },
  redirectAction: (newLoc) => (dispatch) => {
    dispatch(setUser(null));
    dispatch(replace(newLoc));
  },
});

const userIsNotAuth = (decoratedComponent, options = {}) =>
  UserAuthWrapper({
    authSelector: ({ user }) => user,
    predicate: (user) => user === null,
    failureRedirectPath: (state, { location }) =>
      location.query.redirect || '/',
    allowRedirectBack: false,
    ...options,
  })(decoratedComponent);

// Detect if a route change should scroll page to the top or not.
// `ignoreScrollBehavior` to remove all scroll
// `ignoreScrollBehavior='equal'` to remove scroll only on current page
function routeShouldScrollTop(prevRouterProps, { routes }) {
  if (
    routes.some((route) => route.ignoreScrollBehavior === 'equal') &&
    prevRouterProps &&
    prevRouterProps.routes[2] &&
    prevRouterProps.routes[2].path === routes[2].path
  ) {
    return false;
  }

  if (routes.some((route) => route.ignoreScrollBehavior === true)) {
    return false;
  }

  return true;
}

const redirectToLogin = (nextState, replace) => {
  replace('/login');
};

const Root = ({ store, history }) => (
  <SplitFactory config={sdkConfig}>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <AuthProvider>
          <Router
            history={history}
            render={applyRouterMiddleware(useScroll(routeShouldScrollTop))}
          >
            <Route path="/" component={Shell}>
              <IndexRedirect to="/admin/suppliers" />
              <Route path="login" component={userIsNotAuth(LogIn)} />
              <Route path="logout" onEnter={redirectToLogin} />
              <Route
                path="admin"
                ignoreScrollBehavior="equal"
                component={userHasPermission(({ children }) => children)}
              >
                <Route path="users" component={UsersPage} />
                <Route path="users/new" component={AddUserForm} />
                <Route path="users/:id" component={UserPage} />
                <Route path="users/:id/edit" component={EditUserForm} />

                <Route path="quotes" component={Quotes} />
                <Route path="quotes/:quoteId" component={Quote} />

                <Route
                  path="upload-public-image"
                  component={UploadPublicImage}
                />

                <Route path="settings" component={Settings} />
                <Route path="tags" component={Tags} />

                <Route path="suppliers" component={Suppliers} />
                <Route path="suppliers/:supplierId" component={Supplier} />
                <Route
                  path="suppliers/:supplierId/calendar"
                  component={SupplierCalendar}
                />

                <Route path="reviews" component={Reviews} />
                <Route path="reviews/:reviewId" component={Review} />
                <Route path="partnerships" component={Partnerships} />
                <Route
                  path="partnerships/:partnershipId"
                  component={Partnership}
                />
                <Route path="events" component={Events} />
                <Route path="events/:eventId" component={Event} />
                <Route path="events/:eventId/edit" component={EventEditForm} />
                <Route path="requests" component={Requests} />
                <Route path="requests/:requestId" component={Request} />
                <Route
                  path="requests/:requestId/edit"
                  component={RequestEditForm}
                />
                <Route
                  path="requests/:requestId/add-quote"
                  component={AddQuoteForm}
                />
                <Route
                  path="requests/:requestId/:quoteId"
                  component={EditQuoteForm}
                />
              </Route>
              <Route path="*" component={Missing} />
            </Route>
          </Router>
        </AuthProvider>
      </Provider>
    </ApolloProvider>
  </SplitFactory>
);

export default Root;

Root.propTypes = {
  store: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};
