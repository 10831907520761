import { gql } from '@apollo/client';

export const SUPPLIER_PAYMENTS = gql`
  query ($quoteId: String!) {
    supplierPayments(quoteId: $quoteId) {
      uuid
      quote_id
      payment_date
      payment_amount
      payment_method
    }
  }
`;
