import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { initialize, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';
import { updatedDiff } from 'deep-object-diff';
import { getBoroughFromPostcode } from '../../actions/postcode';
import { initialState } from '../../reducers/supplier';
import { generateSlug } from '../../libs/formatting';
import { client as apolloClient } from '../../modules/apollo/apollo';

import {
  createSupplier,
  fetchSupplier,
  updateSupplier,
  setSupplierData,
  deleteSupplier,
  updateSupplierVerticals,
  updateSupplierTags,
  fetchSupplierTags,
  fetchSupplierVerticals,
  updateSupplierTypes,
  fetchSupplierTypes,
  fetchSupplierRecommendations,
  createSupplierRecommendation,
  updateSupplierRecommendation,
  deleteSupplierRecommendation,
  fetchSupplierUsers,
  deleteSupplierUser,
  createSupplierUser,
} from '../../actions/suppliers';
import { fetchUsers, resetUsers } from '../../actions/users';
import setHeaderColor from '../../actions/header';
import {
  getAllTags,
  fetchChildrenTags,
  createTag,
  fetchRootTags,
} from '../../actions/tags';
import { deleteImage } from '../../actions/upload';
import SupplierForm from '../forms/supplier-form';
import { UPDATE_SUPPLIER_PERMISSIONS } from '../../mutations/updateSupplierPermissions';
import { GET_SUPPLIER_PERMISSIONS } from '../../queries/getSupplierPermissions';
import {
  generateSupplierPermissionsMutationVariables,
  generateSupplierPermissionsValues,
} from '../../helpers/permissions';

class Supplier extends Component {
  static propTypes = {
    setHeaderColor: PropTypes.func.isRequired,
    setSupplierData: PropTypes.func.isRequired,
    supplier: PropTypes.shape({
      uuid: PropTypes.string,
    }).isRequired,
    user: PropTypes.shape({}).isRequired,
    params: PropTypes.shape({}).isRequired,
    createSupplier: PropTypes.func.isRequired,
    fetchSupplier: PropTypes.func.isRequired,
    updateSupplier: PropTypes.func.isRequired,
    deleteSupplier: PropTypes.func.isRequired,
    getAllTags: PropTypes.func.isRequired,
    fetchChildrenTags: PropTypes.func.isRequired,
    deleteImage: PropTypes.func.isRequired,
    resetUsers: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    createTag: PropTypes.func.isRequired,
    users: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})),
      paging: PropTypes.shape({}),
      totalRecords: PropTypes.number,
    }).isRequired,
    getBoroughFromPostcode: PropTypes.func.isRequired,
    router: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
  };

  static formatTags = (tags) => tags.map(({ uuid }) => ({ tag_uuid: uuid }));

  state = {
    isLoading: false,
    categoryTier1: null,
    tier2: [],
    products: [],
    tags: [],
    customTags: [],
    types: [],
    profilePicture: {},
    groups: [],
    items: [],
    recommendations: [],
    users: [],
    initialValues: {},
    groupName: '',
    itemName: '',
    itemDescription: '',
    itemImage: null,
    usersLoading: false,
    showSuccess: false,
  };

  componentWillMount() {
    this.props.setHeaderColor('white');
    this.update(this.props);
    this.resetForm(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.supplier.uuid !== prevProps.supplier.uuid) {
      this.resetForm();
    }
  }

  componentWillUnmount() {
    this.props.setSupplierData();
  }

  onSubmit = async (formData) => {
    const { params } = this.props;
    const supplierMethod =
      params.supplierId === 'new' ? this.createSupplier : this.updateSupplier;
    try {
      await supplierMethod(formData);
      this.displaySuccessMessage();
    } catch (err) {
      this.displayErrorMessage(err);
      throw new SubmissionError({ _error: '' });
    }
  };

  setProfilePicture = (newFile) => {
    this.setState({
      profilePicture: newFile,
    });
  };

  async loadTags() {
    this.setState({ isLoading: true });

    await this.props.getAllTags();
    await this.props.fetchChildrenTags();
    await this.props.fetchRootTags();

    this.setState({ isLoading: false });
  }

  update = async () => {
    const { user, router, params } = this.props;

    if (user == null) {
      router.replace('/login');
      return;
    }

    this.loadTags();

    if (params.supplierId === 'new') {
      this.props.setSupplierData();
    } else {
      await this.props.fetchSupplier(params.supplierId);
      const verticals = await fetchSupplierVerticals(params.supplierId);
      const tags = await fetchSupplierTags(params.supplierId);
      const types = await fetchSupplierTypes(params.supplierId);
      const recommendations = await fetchSupplierRecommendations(
        params.supplierId
      );
      const users = await fetchSupplierUsers(params.supplierId);

      const {
        public: { profilePicture },
      } = this.props.supplier;

      const customTags = tags.filter(
        ({ rootTag }) => rootTag?.name === 'Custom'
      );
      const otherTags = tags.filter(
        ({ rootTag }) => rootTag?.name !== 'Custom'
      );

      this.setState({
        categoryTier1: this.props.supplier.categoryTier1,
        tier2: verticals.tier2.map(({ sysName }) => sysName),
        products: verticals.products.map(({ sysName }) => sysName),
        customTags,
        tags: otherTags,
        types,
        profilePicture,
        recommendations,
        users,
      });
    }
  };

  getLocationFromPostcode = async (postcode, oldLocation) => {
    if (!postcode) {
      return oldLocation;
    }

    const {
      location: { lat, lon },
    } = await this.props.getBoroughFromPostcode(postcode);

    return { lat: Number(lat), long: Number(lon) };
  };

  hideSuccess() {
    this.setState({ showSuccess: false });
  }

  displaySuccessMessage() {
    this.setState({ showSuccess: true });
    this.setState({
      errors: null,
    });
    setTimeout(() => this.hideSuccess(), 3000);
  }

  displayErrorMessage(err) {
    this.setState({
      errors: `Errors: ${err?.response?.errors
        ?.map((error) => error.message)
        .join(', ')}`,
    });
  }

  withLocation = async (formData) => {
    const {
      public: {
        tags: discardTags,
        logistics: { postcode, location: oldLocation, ...logistics },
        ...publicData
      },
    } = formData;

    const location = await this.getLocationFromPostcode(postcode, oldLocation);

    return {
      ...formData,
      public: {
        ...publicData,
        logistics: {
          ...logistics,
          location,
        },
      },
    };
  };

  createSupplier = async (formData) => {
    const { categoryTier1, tier2, tags, customTags, types, products } =
      this.state;
    const { permissions, ...otherFormData } = formData;

    const data = await this.withLocation(otherFormData);

    // drop fields handled by dedicated endpoints
    const {
      dietary,
      cuisines,
      products: dropProducts,
      tier2: dropTier2,
      ...supplierData
    } = data;

    const postData = {
      ...supplierData,
      categoryTier1,
      trusted: !!supplierData.trusted,
      visible: !!supplierData.visible,
      algorithmDiscoverable: !!supplierData.algorithmDiscoverable,
      slug: generateSlug(supplierData.name),
    };

    const supplier = await this.props.createSupplier(postData);

    await apolloClient.mutate({
      mutation: UPDATE_SUPPLIER_PERMISSIONS,
      variables: {
        supplierUuid: supplier.payload.uuid,
        ...generateSupplierPermissionsMutationVariables(permissions),
      },
    });

    await updateSupplierVerticals(supplier.payload.uuid, {
      tier2: tier2.map((tier) => (tier && tier.value) || tier),
      products: products.map(
        (product) => (product && product.value) || product
      ),
    });

    await updateSupplierTags(supplier.payload.uuid, {
      tags: Supplier.formatTags(tags.concat(customTags)),
    });
    await updateSupplierTypes(supplier.payload.uuid, {
      tags: Supplier.formatTags(types),
    });

    this.props.router.replace(`/admin/suppliers/${supplier.payload.uuid}`);
  };

  async resetForm() {
    const { supplier, params } = this.props;

    let supplierPermissionsQueryResponse;

    if (supplier.uuid) {
      supplierPermissionsQueryResponse = await apolloClient.query({
        query: GET_SUPPLIER_PERMISSIONS,
        variables: {
          supplierUuid: supplier.uuid,
        },
      });
    } else {
      supplierPermissionsQueryResponse = null;
    }
    const { supplierPermissions } =
      supplierPermissionsQueryResponse?.data?.suppliers?.[0] ?? {};

    if (params.supplierId !== 'new') {
      this.setState({
        initialValues: {
          ...supplier,
          permissions: supplierPermissions?.length
            ? generateSupplierPermissionsValues(supplierPermissions)
            : initialState.permissions,
        },
      });
    } else {
      this.setState({
        initialValues: {
          ...initialState,
          public: {
            ...initialState.public,
            logistics: {
              ...initialState.public.logistics,
              radius: 0,
              location: {},
            },
          },
        },
      });
    }
  }

  updateSupplier = async (formData) => {
    const { supplier } = this.props;
    const {
      categoryTier1,
      tier2,
      products,
      tags,
      customTags,
      types,
      profilePicture,
    } = this.state;
    const { permissions, ...otherFormData } = formData;

    const supplierData = await this.withLocation(otherFormData);

    const formatData = {
      ...supplierData,
      categoryTier1,
      trusted: Boolean(supplierData.trusted),
      visible: Boolean(supplierData.visible),
      algorithmDiscoverable: Boolean(supplierData.algorithmDiscoverable),
      public: {
        ...supplierData.public,
        logistics: {
          ...supplierData.public.logistics,
          VATRegistered: Boolean(supplierData.public.logistics.VATRegistered),
        },
      },
    };

    const changedData = updatedDiff(supplier, formatData);
    const updateData = {
      ...changedData,
      public: {
        ...(changedData.public || {}),
        logistics: {
          ...((changedData.public || {}).logistics || {}),
        },
      },
    };

    if (profilePicture) {
      updateData.public.profilePicture = profilePicture;
    }

    await updateSupplierTags(supplier.uuid, {
      tags: Supplier.formatTags([...tags, ...customTags]),
    });
    await updateSupplierTypes(supplier.uuid, {
      tags: Supplier.formatTags(types),
    });

    await this.props.updateSupplier(supplier.uuid, updateData);

    await apolloClient.mutate({
      mutation: UPDATE_SUPPLIER_PERMISSIONS,
      variables: {
        supplierUuid: supplier.uuid,
        ...generateSupplierPermissionsMutationVariables(permissions),
      },
    });

    await updateSupplierVerticals(supplier.uuid, {
      tier2: tier2.map((tier) => (tier && tier.value) || tier),
      products: products.map(
        (product) => (product && product.value) || product
      ),
    });
  };

  cancelClick = () => {
    this.props.router.replace('/admin/suppliers');
  };

  deleteSupplier = () => {
    const { supplier } = this.props;

    if (window.confirm('Are you sure you want to delete this supplier?')) {
      this.props.deleteSupplier(supplier.uuid);
    }
  };

  updateVerticals = (fields) => {
    this.setState(fields);
  };

  updateTags = (value) => this.setState({ tags: value });

  updateTypes = (value) => this.setState({ types: value });

  updateCustomTags = (value) => this.setState({ customTags: value });

  createCustomTag = async ({ name }) => {
    const { rootTags } = this.props;

    const customRootTag = rootTags.find((tag) => tag.name === 'Custom');

    const data = {
      name,
      pid: '10',
      root_tag_id: customRootTag.uuid,
    };

    const { customTags } = this.state;

    const { payload } = await this.props.createTag(data);

    const value = customTags.concat([{ ...payload, tagId: payload.id }]);
    this.setState({ customTags: value });
  };

  removeProfilePicture = (file) => {
    this.setState({ profilePicture: {} }, () => this.props.deleteImage(file));
  };

  handleStateChange = (value, property) => this.setState({ [property]: value });

  addRecommendation = async (data) => {
    const { supplier } = this.props;

    const recommendation = await createSupplierRecommendation(
      supplier.uuid,
      data
    );

    this.setState({
      recommendations: [recommendation],
    });
  };

  deleteRecommendation = async (recommendationId) => {
    if (
      window.confirm('Are you sure you want to delete this recommendation?')
    ) {
      const { supplier } = this.props;

      await deleteSupplierRecommendation(supplier.uuid, recommendationId);

      this.setState({
        recommendations: [],
      });
    }
  };

  updateRecommendation = async (recommendationId, data) => {
    const { supplier } = this.props;

    const recommendation = await updateSupplierRecommendation(
      supplier.uuid,
      recommendationId,
      data
    );

    this.setState({
      recommendations: [recommendation],
    });
  };

  removeUser = async (userId) => {
    if (
      window.confirm(
        'Are you sure you want to remove this user from the supplier?'
      )
    ) {
      await deleteSupplierUser(this.props.supplier.uuid, userId);

      const { users } = this.state;
      const value = users.filter(({ userId: id }) => id !== userId);

      this.setState({ users: value });
    }
  };

  searchUsers = async () => {
    const { userSearch } = this.state;
    this.setState({ usersLoading: true });

    await this.props.fetchUsers({ q: userSearch, limit: 10, offset: 0 });

    this.setState({
      usersLoading: false,
    });
  };

  addUser = async (userId) => {
    const { users } = this.state;
    const { supplier } = this.props;

    const newUser = await createSupplierUser(supplier.uuid, userId);

    const value = users.concat([newUser]);

    this.props.resetUsers();

    this.setState({
      users: value,
      userSearch: '',
    });
  };

  render() {
    const { supplier, users } = this.props;
    const { initialValues, showSuccess, errors } = this.state;

    return (
      <main className="view view--bookings view--supplier">
        <div className="container page-title clearfix">
          <h1 className="pull-left">Supplier&#8217;s Profile</h1>
          <Link className="footer__marque pull-right" to="/admin/suppliers">
            {'< Back to all Suppliers'}
          </Link>
        </div>
        <div className="container">
          {!this.state.isLoading && (
            <SupplierForm
              initialValues={initialValues}
              data={supplier}
              onSubmit={this.onSubmit}
              showSuccess={showSuccess}
              errors={errors}
              cancelClick={this.cancelClick}
              deleteClick={this.deleteSupplier}
              tier2={this.state.tier2}
              products={this.state.products}
              supplierTags={this.state.tags}
              supplierTypes={this.state.types}
              supplierCustomTags={this.state.customTags}
              profilePicture={this.state.profilePicture}
              supplierGroups={this.state.groups}
              supplierUsers={this.state.users}
              groupName={this.state.groupName}
              setUploadedFile={this.setUploadedFile}
              setProfilePicture={this.setProfilePicture}
              updateVerticals={this.updateVerticals}
              updateTags={this.updateTags}
              updateTypes={this.updateTypes}
              handleStateChange={this.handleStateChange}
              recommendations={this.state.recommendations}
              addRecommendation={this.addRecommendation}
              updateRecommendation={this.updateRecommendation}
              deleteRecommendation={this.deleteRecommendation}
              removeUser={this.removeUser}
              addUser={this.addUser}
              searchUsers={this.searchUsers}
              userSearchResults={users.items}
              usersLoading={this.state.usersLoading}
              createCustomTag={this.createCustomTag}
              updateCustomTags={this.updateCustomTags}
              categoryTier1={this.state.categoryTier1}
              removeProfilePicture={this.removeProfilePicture}
            />
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = ({ supplier, user, users, rootTags }) => ({
  supplier,
  user,
  users,
  rootTags,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSupplierData,
      createSupplier,
      fetchSupplier,
      updateSupplier,
      deleteSupplier,
      deleteImage,
      getAllTags,
      fetchRootTags,
      fetchChildrenTags,
      setHeaderColor,
      resetForm: initialize,
      fetchUsers,
      resetUsers,
      createTag,
      getBoroughFromPostcode,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Supplier)
);
