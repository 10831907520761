import jwtDecode from 'jwt-decode';
import { fetchAuthSession } from '@aws-amplify/auth';
import { COGNITO_IDENTITY_FIELDS } from '../constants/cognito-identity-fields';
import BrowserStorageIdentityService from '../services/browser-storage-identity-service';
import { store } from '../../index';
import { setUser } from '../actions/user';

const ADMIN_GROUP_NAME = 'admin';

export const isCognitoAuthentication = (token) => {
  const decoded = jwtDecode(token);

  return Array.isArray(decoded[COGNITO_IDENTITY_FIELDS.GROUPS]);
};

export const isCognitoUserAdmin = (token) => {
  const decoded = jwtDecode(token);

  return decoded[COGNITO_IDENTITY_FIELDS.GROUPS].includes(ADMIN_GROUP_NAME);
};

const refreshToken = (tokens) => {
  const identityService = new BrowserStorageIdentityService();
  const accessToken = tokens?.accessToken?.toString();

  identityService.setIdentity({
    ...identityService.getIdentity(),
    token: accessToken,
  });
};

export const getLatestCognitoToken = async () => {
  let session = await fetchAuthSession();

  if (!session.tokens) {
    // If no tokens are available, log user out
    store.dispatch(setUser(null));
    return null;
  }

  if (session.tokens) {
    refreshToken(session.tokens);
  }
  if (session.tokens.accessToken?.payload?.exp <= Date.now() / 1000) {
    session = await fetchAuthSession({ forceRefresh: true });
  }

  return session.tokens?.accessToken?.toString();
};
