import {
  DELETE_USER,
  FETCH_USERS,
  RESET_USERS,
  FETCH_USER,
} from '../constants/action-types';

const initialState = {
  items: [],
  paging: {},
};

function users(state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS:
      return Object.assign({}, state, {
        items: action.payload.results,
        paging: action.payload.paging,
        totalRecords: action.payload.totalRecords,
      });
    case RESET_USERS:
      return initialState;
    case DELETE_USER:
      return Object.assign({}, state, {
        items: state.items.filter((item) => item.id !== action.payload.id),
      });
    default:
      return state;
  }
}

export const customer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER:
      return action.payload;
    default:
      return state;
  }
};

export default users;
