import { takeLatest, call, put } from 'redux-saga/effects';
import { replace } from 'react-router-redux';
import {
  FETCH_REQUESTS,
  GET_ALL_REQUESTS,
  FETCH_REQUEST,
  GET_REQUEST,
  ADD_QUOTE,
  UPDATE_QUOTE,
  DELETE_QUOTE,
  UPDATE_REQUEST,
  CLOSE_REQUEST,
  RUN_ROBEX,
  FETCH_ROBEX_STARTED,
  FETCH_ROBEX_COMPLETE,
  FETCH_ROBEX_FAILED,
  SEND_ALL_QUOTES_EMAIL_FAILED,
  SEND_ALL_QUOTES_EMAIL_COMPLETE,
  SEND_ALL_QUOTES_EMAIL_STARTED,
  SEND_ALL_QUOTES_EMAIL,
  DELETE_REQUEST,
} from '../constants/action-types';
import { request } from './api';

function* fetchRequests({ page, pageSize, query }) {
  const requests = yield call(request, {
    url: `/admin/event-requests/all/${page}?limit=${pageSize}&q=${query}`,
    method: 'GET',
  });
  yield put({
    type: FETCH_REQUESTS,
    payload: requests,
  });
}

function* fetchRequest({ requestId }) {
  const eventRequest = yield call(request, {
    url: `/admin/event-requests/${requestId}`,
    method: 'GET',
  });

  yield put({
    type: FETCH_REQUEST,
    payload: eventRequest,
  });
}

function* updateRequest({ requestId, data }) {
  yield call(request, {
    url: `/admin/event-requests/${requestId}`,
    method: 'PATCH',
    data,
  });

  yield fetchRequest({ requestId });
}

function* addQuote({ quote }) {
  const {
    covers,
    total,
    supplier_id,
    supportText,
    published,
    commission_fee,
    service_fee,
  } = quote;
  const quoteData = {
    covers,
    total,
    supplier_id,
    supportText,
    published,
    commission_fee,
    service_fee,
  };

  yield call(request, {
    url: `/event-requests/${quote.request_id}/quotes`,
    method: 'POST',
    data: quoteData,
  });

  yield fetchRequest({ requestId: quote.request_id });
}

function* updateQuote({ uuid, requestId, quote }) {
  const { updated, eventRequest, payments, ...quoteUpdateFields } = quote;

  yield call(request, {
    url: `/admin/quotes/${uuid}`,
    method: 'PATCH',
    data: quoteUpdateFields,
  });

  yield fetchRequest({ requestId });
}

function* closeRequest({ requestId }) {
  yield call(request, {
    url: `/admin/event-requests/${requestId}/soft`,
    method: 'DELETE',
  });

  yield fetchRequest({ requestId });
}

function* deleteRequest({ requestId }) {
  yield call(request, {
    url: `/admin/event-requests/${requestId}/hard`,
    method: 'DELETE',
  });

  yield put(replace(`/admin/requests`));
}

function* deleteQuote({ quoteId, requestId }) {
  yield call(request, {
    url: `/admin/quotes/${quoteId}`,
    method: 'DELETE',
  });
  if (requestId) {
    yield put(replace(`/admin/requests/${requestId}`));
  }
}

function* runRobex({ requestId }) {
  yield put({
    type: FETCH_ROBEX_STARTED,
  });

  try {
    const results = yield call(request, {
      url: `/admin/event-requests/robex/${requestId}`,
      method: 'GET',
    });

    yield put({
      type: FETCH_ROBEX_COMPLETE,
      payload: results,
    });
  } catch (error) {
    yield put({
      type: FETCH_ROBEX_FAILED,
      payload: error.response?.data?.message,
    });
  }
}

function* sendAllQuotesEmail({ requestId }) {
  yield put({
    type: SEND_ALL_QUOTES_EMAIL_STARTED,
  });

  try {
    yield call(request, {
      url: `/admin/event-requests/emails/all-quotes`,
      method: 'POST',
      data: {
        uuid: requestId,
      },
    });

    yield put({
      type: SEND_ALL_QUOTES_EMAIL_COMPLETE,
    });

    yield fetchRequest({ requestId });
  } catch (error) {
    yield put({
      type: SEND_ALL_QUOTES_EMAIL_FAILED,
      payload: error.response?.data?.message,
    });
  }
}

export default function* requestsSaga() {
  yield takeLatest(GET_ALL_REQUESTS, fetchRequests);
  yield takeLatest(GET_REQUEST, fetchRequest);
  yield takeLatest(UPDATE_REQUEST, updateRequest);
  yield takeLatest(CLOSE_REQUEST, closeRequest);
  yield takeLatest(DELETE_REQUEST, deleteRequest);
  yield takeLatest(ADD_QUOTE, addQuote);
  yield takeLatest(UPDATE_QUOTE, updateQuote);
  yield takeLatest(DELETE_QUOTE, deleteQuote);
  yield takeLatest(RUN_ROBEX, runRobex);
  yield takeLatest(SEND_ALL_QUOTES_EMAIL, sendAllQuotesEmail);
}
