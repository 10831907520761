import {
  SEND_ALL_QUOTES_EMAIL_STARTED,
  SEND_ALL_QUOTES_EMAIL_COMPLETE,
  SEND_ALL_QUOTES_EMAIL_FAILED,
} from '../constants/action-types';

const initialState = {
  loading: false,
  error: '',
};

function allQuotesEmail(state = initialState, action) {
  switch (action.type) {
    case SEND_ALL_QUOTES_EMAIL_STARTED:
      return { ...initialState, loading: true };
    case SEND_ALL_QUOTES_EMAIL_COMPLETE:
      return {
        error: false,
        loading: false,
      };
    case SEND_ALL_QUOTES_EMAIL_FAILED:
      return { ...initialState, error: action.payload };
    default:
      return state;
  }
}

export default allQuotesEmail;
