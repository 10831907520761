import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { httpService } from '../../../app-config';
import MultiSelect from '../../components/multi-select';
import Spinner from '../../components/spinner';
import Asterisk from '../../components/asterisk';

const withLabels = ({ sysName, name }) => ({ value: sysName, label: name });

function useValues(categoryTier1, setLoading) {
  const [tier1Values, setTier1Values] = useState([]);
  const [tier2Values, setTier2Values] = useState([]);

  useEffect(() => {
    setLoading(true);

    let cancelled = false;
    const load = async () => {
      const tier1 = await httpService.request({ path: `/verticals/tier1` });

      if (!cancelled) {
        setTier1Values(tier1.map(withLabels));

        setLoading(false);
      }
    };

    load();

    return () => {
      cancelled = true;
    };
  }, [setLoading]);

  useEffect(() => {
    if (!categoryTier1) {
      return () => null;
    }

    let cancelled = false;
    const load = async () => {
      const tier2 = await httpService.request({
        path: `/verticals/tier2/${categoryTier1}`,
      });

      if (!cancelled) {
        setTier2Values(tier2.map(withLabels));
      }
    };

    load();

    return () => {
      cancelled = true;
    };
  }, [categoryTier1]);

  return [tier1Values, tier2Values];
}

export default function SupplierVerticals({
  categoryTier1,
  tier2,
  products,
  onChange,
}) {
  const [loading, setLoading] = useState(false);
  const [productValues, setProductValues] = useState([]);
  const [tier1Values, tier2Values] = useValues(categoryTier1, setLoading);

  useEffect(() => {
    let cancelled = false;
    const load = async () => {
      const products = await httpService.request({ path: `/products` });

      if (!cancelled) {
        setProductValues(products.map(withLabels));

        setLoading(false);
      }
    };

    load();

    return () => {
      cancelled = true;
    };
  }, []);

  const onChangeTier1 = useCallback(
    ({ value }) => {
      onChange({ categoryTier1: value, tier2: [] });
    },
    [onChange]
  );

  const onChangeTier2 = useCallback(
    (tier2) => {
      onChange({ tier2 });
    },
    [onChange]
  );

  const onChangeProducts = useCallback(
    (products) => {
      onChange({ products });
    },
    [onChange]
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="section field">
        <label className="field__label field__label--touched form-control-label">
          Tier 1 Vertical
          <Asterisk required={true} />
        </label>
        <Select
          value={categoryTier1}
          onChange={onChangeTier1}
          multi={false}
          options={tier1Values}
        />
      </div>

      <MultiSelect
        label="Tier 2 Vertical"
        options={tier2Values}
        onChangeHandler={onChangeTier2}
        value={tier2}
      />

      <MultiSelect
        label="Products"
        options={productValues}
        onChangeHandler={onChangeProducts}
        value={products}
      />
    </>
  );
}

SupplierVerticals.propTypes = {
  categoryTier1: PropTypes.string,
  tier2: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  products: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
};

SupplierVerticals.defaultProps = {
  categoryTier1: null,
};
