import { FETCH_SETTINGS, FETCH_SETTING } from '../constants/action-types';
import { httpService } from '../../app-config';

export function fetchSettings() {
  return (dispatch) =>
    httpService
      .request({ path: `/admin/settings` })
      .then((response) => response)
      .then((payload) => dispatch({ type: FETCH_SETTINGS, payload }));
}

export function updateSetting(setting) {
  return (dispatch) =>
    httpService
      .request({
        path: `/admin/settings/${setting.option_name}`,
        method: 'PATCH',
        body: { option_value: setting.option_value },
      })
      .then((response) => response)
      .then((payload) => dispatch({ type: FETCH_SETTING, payload }));
}
