import { FETCH_QUOTES } from '../constants/action-types';

const initialState = {
  results: [],
  totalRecords: 0,
};

function quotes(state = initialState, action) {
  switch (action.type) {
    case FETCH_QUOTES:
      return action.payload;
    default:
      return state;
  }
}

export default quotes;
