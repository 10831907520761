import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { useDataField } from '../hooks/field';
import Button from './button';
import Asterisk from './asterisk';

function SupplierRecommendation({
  data,
  newRecommendation,
  teamMembersOptions,
  addRecommendation: onAddRecommendation,
  updateRecommendation: onUpdateRecommendation,
  deleteRecommendation: onDeleteRecommendation,
}) {
  const [recommendation, setRecommendation] = useDataField(
    data,
    'recommendation'
  );
  const [highlight, setHighlight] = useDataField(data, 'highlight');
  const [teamMemberName, setTeamMemberName] = useDataField(
    data,
    'teamMemberName'
  );

  const [isEditing, setEditing] = useState(Boolean(newRecommendation));
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setEditing(Boolean(newRecommendation));
  }, [newRecommendation]);

  const validate = useCallback(() => {
    const nextErrors = {};

    if (!teamMemberName) {
      nextErrors.teamMemberName = true;
    }
    if (!highlight) {
      nextErrors.highlight = true;
    }
    if (!recommendation) {
      nextErrors.recommendation = true;
    }

    setErrors(nextErrors);

    return nextErrors;
  }, [highlight, recommendation, teamMemberName]);

  const addRecommendation = useCallback(() => {
    const nextErrors = validate();
    if (Object.keys(nextErrors).length) {
      return;
    }

    onAddRecommendation({
      recommendation,
      highlight,
      teamMemberName,
    });

    setEditing(false);
  }, [
    validate,
    onAddRecommendation,
    recommendation,
    highlight,
    teamMemberName,
  ]);

  const updateRecommendation = useCallback(() => {
    const nextErrors = validate();
    if (Object.keys(nextErrors).length) {
      return;
    }

    onUpdateRecommendation(data.uuid, {
      recommendation,
      highlight,
      teamMemberName,
    });

    setEditing(false);
  }, [
    validate,
    data.uuid,
    onUpdateRecommendation,
    recommendation,
    highlight,
    teamMemberName,
  ]);

  const deleteRecommendation = useCallback(() => {
    onDeleteRecommendation(data.uuid);
  }, [onDeleteRecommendation, data.uuid]);

  const editRecommendation = useCallback(() => {
    setEditing(true);
  }, []);

  const handleRecommendationChange = useCallback(
    (event) => {
      setRecommendation(event.target.value);
    },
    [setRecommendation]
  );

  const handleHighlightChange = useCallback(
    (event) => {
      setHighlight(event.target.value);
    },
    [setHighlight]
  );

  const handleTeamMemberChange = useCallback(
    ({ value }) => {
      setTeamMemberName(value);
    },
    [setTeamMemberName]
  );

  return (
    <div className="supplier-recommendation">
      <div className="field">
        <label className="field__label form-control-label">
          {isEditing ? 'Select a team member' : 'Team member'}
          {isEditing && <Asterisk required />}
        </label>
        {isEditing ? (
          <Select
            value={teamMemberName}
            onChange={handleTeamMemberChange}
            multi={false}
            options={teamMembersOptions.map((o) => ({
              value: o.name,
              label: o.name,
            }))}
          />
        ) : (
          <input
            className="field__input form-control"
            readOnly
            value={teamMemberName}
          />
        )}
        {errors.teamMemberName && (
          <span className="supplier-recommendation__error">Required</span>
        )}
      </div>
      <div className="field">
        <label className="field__label form-control-label">
          Highlight
          {isEditing && <Asterisk required />}
        </label>
        <textarea
          readOnly={!isEditing}
          onChange={handleHighlightChange}
          className="field__input form-control"
          value={highlight}
        />
        {errors.highlight && (
          <span className="supplier-recommendation__error">Required</span>
        )}
      </div>
      <div className="field">
        <label className="field__label form-control-label">
          Recommendation
          {isEditing && <Asterisk required />}
        </label>
        <textarea
          readOnly={!isEditing}
          onChange={handleRecommendationChange}
          className="field__input form-control"
          value={recommendation}
        />
        {errors.recommendation && (
          <span className="supplier-recommendation__error">Required</span>
        )}
      </div>

      <div className="supplier-recommendation__buttons">
        {isEditing && !newRecommendation && (
          <Button onClick={updateRecommendation} color="green">
            Save recommendation
          </Button>
        )}
        {isEditing && newRecommendation && (
          <Button onClick={addRecommendation} color="green">
            Add recommendation
          </Button>
        )}
        {!isEditing && (
          <Button onClick={editRecommendation} color="grey">
            Edit recommendation
          </Button>
        )}
        {!isEditing && !newRecommendation && (
          <Button onClick={deleteRecommendation} color="red">
            Delete recommendation
          </Button>
        )}
      </div>
    </div>
  );
}

SupplierRecommendation.propTypes = {
  data: PropTypes.shape({
    uuid: PropTypes.string,
    highlight: PropTypes.string,
    recommendation: PropTypes.string,
    teamMemberName: PropTypes.string,
  }),
  teamMembersOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  addRecommendation: PropTypes.func,
  updateRecommendation: PropTypes.func,
  deleteRecommendation: PropTypes.func,
  newRecommendation: PropTypes.bool,
};

SupplierRecommendation.defaultProps = {
  data: {},
  newRecommendation: null,
  addRecommendation: null,
  updateRecommendation: null,
  deleteRecommendation: null,
};

export default SupplierRecommendation;
