import { gql } from '@apollo/client';

export const GET_SUPPLIER_PERMISSIONS = gql`
  query GetSupplierPermissions($supplierUuid: String!) {
    suppliers(where: { uuid: { in: [$supplierUuid] } }) {
      supplierPermissions {
        uuid
        permissionType
      }
    }
  }
`;
