import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import debounce from 'just-debounce';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
// Components
import TableList from '../../components/table-list';
import TableSearch from '../../components/table-search';
import Button from '../../components/button';
// Actions
import { fetchPartnerships } from '../../actions/partnerships';
import setHeaderColor from '../../actions/header';

import { getStateFromQuery } from '../../libs/pagination';

class Partnerships extends Component {
  loadPartnerships = debounce(() => {
    this.setState({ isLoading: true });
    this.props
      .fetchPartnerships({
        q: this.state.query,
        limit: this.state.limit,
        page: this.state.page,
      })
      .then(() => this.setState({ isLoading: false }))
      .catch((err) => {
        if (err.status === 401) {
          this.props.setUser(null);
        }
      });
  }, 10);

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      ...getStateFromQuery(this.props),
    };

    this.props.setHeaderColor('white');
  }

  componentDidMount() {
    this.loadPartnerships();
  }

  changeQuery = (query) => {
    this.setState({ query, page: 0 }, this.loadPartnerships);
  };

  onFetchData = (state) =>
    this.setState(
      {
        page: state.page,
        limit: state.pageSize,
      },
      this.loadPartnerships
    );

  addPartnership = () => {
    this.props.router.push('/admin/partnerships/new');
  };

  render() {
    const { partnerships, paging } = this.props;

    const columns = [
      {
        name: 'name',
        title: 'Name',
      },
      {
        name: 'slug',
        title: 'Slug',
      },
      {
        name: 'commission',
        title: 'Commission',
      },
      {
        name: 'postcode',
        title: 'Postcode',
      },
      {
        name: 'uuid',
        title: 'Manage',
        width: 140,
        cell: ({ value }) => {
          return (
            <Link to={`/admin/partnerships/${value}`} activeClassName="t--bold">
              <Button type="button" className="button button--green pull-left">
                View / Edit
              </Button>
            </Link>
          );
        },
      },
    ];

    return (
      <main className="view">
        <div className="container page-title">
          <h1>Partnerships</h1>
        </div>{' '}
        <div className="container clearfix">
          <TableSearch
            className="pull-left"
            label="by name"
            input={this.state.query}
            onChange={this.changeQuery}
          />
          <Button
            className="pull-right"
            color="grey"
            text="+ Add new partnership"
            icon="filter"
            onClick={this.addPartnership}
          />
        </div>
        <div className="row--padded-m">
          <div className="container clearfix">
            <TableList
              pages={paging}
              data={partnerships}
              columns={columns}
              manual
              onFetchData={this.onFetchData}
            />
          </div>
        </div>
      </main>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPartnerships,
    },
    dispatch
  );
}

const mapStateToProps = ({ partnerships, user }) => ({
  partnerships: partnerships.items,
  paging: partnerships.paging,
  setHeaderColor,
  user,
});

Partnerships.propTypes = {
  partnerships: PropTypes.shape({}).isRequired,
  fetchPartnerships: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  setHeaderColor: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, matchDispatchToProps)(Partnerships);
