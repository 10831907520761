import {
  SET_QUOTE,
  FETCH_QUOTE,
  ADD_QUOTE,
  UPDATE_QUOTE,
  DELETE_QUOTE,
  FETCH_QUOTES,
} from '../constants/action-types';
import { httpService } from '../../app-config';
import queryString from 'query-string';

export function setQuote(data) {
  return (dispatch) => dispatch({ type: SET_QUOTE, payload: data });
}

export const fetchQuotes =
  ({ query = '', limit = 10, page = 0, quoteState = null }) =>
  async (dispatch) => {
    const params = queryString.stringify({
      q: query,
      limit: limit,
      state: quoteState ? quoteState : undefined,
    });
    const payload = await httpService.request({
      path: `/admin/quotes/all/${page}?${params}`,
    });

    return dispatch({ type: FETCH_QUOTES, payload });
  };

export const adminCancelQuote = (quoteId) =>
  httpService.request({
    path: `/admin/quotes/cancel/${quoteId}`,
    method: 'POST',
  });

export const adminUndeclineQuote = (quoteUuid) =>
  httpService.request({
    path: `/admin/quotes/undecline/${quoteUuid}`,
    method: 'POST',
  });

export const fetchQuote = (id) => (dispatch) =>
  httpService
    .request({
      path: `/admin/quotes/${id}`,
    })
    .then((payload) => dispatch({ type: FETCH_QUOTE, payload }));

export const addQuote = (quote) => ({
  type: ADD_QUOTE,
  quote,
});

export const updateQuote = (uuid, requestId, quote) => ({
  type: UPDATE_QUOTE,
  quote,
  requestId,
  uuid,
});

export const deleteQuote = ({ id }, requestId) => ({
  type: DELETE_QUOTE,
  quoteId: id,
  requestId,
});
