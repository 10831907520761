import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { connect as withFormik } from 'formik';
import { useDebounce } from 'use-debounce';
import classNames from 'classnames';

import { fetchSuppliers } from '../../actions/suppliers';
import { useOnClick } from '../../hooks/anchor';

const SupplierSelector = ({ uuid, name, onClick }) => {
  const handlers = useOnClick(onClick, uuid, name);

  return (
    <a key={uuid} className="field--supplier__list__link" {...handlers}>
      {name}
    </a>
  );
};

SupplierSelector.propTypes = {
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const SupplierField = ({
  name,
  label,
  labelText,
  formik: { errors, touched, setFieldValue },
  suppliers,
  onFetchSuppliers,
}) => {
  const [search, setSearch] = useState({ query: '', auto: false });
  const [searchQuery] = useDebounce(search, 200);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (searchQuery.query && !searchQuery.auto) {
      onFetchSuppliers(0, { name: searchQuery.query });
    }
  }, [searchQuery, onFetchSuppliers]);

  const onSelectSupplier = useCallback(
    (uuid, supplierName) => {
      setFieldValue(name, uuid);
      setSearch({ query: supplierName, auto: true });
      setSuggestions([]);
    },
    [setFieldValue, name]
  );

  const haveQuery = Boolean(search.query.length);
  const haveSuggestions = haveQuery && Boolean(suppliers.length);
  useEffect(() => {
    setSuggestions(haveSuggestions ? suppliers : []);
  }, [haveSuggestions, suppliers]);

  useEffect(() => {
    if (!haveQuery) {
      setFieldValue(name, null);
    }
  }, [haveQuery, name, setFieldValue]);

  return (
    <div className="field field--supplier">
      <label
        htmlFor={label}
        className={classNames('field__label', 'form-control-label', {
          'field__label--touched': touched[name],
        })}
      >
        {labelText}
      </label>
      <div className="field--supplier__main">
        <input
          id={label}
          className={classNames('field__input', 'form-control')}
          type="text"
          value={search.query}
          onChange={({ target: { value } }) =>
            setSearch({
              query: value,
              auto: false,
            })
          }
        />
        {suggestions.length > 0 && (
          <ul className="field--supplier__list">
            {suppliers.map(({ uuid, name: supplierName }) => (
              <SupplierSelector
                key={uuid}
                uuid={uuid}
                name={supplierName}
                onClick={onSelectSupplier}
              />
            ))}
          </ul>
        )}
        {touched[name] && errors[name] && (
          <span className="field__error">{errors[name]}</span>
        )}
      </div>
    </div>
  );
};

SupplierField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  formik: PropTypes.shape({
    errors: PropTypes.objectOf(PropTypes.string).isRequired,
    touched: PropTypes.objectOf(PropTypes.bool).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  suppliers: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onFetchSuppliers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  suppliers: state.suppliers.items,
});

const mapDispatchToProps = {
  onFetchSuppliers: fetchSuppliers,
};

export default withFormik(
  connect(mapStateToProps, mapDispatchToProps)(SupplierField)
);
