import { fork } from 'redux-saga/effects';

import supplierReviewsSaga from './supplier-reviews';
import eventsSaga from './events';
import requestsSaga from './requests';

export default function* rootSaga() {
  yield fork(supplierReviewsSaga);
  yield fork(eventsSaga);
  yield fork(requestsSaga);
}
