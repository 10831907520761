import { useState, useEffect } from 'react';

export function useDataField(
  data,
  field,
  initialValue = '',
  defaultValue = ''
) {
  const [value, setValue] = useState(initialValue);
  const propValue = data[field];
  useEffect(() => {
    setValue(propValue || defaultValue);
  }, [propValue, defaultValue]);

  return [value, setValue];
}
