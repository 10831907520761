import * as React from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';

import { MARK_SUPPLIER_AS_PAID } from '../../mutations/mark-supplier-as-paid';
import { SUPPLIER_PAYMENTS } from '../../queries/supplier-payments';
import Quote from './quote';

export const QuoteWithMarkSupplierAsPaid = (props) => {
  const [markSupplierAsPaid] = useMutation(MARK_SUPPLIER_AS_PAID);
  const [getSupplierPayments, { data }] = useLazyQuery(SUPPLIER_PAYMENTS);

  return (
    <Quote
      {...props}
      markSupplierAsPaid={markSupplierAsPaid}
      supplierPayments={data?.supplierPayments}
      getSupplierPayments={getSupplierPayments}
    />
  );
};
