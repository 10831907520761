import { gql } from '@apollo/client';

export const RUN_VENUES_MATCHING = gql`
  mutation runVenuesMatching(
    $requestId: String!
    $callMethod: CallMethod!
    $sendToFreshsales: Boolean
  ) {
    runVenuesMatching(
      requestId: $requestId
      callMethod: $callMethod
      sendToFreshsales: $sendToFreshsales
    ) {
      coreMatchedSpaces {
        uuid
        name
      }
      coreMatchedSuppliersCount
    }
  }
`;
