import { getStoredIdentity } from '../services/browser-storage-identity-service';
import {
  isCognitoAuthentication,
  getLatestCognitoToken,
} from '../modules/cognito';

export async function withAuthHeader(req, identityService = null) {
  const identity = identityService
    ? identityService.getIdentity()
    : getStoredIdentity();

  const token = (identity && identity.token) || req.token;

  if (!token) {
    return req;
  }

  const authorization = `Bearer ${token}`;
  const headers = { ...req.headers, authorization };

  if (isCognitoAuthentication(token)) {
    return {
      ...req,
      headers: {
        ...headers,
        authorization: `Bearer ${await getLatestCognitoToken()}`,
        'x-new-auth': true,
        'x-user-id': identity.id,
      },
    };
  }

  return { ...req, headers };
}

export default function createAuthorization({ identityService }) {
  return async function Authorization(request, next) {
    return next(await withAuthHeader(request, identityService));
  };
}
