import React from 'react';

const Spinner = () => (
  <svg className="spinner" viewBox="0 0 50 50">
    <circle
      className="spinner__static"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="8"
    />
    <circle
      className="spinner__rotating"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="8"
      strokeDashoffset="0"
      strokeDasharray="95, 360"
    />
  </svg>
);

export default Spinner;
