import { FETCH_REQUESTS, FETCH_REQUEST } from '../constants/action-types';

export const requests = (state = {}, action) => {
  switch (action.type) {
    case FETCH_REQUESTS:
      return action.payload;
    default:
      return state;
  }
};

export const requestDetails = (state = {}, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
      return action.payload;
    default:
      return state;
  }
};
