import { SET_USER } from '../constants/action-types';

const initialState = null;

export default function (state = initialState, { type, meta, payload }) {
  if (type === SET_USER) {
    return payload === null ? null : Object.assign({}, state, payload);
  }
  return state;
}
