import { useSplitTreatments } from '@splitsoftware/splitio-react';
import { IMPERSONATION_V2_FEATURE } from '../constants/impersonationV2';
import { getStoredIdentity } from '../services/browser-storage-identity-service';
import { isCognitoAuthentication } from '../modules/cognito';

export function useImpersonationV2() {
  const { treatments, isReady } = useSplitTreatments({
    names: [IMPERSONATION_V2_FEATURE],
  });
  const { token } = getStoredIdentity() ?? {};

  return {
    isImpersonationV2Enabled:
      treatments[IMPERSONATION_V2_FEATURE].treatment === 'on' &&
      isReady &&
      isCognitoAuthentication(token),
  };
}
