import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import debounce from 'just-debounce';
import { toPounds, toPennies } from '@feast-it/finance';

import { Link } from 'react-router';
import isEmpty from 'lodash/isEmpty';

import { addQuote } from '../../actions/quotes';
import {
  resetSupplier,
  fetchSupplier,
  fetchSuppliers,
} from '../../actions/suppliers';
import { resetEvent } from '../../actions/event';
import formField from '../../components/form-field';
import formCheckbox from '../../components/form-checkbox';
import TableSearch from '../../components/table-search';
import TableList from '../../components/table-list';
import Button from '../../components/button';
import DietaryCalculator from '../partials/dietary-calculator';
import TextEditor from '../../components/text-editor';

import { replaceLinebreaksWithSpaces } from '../../libs/formatting';
import { calculateCostBreakdown } from '../../libs/finance';

const handleOnSubmit = (data, dispatch, props) => {
  const { event, supplier, request } = props;

  const supportText = replaceLinebreaksWithSpaces(
    data.supportText ? data.supportText : ''
  );

  const { baseTotal } = calculateCostBreakdown({
    total: toPounds(data.total),
    commission: data.commission_fee || supplier.public.commission,
    old_commission: false,
  });

  const quote = {
    ...data,
    dietary: {
      tags: event.dietaryItems,
      notes: data.dietary ? data.dietary.notes : '',
    },
    total: Math.round(baseTotal),
    supplier_id: supplier.uuid,
    request_id: request.id,
    menuPrice: supplier.public.basePrice,
    supportText,
    published: data.published === true,
  };

  if (isEmpty(data.commission_fee)) {
    delete quote.commission_fee;
  }

  if (isEmpty(data.service_fee)) {
    delete quote.service_fee;
  }

  if (supplier.uuid) {
    return dispatch(addQuote(quote));
  }
};

const handleOnSubmitSuccess = (result, dispatch, props) =>
  props.router.push(`/admin/requests/${props.params.requestId}`);

class QuoteForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      query: '',
    };
  }

  componentWillMount() {
    this.loadVendors();
    this.resetSupplier();
  }

  setVendor({ id }) {
    this.setState({ supplier_id: id, isLoading: true });
    this.props.fetchSupplier(id).then(() => {
      this.setState({ isLoading: false });
      this.props.resetEvent();
    });
  }

  resetSupplier() {
    this.props.resetSupplier();
  }

  loadVendors = debounce(({ name = '', limit = 30, offset = 0 } = {}) => {
    this.setState({ isLoading: true });
    this.props
      .fetchSuppliers(0, { name, limit, offset })
      .then(() => this.setState({ isLoading: false }))
      .catch((err) => {
        if (err.status === 401) {
          this.props.setUser(null);
        }
      });
  }, 100);

  changeQuery = (query) => {
    this.setState({ query });
    this.loadVendors({ name: query });
  };

  render() {
    const {
      handleSubmit,
      error,
      submitFailed,
      submitting,
      suppliers,
      supplier,
      params: { requestId },
    } = this.props;

    const columns = [
      {
        name: 'uuid',
        title: 'Id',
        width: 150,
        cell: (props) => (
          <Button
            color="blue"
            onClick={this.setVendor.bind(this, { id: props.value })}
            text="Select"
          />
        ),
      },
      {
        name: 'name',
        title: 'Supplier Name',
      },
    ];

    return (
      <div>
        <div className="container">
          <div className="clearfix">
            <Link
              className="pull-left"
              to={`/admin/requests/${requestId}`}
              style={{ margin: '10px 0' }}
            >
              <button type="button" className="button button--grey-light">
                &laquo; Back to Request
              </button>
            </Link>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="container">
          <div className="row">
            <div className="col-md-6">
              <Field
                name="published"
                className="form-control"
                component={formCheckbox}
                label="Published"
                labelClassName="form-control-label"
              />

              <Field
                name="covers"
                type="number"
                className="form-control"
                component={formField}
                label="Covers"
                required
                labelClassName="form-control-label"
                min={0}
                max={100000}
              />

              <Field
                name="total"
                type="number"
                component={formField}
                className="form-control"
                label="Total price (not including commission or fees)"
                labelClassName="form-control-label"
                step={0.01}
                min={0}
                required
                format={toPounds}
                parse={toPennies}
              />

              <Field
                name="commission_fee"
                type="number"
                component={formField}
                className="form-control"
                label="Commission Fee (OPTIONAL)"
                labelClassName="form-control-label"
                step="any"
                min={0}
              />

              <Field
                name="service_fee"
                type="number"
                component={formField}
                className="form-control"
                label="Service Fee (OPTIONAL)"
                labelClassName="form-control-label"
                step="any"
                min={0}
              />

              <Field
                name="supportText"
                type="textarea"
                component={TextEditor}
                className="form-control"
                label="Quote section text"
                labelClassName="form-control-label"
              />
            </div>

            <div className="col-md-6">
              <TableSearch
                label="Search query"
                input={this.state.query}
                onChange={this.changeQuery}
              />

              <TableList
                data={suppliers}
                columns={columns}
                className="u-margin-bottom"
              />

              {supplier.uuid && (
                <div>
                  <p>
                    <strong>Selected Supplier</strong>: {supplier.name}
                  </p>

                  {supplier.dietary[0] && (
                    <DietaryCalculator
                      label="Dietary Requirements"
                      labelClassName="form-control-label"
                      className="u-mb--s"
                    />
                  )}

                  <Field
                    name="dietary.notes"
                    type="text"
                    component={formField}
                    className="form-control"
                    label="Dietary Notes"
                    labelClassName="form-control-label"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 action-bar">
              <Button
                isLoading={submitting}
                type="submit"
                color="grey"
                text="Save"
                icon="save"
              />
              {submitFailed && (
                <p>
                  <span className="form-error">{error}</span>
                </p>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const matchDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSupplier,
      fetchSuppliers,
      resetEvent,
      resetSupplier,
    },
    dispatch
  );

const mapStateToProps = ({
  supplier,
  tag,
  suppliers,
  event,
  requestDetails,
}) => ({
  request: requestDetails,
  event,
  supplier,
  tag,
  suppliers: suppliers.items,
});

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(
  reduxForm({
    form: 'quote',
    validate: () => {},
    onSubmit: handleOnSubmit,
    onSubmitSuccess: handleOnSubmitSuccess,
  })(QuoteForm)
);
